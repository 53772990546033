import {
     initialState, UPDATE_USER_WALLET
} from "../constant/constant";




export default function reducer(state = { ...initialState.wallet }, action) {

    switch (action.type) {
       
        case UPDATE_USER_WALLET:
            return { ...state, wallet:action.value} ;
        default:
            return { ...state };
    }
}