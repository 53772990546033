import { LOGIN_USER, LOGOUT_USER, SET_NOTIFICATION, REFRESHTOKEN, RESETAUTHUSER, LOGIN, UPDATE_USER_WALLET } from "../constant/constant";
import { pAxios } from "..";
import { history } from '../app/history';

import { removeLocal } from "../constant/resetLocal"

import store from "../app/store";
import forge from 'node-forge';
import { gettoken } from "../constant/key";


function encryptByPublicKey(str) {

    let encryptedBase64;

    try {
        const publicKey = forge.pki.publicKeyFromPem(gettoken());

        // Encrypt the plain text using RSA-OAEP with SHA-256
        const encrypted = publicKey.encrypt(str, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha256.create(),
            },
        });

        // Convert encrypted data to base64 for transmission
        encryptedBase64 = forge.util.encode64(encrypted);
    }
    catch (e) {
        console.log(e);
    }



    return encryptedBase64;
}


function loginService(userId, passWord) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": "/api/auth/signin",
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify({
                "username": userId,
                "password": encryptByPublicKey(passWord)
            })
        }).then(response => {

            if (response.data && response.data.accessToken) {

                //    localStorage.setItem("userId",response.data.id);
                localStorage.setItem("token", response.data.accessToken);
                localStorage.setItem("refreshToken", response.data.refreshToken);

                //    localStorage.setItem("userName",response.data.username);
                //    localStorage.setItem("role",response.data.role);
                //    loadAllLookupsService();

                store.dispatch({ type: LOGIN, userid: response.data.id })

                history.push("/");
                resolve(loadUserService(response.data.id));
            }


        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Authenticaltion failed", variant: "danger", err } })
            });
    });

}

function loadUserService() {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/auth/user`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOGIN_USER, user: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load user data", variant: "danger", err } })
            });
    });

}

export function loadUserWalletService() {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/users/wallet`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: UPDATE_USER_WALLET, value: (response.data.wallet ?? 0) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load user data", variant: "danger", err } })
            });
    });

}


function refreshTokenService() {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": "/api/auth/refreshToken",
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify({

                "refreshToken": localStorage.getItem("refreshToken")
            })
        }).then(response => {

            if (response.data && response.data.accessToken) {

                localStorage.setItem("token", response.data.accessToken);
                resolve({ type: REFRESHTOKEN });
            }


        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Authenticaltion failed", variant: "danger", err } })
            });
    });

}

function logoutService() {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": "/api/auth/signout",
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify({
                refreshToken: localStorage.getItem("refreshToken")
            })
        }).then(response => {


            if (response.data && response.data) {


                removeLocal();
                history.push("/login");

                resolve({ type: LOGOUT_USER });
            }
        })
            .catch(err => {
                reject({ type: SET_NOTIFICATION, notification: { message: "Logout failed", variant: "danger", err } })
            });
    });

}


export const login = (userId, password) => {

    return dispatch => {

        loginService(userId, password)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loginUser = () => {

    return dispatch => {

        loadUserService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const resetAuth = () => {

    return dispatch => {
        dispatch({ type: RESETAUTHUSER, });
    }
}

export const refreshToken = () => {

    return dispatch => {

        refreshTokenService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}



export const logout = () => {

    return dispatch => {

        logoutService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadUserWallet = () => {

    return dispatch => {

        loadUserWalletService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

