import React from "react";
import { useSelector } from "react-redux";

import ReactNotification, { store } from "react-notifications-component";

function Notification(props) {

  const notification = useSelector((state) => state.notification.notification);

  React.useEffect(() => {
    if (notification ) {
      const data = {
        title: "",
        message: notification.message,
        type: notification.variant ? notification.variant : "default",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          click: true,
          onScreen: true,
          showIcon: true,
        },
        width: 300,
      };
      store.addNotification(data);
    } 
  }, [notification]);

  return <ReactNotification  />;
}

export default Notification;
