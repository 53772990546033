import { LOADALLEXPENSE,
    LOADEXPENSE,
    RESETEXPENSE,
    CHANGEEXPENSEFIELD,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";

function loadAllExpenseService(startDate,endDate,user,projectid,type,costcenter,status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/expense?fromDate=${startDate}&toDate=${endDate}${user?"&user="+user:""}${projectid ? "&projectid="+projectid:""}${type? "&type="+type:""}${costcenter? "&costcenter="+costcenter:""}${status?"&status="+status:""}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLEXPENSE, expense: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load expense data",variant:"danger",err}  })
            });
    });

}

function loadExpenseService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/expense/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADEXPENSE, expense: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load expense data",variant:"danger",err}  })
          });
  });

}


export function saveExpenseService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/expense`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
                resolve({ message: `Expense ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETEXPENSE});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save expense data",variant:"danger",err}  )
          });
  });

}


export function deleteExpenseService(id) {


    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/expense?id=${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                //     type: SET_NOTIFICATION, notification:
                        
                // });
                resolve({ message: `Expense deleted successfull`, variant: "success", err: "" })
            }
        })
            .catch(err => {

                reject({message: "Failed to Expense ", variant: "danger", err } )
            });
    });

}



 export const loadAllExpense=({startDate,endDate,user,projectid,type,costcenter, status})=>{

    return dispatch => {

        loadAllExpenseService(startDate,endDate,user,projectid,type,costcenter,status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export function approveExpenseService(id){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/expense/approve?id=${id}&screen_name=expense`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                 
            // store.dispatch({
            //   type: SET_NOTIFICATION, notification:
                  resolve({ message: `Expense approved successfully`, variant: "success", err: "" });
            //   });
           }
        })
            .catch(err => {
                
                reject({message:"Failed to approved expense",variant:"danger",err})
            });
    });
  
  }

export const loadExpense=(id)=>{

  return dispatch => {

      loadExpenseService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeExpense=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEEXPENSEFIELD,name,value,model});
}
  

}

export const resetExpense=()=>{

  return dispatch => {
    dispatch({type:RESETEXPENSE,});
}
  
}

// export const saveExpense=(expense)=>{

//   return dispatch => {
//     saveExpenseService(expense,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateExpense=(expense)=>{

//     return dispatch => {
//       saveExpenseService(expense,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }


  export const deleteExpense = (id) => {

    return dispatch => {
        deleteExpenseService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }

}