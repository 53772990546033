import { LOADALLVENDOR,
    LOADVENDOR,
    RESETVENDOR,
    CHANGEVENDORFIELD,SET_NOTIFICATION, LOADVENDORLOOKUPS, RESETVENDORS} from "../constant/constant";
import {pAxios} from "..";


function loadAllVendorService(status,unallocated){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/vendors?status=${status}${unallocated?"&unallocated="+unallocated:""}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLVENDOR, vendor: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
            });
    });

}

function loadVendorService(id,attributes){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/vendors/${id}${attributes?"?attributes="+attributes:""}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADVENDOR, vendor: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
          });
  });

}

export function loadVendorPaymentService(vendorId, projectId, paymentId){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/vendors/payment?vendorId=${vendorId}&projectId=${projectId}${paymentId?`&paymentId=${paymentId}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor payment data",variant:"danger",err}  })
            });
    });
  
  }
  

export function saveVendorService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/vendors`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
            // type: SET_NOTIFICATION, notification:
                resolve({ message: `Vendor ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" });
            // });
            // resolve({ type: RESETVENDOR});
         }
      })
          .catch(err => {
              
              reject({ message:"Failed to save vendor data",variant:"danger",err}  )
          });
  });

}



export function loadVendorLookUpService(attributes){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/vendors?attributes=${attributes?attributes:"id,name"},status&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADVENDORLOOKUPS, vendor: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor lookup",variant:"danger",err}  })
            });
    });
  
  }



 export const loadAllVendor=(status,unallocated)=>{

    return dispatch => {

        loadAllVendorService(status,unallocated)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadVendor=(id,attributes)=>{

  return dispatch => {

      loadVendorService(id,attributes)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  

export const changeVendor=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEVENDORFIELD,name,value,model});
}
  

}

export const resetVendor=()=>{

  return dispatch => {
    dispatch({type:RESETVENDOR,});
}
  
}


export const resetVendors=()=>{

    return dispatch => {
      dispatch({type:RESETVENDORS,});
  }
    
  }

// export const saveVendor=(vendor)=>{

//   return dispatch => {
//     saveVendorService(vendor,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateVendor=(vendor)=>{

//     return dispatch => {
//       saveVendorService(vendor,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }

  
export const loadVendorLookup=(attributes)=>{

    return dispatch => {
  
        loadVendorLookUpService(attributes)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }


