import React, { Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";

import cx from 'classnames';
import { Link, } from "react-router-dom";
import { UPDATE_COSTCENTER } from '../../constant/constant';
import { loadCostCenter } from '../../action/categoryAction';

export default function SearchBox({ newMenu }) {

    const dispatch = useDispatch();

    const [selected, setSelected] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [style, setStyle] = React.useState({ display: "none" });

    const costcenters = useSelector(
        (state) => state.costcenter.costcenters
    );

    const costcenter = useSelector(
        (state) => state.costcenter.costcenter
    );


    React.useEffect(() => {
        dispatch({type:UPDATE_COSTCENTER,value:localStorage.getItem('costCenter')??0})  ;
        dispatch(loadCostCenter());


    },[dispatch])

    React.useEffect(() => {

        setOptions([...Object.keys(newMenu).filter((k) => newMenu[k].length > 0)
            .flatMap((k, i) => {

                return [...newMenu[k].map((m, j) => {
                    return {
                        label: `${k} - ${m.label}`,
                        to: m.link,
                    }
                })]
            })])
    }, [newMenu])

    return (
        <Fragment>
            <div className={cx("search-wrapper", {
                'active': true
            })}>
                <div className="input-holder">
                    <input type="text" placeholder='Search' className="search-input"
                        value={selected ?? ""} onChange={(e) => {
                            setSelected(e.target.value);


                        }}
                        onBlur={() => {


                            setTimeout(
                                () => {
                                    setSelected(null);
                                    setStyle({
                                        display: "none"
                                    })
                                }, 200
                            );

                        }}
                        onKeyDown={(e) => {
                            setStyle({
                                top: e.target.getBoundingClientRect().top + 40,
                                position: "absolute",
                                left: e.target.getBoundingClientRect().left,
                                height: "300px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                zIndex: 99999,
                                width: e.target.getBoundingClientRect().width
                            })
                        }
                        }
                    />
                    <button className="search-icon"><span /></button>
                </div>
            </div>
            {
                costcenters && costcenters.length > 1 ? (
                    <div className='d-none d-lg-block'>
                        <select className='form-control search-input' value={costcenter} onChange={(e)=>{
                            dispatch({type:UPDATE_COSTCENTER,value:e.target.value})  ;
                            localStorage.setItem("costCenter", e.target.value);
                        }}>

                            <option value={0}>All Cost Center</option>
                            {
                                costcenters ? costcenters.map((e, i) => {
                                    return <option key={`cate${i}`} value={e.id}>{e.name}</option>
                                }) : null
                            }
                        </select>
                    </div>
                ): null
            }

            <div style={style}>
                <ul className="dropdown-menu show w-100" onClick={() => {

                    setStyle({
                        display: "none"
                    });
                    setSelected(null)
                }}>
                    {
                        options ? options.filter(e => !selected || (e.label && e.label.toLowerCase().includes(selected.toLowerCase())))
                            .map((e, i) => {
                                return <Link key={`link_${i}`} className='dropdown-item' to={e.to}>{e.label}</Link>

                            }) : null
                    }


                </ul>
            </div>
        </Fragment>
    )

}
