import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';

import { initialState } from "../constant/constant";





const store = createStore(rootReducer, initialState,
    compose(applyMiddleware(thunk)));
//, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));



export default store;