import { LOADALLCREDIT,
    LOADCREDIT,
    RESETCREDIT,
    CHANGECREDITFIELD,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";


function loadAllCreditNoteService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/credit`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLCREDIT, creditnote: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load credits data",variant:"danger",err}  })
            });
    });

}

function loadCreditNoteService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/credit/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADCREDIT, creditnote: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load contact data",variant:"danger",err}  })
          });
  });

}

export function saveCreditNoteService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/credit`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
               resolve({ message: `Contact ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETCREDIT});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save credit data",variant:"danger",err}  )
          });
  });

}


 export const loadAllCreditNote=()=>{

    return dispatch => {

        loadAllCreditNoteService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadCreditNote=(id)=>{

  return dispatch => {

      loadCreditNoteService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeCreditNote=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGECREDITFIELD,name,value,model});
}
  

}

export const resetCreditNote=()=>{

  return dispatch => {
    dispatch({type:RESETCREDIT,});
}
  
}



