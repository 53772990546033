import { LOADALLTDS,
    LOADTDS,
    RESETTDS,
    CHANGETDSFIELD,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";


function loadAllTdsService(status,projectid){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/tds?status=${status}${projectid?`&projectid=${projectid}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLTDS, tds: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load tds data",variant:"danger",err}  })
            });
    });

}

function loadTdsService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/tds/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADTDS, tds: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
          });
  });

}

export function saveTdsService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/tds`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
               resolve({ message: `Tds ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETTDS});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save vendor data",variant:"danger",err}  )
          });
  });

}


 export const loadAllTds=({status,projectid})=>{

    return dispatch => {

        loadAllTdsService(status,projectid)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadTds=(id)=>{

  return dispatch => {

      loadTdsService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeTds=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGETDSFIELD,name,value,model});
}
  

}

export const resetTds=()=>{

  return dispatch => {
    dispatch({type:RESETTDS,});
}
  
}

// export const saveTds=(vendor)=>{

//   return dispatch => {
//     saveTdsService(vendor,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateTds=(vendor)=>{

//     return dispatch => {
//       saveTdsService(vendor,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }



