import { LOADING, initialState } from "../constant/constant";

export default function reducer(state = { ...initialState.fetch }, action) {

    switch (action.type) {
        case LOADING:
            return { ...state, isLoading: action.isLoading };
        default:
            return state;
    }
}