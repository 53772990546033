import React from 'react'


import BTable from 'react-bootstrap/Table';
import { Card, Container, Form, ButtonGroup, Button } from 'react-bootstrap';
import { useTable, useGlobalFilter, usePagination, ColumnFiltersState, useFilters } from 'react-table'
import { useSelector } from "react-redux";

import TablePagination from './TablePagination';
import { Input } from 'reactstrap';


export const Filter = ({ column }) => {
    return (
      <div style={{ marginTop: 5 }}>
        {column.canFilter && column.render('Filter')}
      </div>
    );
  };
  
  export const DefaultColumnFilter = ({
    column: {
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) => {
    return (
      <Input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`search (${length}) ...`}
      />
    );
  };
  
  export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
  
    return (
      <Input
        id='custom-select'
        type='select'
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value=''>All</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Input>
    );
  };
  

function Table({ columns, data, title, edit, editText, 
    status = 'active', 
    changeStatus, 
    footer, 
    hideColumn, 
    rowChange, 
    cssName, 
    pagination = true,
    pageCount: controlledPageCount, ...props }) {
    // Use the state and functions returned from useTable to build your UI

 
    const { getTableProps, headerGroups, footerGroups, rows, prepareRow, state,
        preGlobalFilteredRows,
        gotoPage,
        // eslint-disable-next-line no-unused-vars
        pageCount,
        setPageSize,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        setGlobalFilter } = useTable({
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                hiddenColumns: ['id', ...(hideColumn ?? [])],

            },
        }, useFilters, useGlobalFilter, usePagination)

    // Render the UI for your table
    const { pageIndex=2, pageSize } = state;

    const theme = useSelector((state) => state.ThemeOptions);
    const [showFilter, setShowFilter] = React.useState(false);
   
    return (

        <Card className={`${title ? "border" : "border-0"} mb-0 mt-1`}>
            {
                title ? <Card.Header className={`d-flex flex-wrap ${theme.headerBackgroundColor}`}>
                
                    <h6 className="">
                        <i className='fa fa-table pe-1'></i>
                        {title}
                    </h6>
                    {
                        changeStatus ? (<ButtonGroup aria-label="Record Status" className=' ms-auto ms-md-0 ps-1'>
                            <Button variant={status === 'active' ? 'primary' : 'secondary'} onClick={() => changeStatus('active')} className="btn-grp">Active</Button>
                            <Button variant={status === 'closed' ? 'primary' : 'secondary'} onClick={() => changeStatus('closed')} className="btn-grp">Closed</Button>

                        </ButtonGroup>) : ("")
                    }


                    <div className="ms-md-auto w-sm-auto w-100">
                        <Form.Control id="searchInput" type="text" placeholder={`Search ${preGlobalFilteredRows.length} records`}
                            value={state.globalFilter || ""}
                            onChange={(e) => {
                                setGlobalFilter(e.target.value || undefined);
                            }} />
                    </div>
                    <Button title='Show column filter' variant="primary" className='ms-1' size="sm" onClick={()=>setShowFilter(!showFilter)} > <i className="fa fa-filter "></i></Button>
                </Card.Header> : ""
            }

            <Card.Body className={`${title ? "p-0" : "p-0"}`} style={{ "overflowX": "auto" }}>

                <Container fluid className={`${title ? "p-1" : "p-0"}`}>
                    {props.children}
                    {
                        edit ?
                            <div className='row ms-auto me-auto p-1'>
                                
                                <div className='col d-flex'>
                                    <span className='p-1'>Show:</span><select className='form-control  w-auto p-1 mb-1'
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize} rows
                                            </option>
                                        ))}
                                    </select>
                                </div> 
                                <div className="col text-end pe-0">
                                    <sub className="text-muted "><span className="text-danger">*</span>{editText ? editText : "Double click the row to edit"}</sub>
                                </div>
                                </div> : null
                    }

                    
                    <BTable bordered hover responsive="md" size='md'   {...getTableProps()} className={`${cssName ?? ""} mb-0`}>
                        <thead className={` ${theme.headerBackgroundColor}`}>
                            {headerGroups.map(headerGroup => (

                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    <th className='text-center'>#</th>
                                    {headerGroup.headers.map(column => {


                                        const classNames = column.className ?? ""
                                            .replace("text-left", "")
                                            .replace("text-right", "");

                                        return (

                                            <th className={`text-center ${classNames}`} {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                                {showFilter && column.render('Header')!=="Action" && !column.hideFilter ? column.textFilter ? <Filter column={column}></Filter> : <SelectColumnFilter  column={column} /> : null}
                                            </th>
                                        )
                                    }
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {(pagination? page : rows).map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...{ ...row.getRowProps(), className: `${rowChange ? rowChange(row.values) : ''}` }} onDoubleClick={() => { if (edit) edit(row) }}>
                                        <td>{ pagination? (Number(pageSize*pageIndex)+i+1) : i + 1}</td>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...{ ...cell.getCellProps(), className: `${cell.column.className ? cell.column.className : ""}` }}>
                                                    {cell.render('Cell') ?? "-"}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        {
                            footer ? (<tfoot>
                                {footerGroups.map(group => (
                                    <tr {...group.getFooterGroupProps()}>
                                        {/* <td></td> */}
                                        {group.headers.map(column => (
                                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tfoot>) : null
                        }

                    </BTable>
                    {rows.length > 0 ? null : (<div className=" alert bg-warning  p-0 text-center">
                        No Records
                    </div>
                    )}
                    {pagination && rows.length > pageSize ? (
                        <TablePagination
                            pageOptions={pageOptions}
                            page={page}
                            pageIndex={state.pageIndex}
                            pageSize={state.pageSize}
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            setPageSize={setPageSize}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            btn={theme.headerBackgroundColor}
                        />
                    ) : (
                        ""
                    )}

                </Container>
            </Card.Body>
        </Card>


    )
}


export default Table


