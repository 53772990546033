import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Form, Card, Col, FloatingLabel } from "react-bootstrap";


import Calendar from "./Calendar";

import {
  format,
  startOfWeek,
  lastDayOfWeek,

} from "date-fns";
import { isObjectEmpty } from "../util/util";
import { loadMandayService, saveMandayService } from "../action/labourAction";
import { dangerNotification, notification, warningNotification } from "../action/notificationAction";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { filterLabour, filterLabourSearch } from "../constant/filterTypeahead";
import { pAxios } from "..";



export default function LabourManday({ minDate, permitted }) {


  const dispatch = useDispatch();
  const labourEntryTypeRef = React.useRef(null);

  const labours = useSelector(
    (state) => state.lookup.labour
  );

  const projects = useSelector(
    (state) => state.lookup.project
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );

  const [show, setShow] = useState(false);

  const [selectedProject, setSelectedProject] = useState({});
  const [selectedLabour, setSelectedLabour] = useState("0");
  const [selectedLabourWage, setSelectedLabourWage] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [mandays, setMandays] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [multiselect, setMultiSelect] = useState(false);


  const handleSubmit = () => {
    if (!isObjectEmpty(selectedProject)) {

      let isFactor = true;
      const mandayObj = [];
      Object.keys(selectedProject).forEach(k => {

        const ele = selectedProject[k];

        if (ele.factor > 0) {
          mandayObj.push({
            labourid: selectedLabour,
            date: k,
            projectid: ele.id,
            wage: selectedLabourWage,
            paidstatus: 'P',
            factor: ele.factor,
            base: ele.base,
          });
        }


        if (isFactor && (!ele.factor || !ele.id || ele.id === "0")) {
          isFactor = false;
        }


      });

      if (mandayObj.length > 0) {
        if (isFactor) {

          saveMandayService(mandayObj).then(res => {
            dispatch(notification(res));
            // setSelectedProject({});
            // setSelectedLabour("0")
            // setSelectedLabourWage(null);
            // setCurrentMonth(new Date());
            // setMandays({});
            // setShow(false);
            load();

          })
            .catch(err => {

              dispatch(notification(err))
            })
        }
        else {
          dispatch(notification({ message: "Wage factor should be greater than 0 or Project selection should not be blank", variant: "warning" }))
        }
      }

    }
  }

  const markAsAbsent = (date) => {

    const postObject = {
      labourid: selectedLabour,
      date,
      absent: 'Y'
    }

    pAxios({
      "method": "POST",
      "url": `/api/manday/absent`,
      "headers": {
        'content-type': 'application/json'
      },
      "data": JSON.stringify(postObject)

    }).then(response => {

      if (response.data) {
        dispatch(notification({ message: "Marked as absent successfully", variant: 'success' }));
        load();
        
      }
    })
      .catch(err => {

        dispatch(dangerNotification("Failed to mark day as absent"));

      });


  }

  const deleteEntry = (id) => {
    pAxios({
      "method": "DELETE",
      "url": `/api/manday/${id}`,
      "headers": {
        'content-type': 'application/json'
      }

    }).then(response => {

      if (response.data) {
        dispatch(notification({ message: "Deleted successfully", variant: 'success' }));
        load();
        
      }
    })
      .catch(err => {

        dispatch(dangerNotification("Failed to delete"));

      });
  }

  const load = (month) => {


    if (selectedLabour) {

      loadMandayService(selectedLabour, format(startOfWeek(month ?? currentMonth, { weekStartsOn: 0 }), "yyyy-MM-dd"), format(lastDayOfWeek(month ?? currentMonth, { weekStartsOn: 0 }), "yyyy-MM-dd"))
        .then(res => {
          setMandays({});
          const l_manday = {};
          res.rows.forEach((e) => {
            l_manday[e.date] = { ...e };
          })
          setMandays({ ...l_manday });
          setShow(true);
        })
        .catch(err => {
          setMandays({});
          setShow(false);
          dispatch(notification(err));
        })
    }
    else {
      dispatch(warningNotification("Select labour to view calendar"));
      setShow(false);
    }

  }


  // React.useEffect(() => {

  //   console.log(`${format(startOfWeek(currentMonth, { weekStartsOn: 1 }), "yyyy-MM-dd")} - ${format(lastDayOfWeek(currentMonth, { weekStartsOn: 1 }),"yyyy-MM-dd")}`)
  // }, [currentMonth]);

  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])

  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`MANDAYS-ENTRY_UPDATE`));

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      <Container fluid >
        <Card>
          <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
            <h6>Labour Mandays</h6></Card.Header>
          <Card.Body>
            <Row className="g-1">

              <Col sm={12} md={4} >

                <Typeahead
                  id="floating-labour-lbl"
                  onChange={(e) => {
                    setSelectedLabour(e[0]?.id);
                    setCurrentMonth(new Date());
                    setShow(false);
                    setSelectedProject({});

                    setMandays({});

                    if (e[0] && e[0].id) {
                      const labourDetail = labours.filter(ele => Number(ele.id) === Number(e[0].id))
                      setSelectedLabourWage(labourDetail && labourDetail.length > 0 ? labourDetail[0].wage : null);
                    }
                    else {
                      setSelectedLabourWage(null)
                    }
                  }}
                  onBlur={() => {
                    if (!selectedLabour) {
                      labourEntryTypeRef.current?.clear();
                      setSelectedLabourWage(null);
                      setCurrentMonth(new Date());
                      setShow(false);
                      setSelectedProject({});
                      setMandays({});
                      setSelectedLabourWage(null)

                    }
                  }}
                  labelKey="name"
                  options={filterLabour(labours, costcenter)}
                  ref={labourEntryTypeRef}
                  placeholder="Select a labour"
                  clearButton
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingLabour" label="Select a labour">
                        <Form.Control

                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />

                      </FloatingLabel>

                    );
                  }}

                  selected={selectedLabour ? labours.filter(e => e.id === Number(selectedLabour)) : []}
                />


              </Col>
              <Col md="auto" sm={12}>
                <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end h-100 align-items-end ">
                  <Button variant="primary" size="sm" onClick={() => {
                    load();
                  }
                  }>Load</Button>

                  <Button size="sm" variant="success" disabled={!show} onClick={() => handleSubmit()}>Save</Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {
          show ?
            <Calendar minDate={minDate} updatePermission load={load} selectedLabourWage={selectedLabourWage} multiselect={multiselect} projects={projects} selectedProject={selectedProject} setSelectedProject={setSelectedProject} show={show} currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} mandays={mandays} header_bg={theme.headerBackgroundColor} costcenter={costcenter} markAsAbsent={markAsAbsent} deleteEntry={deleteEntry}/>

            : null

        }



        {/* <Card>
            <Card.Header>
              <h6 className="float-start"> Manday Week Entry </h6>

               <div className="float-end">
      <div className="form-check form-switch">
        <input disabled={!show} className="form-check-input" type="checkbox" id="flexSwitchMultiSelect" checked={multiselect} onChange={(e) => { setMultiSelect(e.target.checked) }} />
        <label className="form-check-label" htmlFor="flexSwitchMultiSelect"><b>Multiselect </b></label>
      </div>

    </div> 
            </Card.Header>
            <Card.Body>

              <Row className="g-2 mb-3">
                {
                  multiselect ? <Col sm={12} md={4}>
                    <FloatingLabel controlId="floatingProject" label="Project">
                      <Form.Select aria-label="Project" disabled={!show}>
                        <option value="0">Select a project</option>
                        {
                          projects ? projects.map((e, i) => {
                            return <option key={`pro_${i}`} value={e.id}>{e.name}</option>
                          }) : null
                        }

                      </Form.Select>

                    </FloatingLabel>
                  </Col> : null
                }

              </Row>
              

            </Card.Body>
          </Card> */}
      </Container>

    </React.Fragment>);


}

