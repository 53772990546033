import { SET_NOTIFICATION, RESET_NOTIFICATION, initialState } from "../constant/constant";

const extractError = (err, message) => {

    if (err && err.response && err.response.data) {

        if (err.response.data.message) {
            return err.response.data.message;
        }
        else if (err.response.data.exceptionMessage) {
            return err.response.data.exceptionMessage;
        }

    }
    return message;
}

export default function reducer(state = { ...initialState.notification }, action) {

    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state, notification: {
                    message: extractError(action.notification.err, action.notification.message),
                    variant: action.notification.variant ? action.notification.variant : "default"
                }
            };
        case RESET_NOTIFICATION:
            return { ...state, notification: { message: null, variant: "default" } };
        default:
            return state;
    }
}