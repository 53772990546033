
import React from 'react';
import { Route, Redirect } from 'react-router-dom';


function PublicRoute({ component: Component,  ...children }) {
    return(
   !localStorage.getItem("token") ? 
    <Route {...children} render={props => <Component {...props} />} />
    : <Redirect to="/" />);
  }
  
  export default PublicRoute;