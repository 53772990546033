import {
    LOADALLMATERIAL,
    LOADMATERIAL,
    RESETMATERIAL,
    CHANGEMATERIALFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.material }, action) {

    switch (action.type) {
        case LOADALLMATERIAL:
            return {...state,materials:[...action.material.rows]}
       
        case LOADMATERIAL:
            return { ...state, material:{...action.material} };
        case CHANGEMATERIALFIELD:
            if(action.model)
                return { ...state,material:{...state.material,[action.model]:{...(state.material[action.model] ?? {}),[action.name]:action.value}}};
            else
                return { ...state,material:{...state.material,[action.name]:action.value}};
        case RESETMATERIAL:
            return {...state,material:{...initialState.material.material}}
        default:
            return { ...state };
    }
}