import React from "react";


import { Container, Button } from "react-bootstrap";


export default function MasterToolBar({ createPermission, updatePermission, id, show, handleShow, handleClose, handleSubmit }) {


  return (<Container fluid className="pt-1 mb-2">
    <div className="d-flex">
      {/* {!show ? <div className="d-flex align-self-center">
            <sub className="text-muted"><span className="text-danger">*</span>Double click the row to edit</sub>
          </div> : ""
          } */}

      <div className="d-grid flex-fill d-flex gap-2  justify-content-end  ">
        {!show ?
          (createPermission ? <Button variant="outline-success" size="sm" onClick={() => handleShow()}>
            <i className="fa fa-plus  d-md-none d-sm-inline"></i>
            <p className="d-none d-md-inline"> Add New </p>
          </Button> : null) :

          (<React.Fragment>
            {
              id ? (updatePermission ? <Button variant="outline-success" size="sm" onClick={handleSubmit}>
                <i className="fa fa-save d-md-none d-sm-inline"></i>
                <p className="d-none d-md-inline"> Update</p>
              </Button> : null) :
                (createPermission ? <Button variant="outline-success" size="sm" onClick={handleSubmit}>
                  <i className="fa fa-save d-md-none d-sm-inline"></i>
                  <p className="d-none d-md-inline"> Save</p>
                </Button> : null)
            }

            <Button variant="outline-danger" size="sm" onClick={() => handleClose()}>
              <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
              <p className="d-none d-md-inline"> Back</p>
            </Button>
          </React.Fragment>)
        }
      </div>
    </div>
  </Container>)
}

