import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker';
import { Container, Button, Row, Form, Card, Col, FloatingLabel, Table, Modal } from "react-bootstrap";

import RTable from "./Table"
import { addPoItem, chanagePoStatusService, changePo, deletepoService, loadAllPo, loadPo, removePoItem, resetPo, savePoService } from "../action/poAction";


import { Confirm } from "./Confirm";
import { notification, warningNotification } from "../action/notificationAction";
import { numberCheck } from "../constant/numberCheck";
import { Typeahead } from "react-bootstrap-typeahead"
import { format, subDays } from "date-fns";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";
import { filterClient, filterClientsSearch, filterProjects, filterProjectsSearch, filterVendorsSearch } from "../constant/filterTypeahead";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';


export default function PurchaseOrder({minDate, entity, permitted }) {

  const dispatch = useDispatch();

  const cltTypeRef = React.useRef(null);
  const proTypeRef = React.useRef(null);
  const venTypeRef = React.useRef(null);

  const cltEntryTypeRef = React.useRef(null);
  const proEntryTypeRef = React.useRef(null);
  const venEntryTypeRef = React.useRef(null);

  const [status, setStatus] = useState("active");
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedPoId, setSelectedPoId] = useState(null);
  const [errors, setErrors] = useState({});

  const [startDate, setStartDate] = useState(minDate ? new Date(minDate) : subDays(new Date(),7));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null });


  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [closePermission, setClosePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars 
  const [deletePermission, setDeletePermission] = React.useState(false);


  React.useEffect(() => {

    setStatus("active");
    setShow(false);
    setShowUpload(false);
    setSelectedPoId(null);
    setErrors({});
    dispatch(resetPo())
    setSelectedClientId(null);
    setSelectedProjectId(null);
    setSelectedVendorId(null);


    setCreatePermission(permitted(`PO-${entity.toUpperCase()}_CREATE`));
    setUpdatePermission(permitted(`PO-${entity.toUpperCase()}_UPDATE`));
    setClosePermission(permitted(`PO-${entity.toUpperCase()}_CLOSE`));
    setDeletePermission(permitted(`PO-${entity.toUpperCase()}_DELETE`));

    setStartDate(minDate ? new Date(minDate) : subDays(new Date(),7));
    setEndDate(new Date());


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);


  const purchaseorder = useSelector((state) => state.po.po);

  const pos = useSelector(
    (state) => state.po.pos
  );

  const clients = useSelector(
    (state) => state.lookup.client
  );

  const projects = useSelector(
    (state) => state.lookup.project
  );

  const vendors = useSelector(
    (state) => state.lookup.vendor
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );


  const handleClose = (skipLoad) => {
    setShow(false);
    setSelectedPoId(null);
    dispatch(resetPo());
   
    // if(!skipLoad){
    //   search();
    // }
    setErrors({});
  }

  const handleCloseUpload = () => {
    setShowUpload(false);

  }

  const handleShow = (selected) => {
    setShow(true);
    setErrors({});
    if (selected && selected.original.id) {

      setSelectedPoId(selected.original.id);
      dispatch(loadPo(selected.original.id));
    }
    else {
      setSelectedPoId(null);
      setField('date',format(new Date(),"yyyy-MM-dd"));
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let dataToPost = {};
      let method = "POST";

      if (!purchaseorder.id) {
        dataToPost = {
          ...purchaseorder,
          side: entity.toUpperCase(),
          status: 'Y',
          screen_name: `${entity}-PO`
        };
      }
      else {
        dataToPost = {
          ...purchaseorder,
          screen_name: `${entity}-PO`
        };
        method = "PATCH"
      }

      savePoService(dataToPost, method).then(res => {
        dispatch(notification(res));
        handleClose();
        dispatch(resetPo());

        if(!purchaseorder.id){
          search()
        }
      })
        .catch(err => {
          dispatch(notification(err));
        })


    }

  }

  const findFormErrors = () => {

    const { clientid, projectid, vendorid, type, date, number, items } = purchaseorder
    const newErrors = {}


    if (entity === 'Client' && (!clientid || clientid === "0")) newErrors.mapping = 'Select a client!'
    if (entity === 'Vendor' && (vendorid ===null || vendorid === undefined)) newErrors.mapping = 'Select a vendor!'

    if (!projectid || projectid === "0") newErrors.project = 'Select a project!'

    if (!type || type === "0") newErrors.project = 'Select a type!'

    if (!date || date === "") newErrors.date = 'Select a date!'

    if (!number || number === "0") newErrors.number = 'Select a number!'

    if (items) {
      items.forEach((e) => {
        if (type === 'SINGLE') {

          if (!e.description || e.description === "") newErrors.description = 'Description cannot be empty';
          if (!e.basic || e.basic === "") newErrors.description = 'Basic cannot be empty';


        } else {

          if (!e.description || e.description === "") newErrors.description = 'Description cannot be empty';
          if (!e.item || e.item === "") newErrors.description = 'Item cannot be empty';
          if (!e.qty || e.qty === "") newErrors.description = 'Qty cannot be empty';
          if (!e.rate || e.rate === "") newErrors.description = 'rate cannot be empty';
        }

        if (!e.amount || e.amount === "") newErrors.description = 'Amount cannot be empty';
      })
    }

    return newErrors
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Project',
        accessor: 'projectname',
        className: 'w-100p'
      },
      {
        Header: 'Name',
        accessor: (cell) => cell['vendorname'] ?? cell['clientname'],
        className: 'w-100p',
        textFilter: true
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Date',
        accessor: (cell) => cell['date'] ? format(new Date(cell['date']),"dd-MM-yyyy"):"",
      },
      {
        Header: 'Number',
        accessor: 'number',
        textFilter: true
      }, {
        Header: 'Gross',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Closed',
        id:'closedDate',
        accessor: (cell) => cell['closedDate'] ? format(new Date(cell['closedDate']),"dd-MM-yyyy"):"",
        show: status === 'active'
      }, {
        Header: 'Action',
        id: 'action',
        hideFilter: true,
        accessor: (cell) => {
          return <React.Fragment>
            <div className="text-center">

              {closePermission ? <Button size="sm" variant="outline-primary" className="pt-0 pb-0 me-2" title="Close Po" onClick={() => { closePo(cell['id'], cell['status']) }}> <i class="fa fa-times" role="button"></i></Button> : null}
              {deletePermission ? <Button size="sm" variant="outline-danger" className="pt-0 pb-0" title="Delete Po" onClick={() => { setShowConfirm({ show: true, callBackData: cell['id'] }); }}> <i class="fa fa-trash" role="button"></i></Button> : null}
            </div>
          </React.Fragment>
        }
      }],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, closePermission, deletePermission, entity]
  )


  const setField = (field, value, idx) => {
    dispatch(changePo({ name: field, value, idx }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const addRow = () => {
    dispatch(addPoItem(Number(purchaseorder.items[purchaseorder.items.length - 1].idx) + 1));
  }


  const removeRow = (idx) => {
    dispatch(removePoItem(idx));
  }

  
  const onChange = (dates) => {
    
    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }
  };


  const search = (e) => {

    let filter = null;
    if (entity === 'Client' && selectedClientId) {
      filter = selectedClientId;
    }
    else if (entity === 'Vendor' && selectedVendorId !== null) {
      filter = selectedVendorId;
    }

    // if (filter) {

    if (selectedProjectId) {
      dispatch(loadAllPo(status, entity, filter, selectedProjectId, startDate?format(startDate,"yyyy-MM-dd"):null, endDate?format(endDate,"yyyy-MM-dd"):null, costcenter));
    }
    

    else if (filter!=null) {
      dispatch(loadAllPo(status, entity, filter, null, startDate?format(startDate,"yyyy-MM-dd"):null, endDate?format(endDate,"yyyy-MM-dd"):null, costcenter));
    }

    else if(startDate && endDate){
      dispatch(loadAllPo(status, entity, null, null, startDate?format(startDate,"yyyy-MM-dd"):null, endDate?format(endDate,"yyyy-MM-dd"):null, costcenter) );
    }

    // }
    else if (e) {
      dispatch(warningNotification(`Select a valid ${entity} or project to load po's`))
    }

  }

  const closePo = (id, status) => {
    chanagePoStatusService(id, status === "Y" ? "N" : "Y", `${entity}-PO`)
      .then(res => {
        dispatch(notification(res));
        search();
      })
      .catch(err => {
        dispatch(notification(err));
      })
  }

  const deletePurchaseOrder = (confirm, id) => {

    if (confirm) {
      deletepoService(id, `${entity}-PO`)
        .then(res => {
          dispatch(notification(res));
          search();
        })
        .catch(err => {
          dispatch(notification(err));
        })
    }

    setShowConfirm({ show: false, callBackData: null });
  }

  React.useEffect(() => {

    search();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status, entity, costcenter]);

  React.useEffect(()=>{
    dispatch(setEnableMobileMenu(false));
  },[dispatch]);

  
  React.useEffect(()=>{
    
    setSelectedClientId(null);
    setSelectedProjectId(null);
    handleClose(true);
    cltTypeRef?.current?.clear();
    proTypeRef?.current?.clear();
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[costcenter]);


  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      <Container fluid className={`${show?'d-none':''}`}>
            <Card >
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="text-start flex-fill fw-bold"> {entity} Purchase Order</h6>
                {(createPermission ?
                   
                  <Button variant="success" size="sm" onClick={() => handleShow()} title="New Po">
                    <i className="fa fa-plus d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline">Add New</p>
                    </Button> 
                   : null)}
              </Card.Header>
              <Card.Body>
                <Row className="mb-3 g-2">
                <Col sm={12} md={3}>
                    <div style={{ position: "relative" }} className="dp">
                      <DateRangePicker maxDate={new Date()} id="poDateRangeFilter"
                        
                        format="dd-MM-yyyy"
                        onChange={onChange}
                        value = {[startDate, endDate]}
                        calendarIcon={<i className="fa fa-calendar"></i>}
                        // clearIcon = {null}
                        className="form-control"
                        isClearable={true}
                      ></DateRangePicker>
                      <label className="black-text" htmlFor="poDateRangeFilter">Purchase Order Date</label>
                    </div>
                  </Col>
                  {
                    entity === 'Client' ? (
                      <Col sm={12} md={3} >


                        <Typeahead
                          id="floating-label-clt-filter"
                          onChange={(e) => {
                            setSelectedClientId(e[0]?.id)
                          }}
                          clearButton
                          onBlur={() => {
                            if (!selectedClientId) {
                              cltTypeRef.current?.clear();
                              proTypeRef.current?.clear();
                              setSelectedProjectId(null)
                            }
                          }}
                          labelKey="name"
                          options={filterClientsSearch(clients,costcenter)}
                          ref={cltTypeRef}
                          placeholder="Choose A Client"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingClientFilter" label="Select A Client">
                                <Form.Control
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                              </FloatingLabel>

                            );
                          }}
                          selected={selectedClientId ? clients.filter(e => e.id === selectedClientId) : []}
                        />

                       
                      </Col>
                    ) : <Col sm={12} md={3} >
                      <Typeahead
                        id="floating-label-ven-filter"
                        onChange={(e) => {
                          setSelectedVendorId(e[0]?.id)
                        }}
                        clearButton
                        onBlur={() => {
                          if (selectedVendorId ===null) {
                            venTypeRef.current?.clear();
                          }
                        }}
                        labelKey="name"
                        options={filterVendorsSearch(vendors)}
                        ref={venTypeRef}
                        placeholder="Choose A Vendor"
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                          return (

                            <FloatingLabel controlId="floatingVendorIdFilter" label="Select A Vendor">
                              <Form.Control
                                {...inputProps}
                                ref={(node) => {
                                  inputRef(node);
                                  referenceElementRef(node);
                                }}
                              />
                            </FloatingLabel>

                          );
                        }}
                        selected={selectedVendorId!==null ? vendors.filter(e => e.id === selectedVendorId) : []}
                      />
                    
                    </Col>
                  }

                  <Col sm={12} md={3} >


                    <Typeahead
                      id="floating-label-pro-filter"
                      onChange={(e) => {
                        setSelectedProjectId(e[0]?.id)
                      }}
                      clearButton
                      labelKey="name"
                      onBlur={() => {
                        if (!selectedProjectId) {
                          proTypeRef.current?.clear();
                        }
                      }}
                      options={projects && ((selectedClientId && selectedClientId !== "0") || entity === "Vendor") ?
                        filterProjectsSearch(projects,costcenter).filter(e => Number(e.clientid) === Number(selectedClientId) || entity === "Vendor") : []}

                      ref={proTypeRef}
                      placeholder="Choose A Project"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedProjectId ? projects.filter(e => e.id === selectedProjectId) : []}
                    />

                   
                  </Col>
                  <Col >
                    {
                      !show ? (<div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">

                        <Button variant="primary" size="sm" onClick={() => search('e')}>Search</Button>
                      </div>) : ""}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <RTable columns={columns} data={pos} title="Purchase Orders" edit={handleShow} status={status} changeStatus={setStatus} hideColumn={status === 'active' ? ['closedDate'] : ['action']}> </RTable>
          </Container>
      {
        !show ? (

          null) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="text-start flex-fill">Purchase Order Detail </h6>
                <div >
                  {
                    ((purchaseorder.id && updatePermission) || (!purchaseorder.id && createPermission)) ?
                      <Button className="me-1" variant="primary" size="sm" onClick={handleSubmit} title="Save">
                        <i className="fa fa-save d-md-none d-sm-inline"></i>
                        <p className="d-none d-md-inline">{!purchaseorder.id ? "Save" : "Update"}</p>
                      </Button> : null
                  }

                  {
                    !purchaseorder.id && createPermission ? <Button className="me-1" variant="success" size="sm" onClick={() => { setShowUpload(true) }} title="Upload">
                      <i className="fa fa-upload d-md-none d-sm-inline" ></i>
                      <p className="d-none d-md-inline">Upload</p>
                    </Button> : null
                  }

                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p>
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>


                <Row className="mb-3 g-2">

                  {
                    <Col sm={12} md={3} >


                      {
                        entity === 'Client' ?

                          <Typeahead
                            id="floating-label-clt"
                            onChange={(e) => {
                              setField('clientid', e[0]?.id);
                              setField('projectid', null);
                            }}
                            clearButton
                            onBlur={() => {
                              if (!purchaseorder.clientid) {
                                cltEntryTypeRef.current?.clear();
                                proEntryTypeRef.current?.clear();
                                setField('projectid', null);
                              }
                            }}
                            labelKey="name"
                            options={filterClient(clients,costcenter)}
                            ref={cltEntryTypeRef}
                            placeholder="Choose a client"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingClient" label="Select a client">
                                  <Form.Control
                                    isInvalid={!!errors.mapping}
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                  <Form.Control.Feedback type='invalid'>{errors.mapping} </Form.Control.Feedback>
                                </FloatingLabel>

                              );
                            }}
                            selected={purchaseorder.clientid ? clients.filter(e => e.id === Number(purchaseorder.clientid)) : []}
                          />
                          :
                          <Typeahead
                            id="floating-label-ven"
                            onChange={(e) => {
                              setField('vendorid', e[0]?.id)
                            }}
                            clearButton
                            onBlur={() => {
                              if (purchaseorder.vendorid===null) {
                                venEntryTypeRef.current?.clear();
                              }
                            }}
                            labelKey="name"
                            options={vendors}
                            ref={venEntryTypeRef}
                            placeholder="Choose A Vendor"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingVendorId" label="Select A Vendor">
                                  <Form.Control isInvalid={!!errors.mapping}
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                  <Form.Control.Feedback type='invalid'>{errors.mapping} </Form.Control.Feedback>
                                </FloatingLabel>

                              );
                            }}
                            selected={purchaseorder.vendorid!==null ? vendors.filter(e => e.id === Number(purchaseorder.vendorid)) : []}
                          />
                      }

                    </Col>
                  }


                  <Col sm={12} md={3} >


                    <Typeahead
                      id="floating-label-pro"
                      onChange={(e) => {
                        setField('projectid', e[0]?.id)
                      }}
                      clearButton
                      labelKey="name"
                      onBlur={() => {
                        if (!purchaseorder.projectid) {
                          proEntryTypeRef.current?.clear();
                        }
                      }}
                      options={projects && ((purchaseorder.clientid && purchaseorder.clientid !== "0") || entity === "Vendor") ?
                        filterProjects(projects,costcenter).filter(e => Number(e.clientid) === Number(purchaseorder.clientid) || entity === "Vendor") : []}

                      ref={proEntryTypeRef}

                      placeholder="Choose A Project"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingProject" label="Select A Project">
                            <Form.Control isInvalid={!!errors.project}
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                            <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                          </FloatingLabel>

                        );
                      }}
                      selected={purchaseorder.projectid ? projects.filter(e => e.id === Number(purchaseorder.projectid)) : []}
                    />

                  </Col>
                  <Col sm={12} md={3} >

                    <div style={{ position: "relative" }} className="dp">
                      <DatePicker maxDate={new Date()}
                        minDate={minDate? new Date(minDate):null}
                        id="podate"
                        //placeholderText="Select PO Date"
                        format="dd-MM-yyyy"
                        calendarIcon={<i className="fa fa-calendar"></i>}
                        clearIcon = {null}
                        className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                        //isInvalid={true}
                        value={purchaseorder && purchaseorder.date ? new Date(purchaseorder.date) : null}
                        onChange={(date) => setField("date", format(date, "yyyy-MM-dd"))}  
                      ></DatePicker>
                      <label className="black-text" htmlFor="podate">PO Date</label>
                    </div>


                  </Col>
                  <Col sm={12} md={3}>
                    <FloatingLabel controlId="floatingNumber" label="PO Number">
                      <Form.Control type="text" placeholder="PO Number" isInvalid={!!errors.number} value={purchaseorder.number ?? ""} onChange={e => setField("number", e.target.value)} />
                      <Form.Control.Feedback type='invalid'>{errors.number} </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={3} >
                    <FloatingLabel controlId="floatingType" label="Type">
                      <Form.Select id="floatingType" aria-label="Type" isInvalid={!!errors.type}
                        disabled={purchaseorder.id}
                        value={purchaseorder.type ?? "0"}
                        onChange={e => {
                          setField("type", e.target.value);
                          setField("items", [{ idx: 1 }]);

                        }
                        }>
                        <option value="0">Select Type</option>
                        <option value="SINGLE">Single Line</option>
                        <option value="MULTI">Multi Line</option>

                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors.type} </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>


                {
                  purchaseorder.type ? (
                    <React.Fragment>
                      {
                        errors.description ? <div className="alert alert-warning  p-0 text-center " > {errors.description} </div> : null
                      }
                      {
                        purchaseorder.type === "SINGLE" ? (<Container fluid className="p-0" >
                          <Table bordered hover responsive="md" size='md'  >
                            <thead className={` ${theme.headerBackgroundColor}`}>
                              <tr><th className="text-center" style={{ width: "50px" }}>#</th>
                                <th className="text-center">Description</th>
                                <th className="text-center" style={{ width: "200px" }}>Rate</th>
                                <th className="text-center" style={{ width: "100px" }}>Qty</th>
                                <th className="text-center" style={{ width: "100px" }}>GST%</th>
                                <th className="text-center" style={{ width: "150px" }}>GST Value  </th>
                                <th className="text-center" style={{ width: "150px" }}>Amount</th>
                              </tr>
                            </thead>
                            <tbody className="editable">

                              {
                                purchaseorder.items.map((item, i) => {

                                  return (<tr key={`tr_${i}`}>
                                    <td className="align-middle text-end"> {item.idx}</td>
                                    <td><input name="description" type="text" className="form-control" value={item.description ?? ""} onChange={e => setField("description", e.target.value, item.idx)} /></td>
                                    <td><input name="basictxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.rate ?? ""} onChange={e => setField("rate", e.target.value, item.idx)} /></td>
                                    <td><input name="qtytxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.qty ?? ""} onChange={e => setField("qty", e.target.value, item.idx)} /></td>
                                    <td><input name="gsttxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.gstpercentage ?? ""} onChange={e => setField("gstpercentage", e.target.value, item.idx)} /></td>
                                    <td className="align-middle text-end">{item.gstvalue ?? ""} </td>
                                    <td className="align-middle text-end">{item.amount ?? ""} </td>

                                  </tr>)

                                })
                              }

                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={6} className="text-end"> MISC/TRANSPORT</td>
                                <td className="p-0"><input name="misctxt" type="text" onKeyPress={numberCheck} className="form-control" value={purchaseorder.misc ?? ""} onChange={e => setField("misc", e.target.value)} /></td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="text-end">GST</td>
                                <td>{purchaseorder.gst}</td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="text-end">GROSS</td>
                                <td>{purchaseorder.gross}</td>
                              </tr>
                            </tfoot>


                          </Table>
                        </Container>) : (<Container fluid className="p-0" >
                          <Table bordered hover responsive="md" size='md'  >
                            <thead className={` ${theme.headerBackgroundColor}`}>
                              <tr><th className="text-center" style={{ width: "50px" }}>#</th>
                                <th className="text-center">Item</th>
                                <th className="text-center">Description</th>
                                <th className="text-center">HSN/SAC</th>
                                <th className="text-center">Qty</th>
                                <th className="text-center">Rate</th>
                                <th className="text-center" style={{ width: "100px" }}>Gst %</th>
                                <th className="text-center">Gst Value</th>
                                <th className="text-center" >Basic</th>
                                {
                                  ((purchaseorder.id && updatePermission) || (!purchaseorder.id && createPermission)) ?
                                    <th style={{ width: "50px" }}>Action</th> : null
                                }
                              </tr>
                            </thead>
                            <tbody className="editable">

                              {
                                purchaseorder.items.map((itm, i) => {

                                  return (<tr key={`tr_${i}`} >
                                    <td className="align-middle text-end">{itm.idx ?? ""}</td>
                                    <td><input name="itemtxt" type="text" className="form-control" value={itm.item ?? ""} onChange={e => setField("item", e.target.value, itm.idx)} /></td>
                                    <td><input name="descriptiotxt" type="text" className="form-control" value={itm.description ?? ""} onChange={e => setField("description", e.target.value, itm.idx)} /></td>

                                    <td><input name="hsntxt" type="text" className="form-control" value={itm.hsn ?? ""} onChange={e => setField("hsn", e.target.value, itm.idx)} /></td>
                                    <td><input name="qtytxt" type="text" onKeyPress={numberCheck} className="form-control" value={itm.qty ?? ""} onChange={e => setField("qty", e.target.value, itm.idx)} /></td>
                                    <td><input name="ratetxt" type="text" onKeyPress={numberCheck} className="form-control" value={itm.rate ?? ""} onChange={e => setField("rate", e.target.value, itm.idx)} /></td>
                                    <td><input name="gstpertxt" type="text" onKeyPress={numberCheck} className="form-control" value={itm.gstpercentage ?? ""} onChange={e => setField("gstpercentage", e.target.value, itm.idx)} /></td>
                                    <td className="align-middle text-end">{itm.gstvalue ?? ""} </td>
                                    <td className="align-middle text-end">{itm.basic ?? ""} </td>

                                    {
                                      ((purchaseorder.id && updatePermission) || (!purchaseorder.id && createPermission)) ?
                                        (<td className="text-center action">
                                          {
                                            i !== 0 ? <i className="fa fa-trash text-danger " role="button" onClick={() => { removeRow(itm.idx) }}></i> : (
                                              <i className="fa fa-plus  text-success" role="button" onClick={() => { addRow() }}> </i>)
                                          }
                                        </td>) : null
                                    }

                                  </tr>)

                                })
                              }

                            </tbody>
                            <tfoot>
                              <tr >
                                <td colSpan={8} className="text-end">Total Basic</td>
                                <td colSpan={2}>{purchaseorder.basic}</td>
                              </tr>
                              <tr>
                                <td colSpan={8} className="text-end">GST</td>
                                <td colSpan={2}>{purchaseorder.gst}</td>
                              </tr>
                              <tr >
                                <td colSpan={8} className="text-end">MISC/TRANSPORT</td>
                                <td className="p-0" colSpan={2}><input name="misctxt" type="text" onKeyPress={numberCheck} className="form-control" value={purchaseorder.misc ?? ""} onChange={e => setField("misc", e.target.value)} /></td>
                              </tr>
                              <tr>
                                <td colSpan={8} className="text-end">GROSS</td>
                                <td colSpan={2}>{purchaseorder.gross}</td>
                              </tr>
                            </tfoot>


                          </Table>
                        </Container>)
                      }
                    </React.Fragment>
                  ) : null
                }



              </Card.Body>
            </Card>

            <Modal show={showUpload} onHide={handleCloseUpload}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Purchase Order</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formFile" >
                  <Form.Control size="lg" type="file" accept=".xlsx" />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>

                <Button variant="primary" onClick={handleCloseUpload}>
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

          </Container>)
      }

      <Confirm show={showConfirm} confirmation="Are you sure?" confirmCallBack={deletePurchaseOrder}>

      </Confirm>
    </React.Fragment>);
}

// }
