import {
    LOADCLIENTLOOKUPS,
    LOADVENDORLOOKUPS,
    LOADLABOURLOOKUPS,
    LOADPROJECTLOOKUPS,
    initialState,
    LOADCATEGORYLOOKUP,
    LOADACTIVITYLOOKUP,
    LOADMATERIALLOOKUP,
    LOADPOLOOKUP,
    LOADUSERLOOKUP
} from "../constant/constant";

export default function reducer(state = { ...initialState.lookup }, action) {

    switch (action.type) {
        case LOADCLIENTLOOKUPS:
            return { ...state, client: [...action.client.rows] }

        case LOADVENDORLOOKUPS:
            return { ...state, vendor: [...action.vendor.rows] };

        case LOADPROJECTLOOKUPS:
            return { ...state, project: [...action.project.rows] };

        case LOADLABOURLOOKUPS:
            return { ...state, labour: [...action.labour.rows] };

        case LOADACTIVITYLOOKUP:
            return { ...state, activity: [...action.activity.rows] };

        case LOADMATERIALLOOKUP:
            return { ...state, material: [...action.material.rows] };

        case LOADPOLOOKUP:
            return { ...state, po: [...action.po.rows] };
       
        case LOADUSERLOOKUP:
            return { ...state, user: [...action.user.rows] };

        case LOADCATEGORYLOOKUP:
            return { ...state, category: { ...action.category } };
        default:
            return { ...state };
    }
}