import React, { Fragment } from 'react';

import { Slider } from "react-burgers";

import AppMobileMenu from './AppMobileMenu';

import {
    setEnableClosedSidebar,
} from '../../constant/constant';

import { useDispatch, useSelector } from "react-redux";
import brand from "../../assets/brand.jpg"

export default function AppLogo({}) {


    const theme = useSelector((state) => state.ThemeOptions);
    const dispatch = useDispatch();

    const [active, setActive] = React.useState(false)


    const toggleEnableClosedSidebar = () => {

        dispatch(setEnableClosedSidebar(!theme.enableClosedSidebar));
    }




    return (
        <Fragment>
            <div className="app-header__logo">
                {/* <div className="logo-src"/> */}
                <a href="/">
                    <img className='logo-src' width="30" height="30" id="profile-img" src={brand} alt="logo" />
                </a>
                    <b className='logo-src ps-2'>
                    Athira Projects
                </b>
                
                <div className="header__pane ms-auto">
                    <div onClick={toggleEnableClosedSidebar}>
                        <Slider
                            active={theme.enableClosedSidebar}
                            type="elastic"
                            onClick={() => setActive(!active)}
                        />
                    </div>
                </div>
            </div>
            <AppMobileMenu />
        </Fragment>
    )

}


