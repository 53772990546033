import { LOADALLLABOUR,
    LOADLABOUR,
    RESETLABOUR,
    CHANGELABOURFIELD,SET_NOTIFICATION, LOADLABOURLOOKUPS} from "../constant/constant";
import {pAxios} from "..";
// import store from "../app/store";


function loadAllLabourService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/labour?status=${status}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLLABOUR, labour: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load labour data",variant:"danger",err}  })
            });
    });

}

function loadLabourService(id,attributes){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/labour/${id}${attributes?"?attributes="+attributes:""}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADLABOUR, labour: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
          });
  });

}


export function saveLabourService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/labour`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
            // type: SET_NOTIFICATION, notification:
            resolve({ message: `Labour ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETLABOUR});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save vendor data",variant:"danger",err}  )
          });
  });

}


export function loadLabourLookUpService(attributes){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/labour?attributes=${attributes?attributes:"id,name,wage"},status,costcenter&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADLABOURLOOKUPS, labour: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load client lookup",variant:"danger",err}  })
            });
    });
  
  }


  export function loadMandayService(labour,fromDate,toDate,paidstatus){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/manday?labour=${labour}&${fromDate?"fromDate="+fromDate:""}${toDate?"&toDate="+toDate:""}${paidstatus?"&paidstatus="+paidstatus:""}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({message:"Failed to load mandays data",variant:"danger",err}  )
            });
    });
  
  }

  export function saveMandayService(data){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/manday`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(data)
          
        }).then(response => {
  
           if(response.data){
                       
               resolve(
                    { message: "Mandays updated successfull", variant: "success", err: "" }
                );
           }
        })
            .catch(err => {
                
                reject({message:"Failed to save mandays data",variant:"danger",err}  )
            });
    });
  
  }


 export const loadAllLabour=(status)=>{

    return dispatch => {

        loadAllLabourService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadLabour=(id,attributes)=>{

  return dispatch => {

      loadLabourService(id,attributes)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeLabour=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGELABOURFIELD,name,value,model});
}
  

}

export const resetLabour=()=>{

  return dispatch => {
    dispatch({type:RESETLABOUR,});
}
  
}

// export const saveLabour=(vendor)=>{

//   return dispatch => {
//     saveLabourService(vendor,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateLabour=(vendor)=>{

//     return dispatch => {
//       saveLabourService(vendor,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }

export const loadLabourLookup=(attributes)=>{

    return dispatch => {
  
        loadLabourLookUpService(attributes)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }





