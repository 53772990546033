import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Form, Card, Col, FloatingLabel } from "react-bootstrap";

import MasterToolBar from "./MasterToolBar"
import Table from "./Table"
import { states } from '../constant/in_state'
import { changeProject, loadAllProject, loadProject, loadProjectLookup, resetProject, saveProjectService } from "../action/projectAction";
import { isObjectEmpty } from "../util/util";
import { notification } from "../action/notificationAction";
import {Typeahead} from "react-bootstrap-typeahead";
import {
    setEnableMobileMenu
  } from '../constant/constant';
  
import {filterClient} from "../constant/filterTypeahead"
// import { loadAllCustomer, updateStatus } from '../action/customerAction';

export default function Project({ permitted }) {

    const [status, setStatus] = useState("active");
    const dispatch = useDispatch();
    const typeRef = React.useRef(null);

    // eslint-disable-next-line no-unused-vars
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [errors, setErrors] = useState({});

    const clients = useSelector(
        (state) => state.lookup.client
    );

    const project = useSelector(
        (state) => state.project.project
    );

    const projects = useSelector(
        (state) => state.project.projects
    );

    
  const lookup = useSelector(
    (state) => state.lookup.category
  );


    const [show, setShow] = useState(false);

    const handleClose = () => {
        setErrors({});
        setShow(false);
        setSelectedProjectId(null);
        dispatch(resetProject());
        dispatch(loadAllProject(status));
    }

    const handleShow = (selected) => {
        setErrors({});
        setShow(true);
        if (selected && selected.original.id) {

            dispatch(loadProject(selected.original.id))
            setSelectedProjectId(selected.original.id);
        }
        else {
            setSelectedProjectId(null);
        }
    }

    const handleSubmit = e => {

        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            let dataToPost = {};
            let method = "POST";

            if (!project.id) {
                dataToPost = {
                    ...project,
                    status: 'Y',
                    poReleased: project.poReleased && project.poReleased !== "0" ? project.poReleased : "N",
                    address: isObjectEmpty(project.address) ? null : project.address,
                    //   client: isObjectEmpty(project.client)  ? null : project.client          
                };
            }
            else {
                dataToPost = {
                    ...project,
                    poReleased: project.poReleased && project.poReleased !== "0" ? project.poReleased : "N",
                    address: isObjectEmpty(project.address) ? null : project.address,
                    //   client: isObjectEmpty(project.client) ? null : project.client
                };
                method = "PATCH"
            }

            saveProjectService(dataToPost, method).then(res => {

                dispatch(notification(res));
                handleClose();
                dispatch(resetProject());
                dispatch(loadProjectLookup());
            })
                .catch(err => {
                    dispatch(notification(err))
                })

        }



    }

    const findFormErrors = () => {

        const { name, clientid } = project
        const newErrors = {}

        if (!name || name === '') newErrors.name = 'Name cannot be blank!';

        if (!clientid || clientid === "0") newErrors.client = 'Select a client!'

        return newErrors
    }



    const setField = (field, value, model) => {
        dispatch(changeProject({ name: field, value, model }));
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    
  const  fnCostCenter = (lookup,costcenter)=>{

    const cc =  lookup?.CLIENT?.find(e=> e.id===Number(costcenter))

    return cc? cc.name : "";
  }

    const columns = React.useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
                id: 'name',
                className: 'text-wrap w-250',
                textFilter: true
            },
            {
                Header: 'Code',
                accessor: 'code',
            },
            {
                Header: 'Client',
                accessor: (cell) => `${cell['clientName']}  - ${fnCostCenter(lookup,cell['categoryid'])} `,
                className: 'text-wrap w-200'
            },
            {
                Header: 'Address',
                accessor: 'address.address1',
                className: 'text-truncate w-400',
                textFilter: true
            },
            {
                Header: 'PO to Vendor',
                accessor: 'poReleased',
                
            },

            {
                Header: 'Balance',
                accessor: 'balance',
                hideFilter: true
            },
            {
                Header: 'Closed',
                accessor: 'closedDate',
                show: status === 'active'
            }

        ],
        [status,lookup]
    )

    React.useEffect(() => {
        dispatch(loadAllProject(status));
    }, [dispatch, status]);

    React.useEffect(()=>{
        dispatch(setEnableMobileMenu(false));
    },[dispatch])

    // eslint-disable-next-line no-unused-vars
    const [createPermission, setCreatePermission] = React.useState(permitted(`PROJECT_CREATE`));
    // eslint-disable-next-line no-unused-vars
    const [updatePermission, setUpdatePermission] = React.useState(permitted(`PROJECT_UPDATE`));

    const theme = useSelector((state) => state.ThemeOptions);


    return (
        <React.Fragment>
            <MasterToolBar createPermission updatePermission id={project.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose}></MasterToolBar>
            {
                !show ? (

                    <Container fluid >
                        <Table columns={columns} data={projects} title="Project" edit={handleShow} status={status} changeStatus={setStatus} hideColumn={status === 'active' ? ['closedDate'] : []}> </Table>
                    </Container>) : (
                    <Container fluid >

                        <Card>
                            <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>Project</Card.Header>
                            <Card.Body className="p-0">
                                <Form>
                                    <Row className="m-auto">

                                        <Card className="p-0 col-md-6 col-sm-12 ">

                                            <Card.Body>
                                                <Card.Title><i className="fa fa-file-powerpoint-o "></i> Project Info</Card.Title>
                                                <hr></hr>

                                                <Row className="mb-3">

                                                    <Col> <FloatingLabel controlId="floatingName" label="Project Name">
                                                        <Form.Control type="text" placeholder="Project Name" isInvalid={!!errors.name} value={project.name ?? ""} onChange={e => setField('name', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Col> <FloatingLabel controlId="floatingCode" label="Project Code">
                                                        <Form.Control type="text" placeholder="Project Code" isInvalid={!!errors.code} value={project.code ?? ""} onChange={e => setField('code', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.code} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Col>  <FloatingLabel controlId="floatingClient" label="Client" >
                                                        <Form.Select id="floatingClient" aria-label="Client" isInvalid={!!errors.client} value={project.clientid ?? "0"}
                                                            onChange={e => {
                                                                setField('clientid', e.target.value !== '0' ? e.target.value : null);

                                                                let client = clients.find(c => e.target.value && c.id === Number(e.target.value))
                                                                if (client) {
                                                                    setField('gstin', client.gstin);
                                                                }

                                                            }}>
                                                            <option value="0">Select Client</option>
                                                            {
                                                                filterClient(clients,"0").map((e, i) => {
                                                                    return <option key={`client_${i}`} value={e.id}>{e.name}</option>
                                                                })
                                                            }

                                                        </Form.Select>
                                                        <Form.Control.Feedback type='invalid'>{errors.client} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingGstin" label="GSTIN">
                                                        <Form.Control type="text" placeholder="GSTIN" isInvalid={!!errors.gstin} value={project.gstin ?? "0"} onChange={e => setField('gstin', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.gstin} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">

                                                    <Col>  <FloatingLabel controlId="floatingPo" label="Po To Vendor" >
                                                        <Form.Select id="floatingPo" aria-label="Po To Vendor" isInvalid={!!errors.potovendor} value={project.poReleased ?? "0"} onChange={e => setField('poReleased', e.target.value)}>
                                                            <option value="0">Select po status</option>
                                                            <option value="Y">Yes</option>
                                                            <option value="N">No</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type='invalid'>{errors.potovendor} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingBalance" label="Balance">
                                                        <Form.Control type="text" placeholder="Balance" value={project.balance ?? ""} readOnly />
                                                    </FloatingLabel>
                                                    </Col>
                                                    <Col> <FloatingLabel controlId="floatingPaid" label="Paid">
                                                        <Form.Control type="text" placeholder="Balance" value={project.received ?? ""} readOnly />
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>



                                            </Card.Body>
                                        </Card>

                                        <Card className="p-0 col-md-6 col-sm-12 ">

                                            <Card.Body>
                                                <Card.Title> <i className="fa fa-address-book-o "></i> Delivery Address</Card.Title>
                                                <hr></hr>

                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingAddr" label="Address">
                                                        <Form.Control type="text" placeholder="Billing Address" isInvalid={!!errors.address1} value={project.address.address1 ?? ""} onChange={e => setField('address1', e.target.value, 'address')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.address1} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                {/* <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingAddr2" label=" Address Line 2">
                                                        <Form.Control  type="text" placeholder="Billing Address Line 2 " value={project.address.address2 ?? ""} onChange={e => setField('address2', e.target.value, 'address')} />
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row> */}
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingCity" label="City">
                                                        <Form.Control type="text" placeholder="City" isInvalid={!!errors.city} value={project.address.city ?? ""} onChange={e => setField('city', e.target.value, 'address')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.city} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Col>
                                                        <Typeahead
                                                            id="floating-label-state"
                                                            onChange={(e) => {
                                                                setField('state', e[0], 'address')
                                                            }}
                                                            clearButton
                                                            onBlur={() => {
                                                                if (!project.address.state) {
                                                                    typeRef.current?.clear();
                                                                }
                                                            }}
                                                            options={states}
                                                            ref={typeRef}
                                                            placeholder="Choose a state..."
                                                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                                                return (

                                                                    <FloatingLabel controlId="floatingLabel" label="Select a state...">
                                                                        <Form.Control
                                                                            {...inputProps}
                                                                            ref={(node) => {
                                                                                inputRef(node);
                                                                                referenceElementRef(node);
                                                                            }}
                                                                        />
                                                                    </FloatingLabel>

                                                                );
                                                            }}
                                                            selected={project.address.state ? [project.address.state] : []}
                                                        />
                                                        {/* <FloatingLabel controlId="floatingState" label="State" >
                                                        <Form.Select id="floatingState" aria-label="State" isInvalid={!!errors.state} value={project.address.state ?? ""} onChange={e => setField('state', e.target.value !== '0' ? e.target.value : null, 'address')}>
                                                            <option value="0">Select State</option>
                                                            {
                                                                states.map((e, i) => {
                                                                    return <option key={`state_${i}`} value={e}>{e}</option>
                                                                })
                                                            }

                                                        </Form.Select>
                                                        <Form.Control.Feedback type='invalid'>{errors.state} </Form.Control.Feedback>
                                                    </FloatingLabel> */}
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel controlId="floatingZip" label="Pincode">
                                                            <Form.Control type="text" placeholder="Pincode" isInvalid={!!errors.pincode} value={project.address.pincode ?? ""} onChange={e => setField('pincode', e.target.value, 'address')} />
                                                            <Form.Control.Feedback type='invalid'>{errors.pincode} </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" disabled={!project.id} type="checkbox" id="flexSwitchInvoiceType"
                                                                checked={project.status === "Y"} onChange={(e) => { setField('status', e.target.checked ? "Y" : "N") }} />
                                                            <label className="form-check-label" htmlFor="flexSwitchInvoiceType"><b>Is Active? </b>
                                                                {
                                                                    project.closedDate && project.status === 'N' ? <sub className="d-flex text-muted">{project.closedDate}</sub> : null
                                                                }
                                                            </label>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Card.Body>
                                        </Card>


                                    </Row>

                                </Form>
                            </Card.Body>
                        </Card>

                    </Container>)
            } </React.Fragment>);
}

// }
