import {
    LOADALLCLIENT,
    LOADCLIENT,
    RESETCLIENT,
    CHANGECLIENTFIELD,
    RESETCLIENTS,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.client }, action) {

    switch (action.type) {
        case LOADALLCLIENT:
            return { ...state, clients: [...action.client.rows] }

        case LOADCLIENT:
            return { ...state, client: { ...action.client } };
        case CHANGECLIENTFIELD:
            if (action.model)
                return { ...state, client: { ...state.client, [action.model]: { ...(state.client[action.model] ?? {}), [action.name]: action.value } } };
            else
                return { ...state, client: { ...state.client, [action.name]: action.value } };
        case RESETCLIENT:
            return { ...state, client: { ...initialState.client.client } }
        case RESETCLIENTS:
            return { ...state, clients: [] }
        default:
            return { ...state };
    }
}