import {pAxios} from "..";
import {history} from "../app/history";

export const removeLocal=()=>{

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("costCenter");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("role");
    // localStorage.removeItem("userId")
}


export async function isAuthenticated (){
   
    let token = localStorage.getItem("token");
    if (token) {
      
      const res = await pAxios("/api/auth"); 
    
      if(res.status===200){
        return true;
      }else{
        removeLocal();
        history.push("/login");
        return false;
      }
   
    }
    else{
      removeLocal();
      history.push("/login");
      return false;
    }

}