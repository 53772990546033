import {
    LOADALLPO,
    LOADPO,
    RESETPO,
    CHANGEPOFIELD,
    ADDPOITEM,
    initialState,
    REMOVEPOITEM
} from "../constant/constant";

export default function reducer(state = { ...initialState.po }, action) {

    switch (action.type) {
        case LOADALLPO:
            return { ...state, pos: [...action.po.rows] }

        case LOADPO:
            return { ...state, po: { ...action.po } };
        case CHANGEPOFIELD:
            if (action.idx) {
                return {
                    ...state,
                    po: calculateTotal({ 
                        ...state.po,

                        items:[...state.po.items ? (
                            state.po.items.map(e => {
                                if (e.idx === action.idx) {
                                    return { ...e, [action.name]: action.value }
                                }
                                return e;
                            })
                        ) : []]

                    })
                };
            }
            else {
                return { ...state, po: calculateTotal({ ...state.po, [action.name]: action.value }) };
            }
        case ADDPOITEM:
            return { ...state, po: calculateTotal({ ...state.po, items: state.po.items ? [...state.po.items, { idx: action.idx }] : ([...{ idx: action.idx }]) }) }

        case REMOVEPOITEM:
            return { ...state, po: calculateTotal({ ...state.po, items: [...state.po.items.filter(e=> e.idx !== action.idx) ]}) }
        case RESETPO:
            return { ...state, po: { ...initialState.po.po } }
        default:
            return { ...state };
    }
}

function calculateTotal(po){

    if(po.items && po.items.length > 0){
       
             
           po.items =[...po.items.map((e) => {

            let basic = (Number(e.rate ?? 0) * Number(e.qty ?? 0)).toFixed(2) 
            let gstvalue = ( (Number(basic ?? 0) * Number(e.gstpercentage ?? 0))/100).toFixed(2); 

            return{...e,basic :  basic ,  gstvalue, amount: (Number(basic)+Number(gstvalue)).toFixed(2)}
            
        })]
       

       po.gst = Number(po.items.map((e) => Number(e.gstvalue??0))
       .reduce((prev, curr) => prev + curr, 0)).toFixed(2)

       po.basic=Number((po.items.map((e) => Number(e.basic ?? 0))
                .reduce((prev, curr) => prev + curr, 0)) ).toFixed(2)
       
    }

    po.gross = (Number(po.basic ?? 0) + Number(po.gst ?? 0) + Number(po.misc  ?? 0)).toFixed(2)
    
    return {...po}
}