import {
     initialState, UPDATE_DASH_PENDING,
     UPDATE_DASH_SPREAD,
     UPDATE_DASH_SPREAD_CLOSED,
     UPDATE_DASH_PROFIT
} from "../constant/constant";




export default function reducer(state = { ...initialState.dashboard }, action) {

    switch (action.type) {
       
        case UPDATE_DASH_SPREAD:
            return { ...state, highlightChart:action.value } ;
        case UPDATE_DASH_SPREAD_CLOSED:
                return { ...state, highlightChartClosed:action.value } ;
        case UPDATE_DASH_PENDING:
            return { ...state, request:action.value } ;
        case UPDATE_DASH_PROFIT:
            return { ...state, profit:action.value } ;
        default:
            return { ...state };
    }
}