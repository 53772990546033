import { LOADALLCATEGORY,
    LOADCATEGORY,
    RESETCATEGORY,
    CHANGECATEGORYFIELD,SET_NOTIFICATION, LOADALLCONFIG, LOAD_COSTCENTERS} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";


function loadAllCategoryService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/category`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLCATEGORY, category: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load category data",variant:"danger",err}  })
            });
    });

}


function loadAllConfigService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/config`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLCONFIG, config: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load config data",variant:"danger",err}  })
            });
    });

}


function loadCategoryService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/category/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADCATEGORY, category: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
          });
  });

}


function loadCostcenterService(id){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/costcenter`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOAD_COSTCENTERS, value: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load costcenter data",variant:"danger",err}  })
            });
    });
  
  }


function saveCategoryService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/category`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
          store.dispatch({
            type: SET_NOTIFICATION, notification:
                { message: `Category ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" }
            });
            resolve({ type: RESETCATEGORY});
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to save vendor data",variant:"danger",err}  })
          });
  });

}


 export const loadAllCategory=()=>{

    return dispatch => {

        loadAllCategoryService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadAllConfig=()=>{

    return dispatch => {

        loadAllConfigService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadCategory=(id)=>{

  return dispatch => {

      loadCategoryService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  

export const loadCostCenter=()=>{

    return dispatch => {
  
        loadCostcenterService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }
  
    
    

export const changeCategory=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGECATEGORYFIELD,name,value,model});
}
  

}

export const resetCategory=()=>{

  return dispatch => {
    dispatch({type:RESETCATEGORY,});
}
  
}

export const saveCategory=(vendor)=>{

  return dispatch => {
    saveCategoryService(vendor,"POST")
    .then(response => dispatch(response))
    .catch(err => dispatch(err));
}
  
}


export const updateCategory=(vendor)=>{

    return dispatch => {
      saveCategoryService(vendor,"PATCH")
      .then(response => dispatch(response))
      .catch(err => dispatch(err));
  }
    
  }



