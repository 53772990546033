import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Card, Col, } from "react-bootstrap";
import DatePicker from 'react-date-picker';
import { pAxios } from "..";
import fileDownload from 'js-file-download'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';


import { warningNotification } from "../action/notificationAction";

import {
  setEnableMobileMenu
} from '../constant/constant';
import { format, sub } from "date-fns";


export default function LabourReport({ permitted }) {

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [selectedName, setSelectedName] = useState(null);

  React.useEffect(() => {
    setSelectedName(null);
    dispatch(setEnableMobileMenu(false));
  }, []);

  const handleDownload = () => {

    if (startDate && endDate) {

      const data = {

        fromDate: format(startDate, "yyyy-MM-dd"),
        toDate: format(endDate, "yyyy-MM-dd"),
        selectedName: selectedName

      }

      let url = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      }).join('&')

      pAxios({
        "method": "GET",
        "url": "/api/auth",

      }).then(r => {

        pAxios.get(`/api/statement/labour/download?${url}`, {
          responseType: 'blob',
        })
          .then((res) => {
            fileDownload(res.data, `Labour Report ${format(startDate, "dd-MM-yyyy")} to ${format(endDate, "dd-MM-yyyy")}.XLSX`)
          })
      })

    }
    else {
      dispatch(warningNotification("Select a date range"));
    }
  }

  const theme = useSelector((state) => state.ThemeOptions);

  const onChange = (dates) => {

    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }

  };

  React.useEffect(() => {
    
    setSelectedName(null);
    setStartDate(sub(new Date(), { days: 7 } ));
    setEndDate(new Date());
    
  }, []);


  return (
    <React.Fragment>
      <Container fluid >

        <Card>

          <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
            <h6 className="float-start flex-fill">Labour Report  </h6>

          </Card.Header>

          <Card.Body>
            <Row className="mb-3 g-2">
              <Col sm={12} md={3} >
                <div style={{ position: "relative" }} className="dp">
                  <DateRangePicker maxDate={new Date()}
                    
                    format="dd-MM-yyyy"
                    onChange={onChange}
                    value = {[startDate, endDate]}
                    calendarIcon={<i className="fa fa-calendar"></i>}
                    // clearIcon = {null}
                    className="form-control"
                    isClearable={true}
                  ></DateRangePicker>
                  <label className="black-text">Working Date</label>
                </div>
              </Col>
              <Col >
                <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">
                  <Button variant="success" size="sm" onClick={handleDownload}>Download</Button>
                </div>
              </Col>

            </Row>

            <hr />
          </Card.Body>

        </Card>
      </Container>

    </React.Fragment >);
}

// }
