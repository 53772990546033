import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col, Container, Row, FloatingLabel, Form, Button } from "react-bootstrap";

import Table from "./Table";
// import { loadAllVendor, resetVendors } from "../action/vendorAction";
// import { loadAllProject, resetProjects } from "../action/projectAction";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { notification, warningNotification } from "../action/notificationAction";

import { format, startOfMonth } from "date-fns";
import { pAxios } from "..";
import { Typeahead } from "react-bootstrap-typeahead";
import { setEnableMobileMenu } from "../constant/constant";

// import { loadAllUser,  getUserPassword } from "../action/userAction";

export default function Accesslog({ permitted }) {


  const dispatch = useDispatch();


  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const userTypeRef = React.useRef(null);

  const users = useSelector(
    (state) => state.lookup.user
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onChange = (dates) => {
    if(dates && dates.length > 0){
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    }
    
  };


  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
    load();

  }, []);

  const load = (id) => {

    pAxios.get(`/api/accesslog${id ? `/${id}` : ''}?limit=100&fromDate=${format(startDate, "yyyy-MM-dd")} 00:00:00&toDate=${format(endDate,'yyyy-MM-dd')} 23:59:59`)
      .then((res) => {
        if (res?.data?.rows) {
          setData(res.data.rows)
        }
        else {
          setData([]);
        }
      })
      .catch(err => {
        setData([]);
        dispatch(warningNotification("Failed to load report data"))
      })
  }



  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },

      {
        Header: 'Screen',
        accessor: 'screen_name',
      },

      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'By',
        accessor: 'user',
      },
      {
        Header: "Date",
        accessor: (cell) => format(new Date(cell['createdAt']), 'dd-MM-yyyy HH:mm:ss')
      }
    ],
    []
  )




  return (
    <React.Fragment>

      <Container fluid className="mt-1">
        <Row className="mb-2 g-2">
          <Col sm={12} md={3}>
            <div style={{ position: "relative" }} className="dp">
              <DateRangePicker id="expDateRangeFilter" maxDate={new Date()}
                placeholderText="Select Expense Date"
                format="dd-MM-yyyy"
                selected={new Date()}
                onChange={onChange}
                
                value = {[startDate, endDate]}
                calendarIcon={<i className="fa fa-calendar"></i>}
                // clearIcon = {null}
                className="form-control"
                isClearable={true}
              ></DateRangePicker>
              <label className="black-text" htmlFor="expDateRangeFilter" >Log Date</label>
            </div>
          </Col>
          <Col sm={4}>
            <Typeahead
              id="floating-user-lbl"
              onChange={(e) => {
                setSelected(e[0]?.id)
              }}
              onBlur={() => {
                if (!selected) {
                  userTypeRef.current?.clear();

                }
              }}
              clearButton
              labelKey="name"
              options={users ?? []}
              ref={userTypeRef}
              placeholder="Select a user"
              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                return (

                  <FloatingLabel controlId="floatingUser" label="Select a user">
                    <Form.Control

                      {...inputProps}
                      ref={(node) => {
                        inputRef(node);
                        referenceElementRef(node);
                      }}
                    />

                  </FloatingLabel>

                );
              }}
              selected={selected ? users.filter(e => e.id === Number(selected)) : []}
            />
          </Col>

          <Col sm={12} md={1}>
            <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end align-items-end h-100  ">


              <Button variant="primary" size="sm" onClick={() => { load(selected) }}>Search</Button>

            </div>
          </Col>
        </Row>

        <Table columns={columns} data={data} >
        </Table>

      </Container>
    </React.Fragment >);








}