export const formatAccounting = (number)=>{
    if(number){

        return new Intl.NumberFormat('en-IN', { decimal: 2 }).format(Number(number))
    }
    else{
        return number
    }
}

export const formatCurrency = (number)=>{
    if(number){

        return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR', decimal: 2 }).format(Number(number))
    }
    else{
        return number
    }
}
