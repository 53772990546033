import {
    LOADALLCONTACT,
    LOADCONTACT,
    RESETCONTACT,
    CHANGECONTACTFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.contact }, action) {

    switch (action.type) {
        case LOADALLCONTACT:
            return {...state,contacts:[...action.contact.rows]}
       
        case LOADCONTACT:
            return { ...state, contact:{...action.contact} };
        case CHANGECONTACTFIELD:
            return { ...state, contact:{...state.contact,[action.name]:action.value}};
        case RESETCONTACT:
            return {...state,contact:{...initialState.contact.contact}}
        default:
            return { ...state };
    }
}