import { LOADALLPAYMENT,
    LOADPAYMENT,
    RESETPAYMENT,
    CHANGEPAYMENTFIELD,SET_NOTIFICATION, RESETPAYMENTS} from "../constant/constant";
import {pAxios} from "..";
// import store from "../app/store";


function loadAllPaymentService(status,startDate,endDate,type,paymenton,unallocated,mode,projectid,ownPermission,execludeAuto,costcenter,vendorid,clientid){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/payment?${status?"status="+status:""}${startDate? `&fromDate=${startDate}`:''}${endDate?`&toDate=${endDate}`:''}${type?"&type="+type:""}${paymenton?"&paymenton="+paymenton:""}${unallocated?`&unallocated=${unallocated}`:''}${mode?`&mode=${mode}`:''}${projectid? `&projectid=${projectid}`:''}${ownPermission ?`&ownPermission=true`:''}${execludeAuto?`&execludeAuto=Y`:''}${costcenter&&costcenter!=="0"?`&costcenter=${costcenter}`:''}${vendorid? `&vendorid=${vendorid}`:''}${clientid?`&clientid=${clientid}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLPAYMENT, payment: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load payment data",variant:"danger",err}  })
            });
    });

}

function loadAllLabourPayReqService(status,startDate,endDate,type,labourid,costcenter){

    return new Promise(function (resolve, reject) {

    pAxios({
            "method": "GET",
            "url": `/api/payment/req?${status?"status="+status:""}&fromDate=${startDate}&toDate=${endDate}&type=${type}${labourid?"&labourid="+labourid:""}${costcenter&&costcenter!=="0"?`&costcenter=${costcenter}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLPAYMENT, payment: response.data });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load payment data",variant:"danger",err}  })
            });
    });

}


export function loadPaymentService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/payment/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADPAYMENT, payment: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load payment data",variant:"danger",err}  })
          });
  });

}


export function savePaymentService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/payment`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
                resolve({ message: `Payment ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETPAYMENT});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save payment data",variant:"danger",err}  )
          });
  });

}

export function authorizePaymentService(id, paiddate, screen_name){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/payment/authorize?id=${id}&paiddate=${paiddate}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                 
            // store.dispatch({
            //   type: SET_NOTIFICATION, notification:
                  resolve({ message: `Payment processed successfully`, variant: "success", err: "" });
            //   });
           }
        })
            .catch(err => {
                
                reject({message:"Failed to authorize payment",variant:"danger",err})
            });
    });
  
  }
  
  export function approvePaymentService(id, screen_name){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/payment/approve?id=${id}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                 
            // store.dispatch({
            //   type: SET_NOTIFICATION, notification:
                  resolve({ message: `Payment approved successfully`, variant: "success", err: "" });
            //   });
           }
        })
            .catch(err => {
                
                reject({message:"Failed to approved payment",variant:"danger",err})
            });
    });
  
  }
  
  export function verifyPaymentService(id, screen_name){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/payment/verify?id=${id}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                 
            // store.dispatch({
            //   type: SET_NOTIFICATION, notification:
                  resolve({ message: `Payment verified successfully`, variant: "success", err: "" });
            //   });
           }
        })
            .catch(err => {
                
                reject({message:"Failed to verify payment",variant:"danger",err})
            });
    });
  
  }
  
  
  


export function deletepaymentService(id, screen_name) {


    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/payment?id=${id}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                        resolve({ message: response.data, variant: response.data.includes("success")? "success":'danger', err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to delete payment request", variant: "danger", err })
            });
    });

}

export function reversepaymentService(id,screen_name) {


    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "Post",
            "url": `/api/payment/reverse?id=${id}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                        resolve({ message: response.data, variant: response.data.includes("success")? "success":'danger', err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to reverse payment", variant: "danger", err })
            });
    });

}



 export const loadAllPayment=({status,mode,startDate,endDate,type,paymenton,unallocated,projectid,ownPermission,execludeAuto,costcenter,vendorid,clientid})=>{

    return dispatch => {

        loadAllPaymentService(status,startDate,endDate,type,paymenton,unallocated, mode,projectid,ownPermission,execludeAuto,costcenter,vendorid,clientid)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadAllPayReq=({status,startDate,endDate,type,labourid,costcenter})=>{

    return dispatch => {

        loadAllLabourPayReqService(status,startDate,endDate,type,labourid,costcenter)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const loadPayment=(id)=>{

  return dispatch => {

      loadPaymentService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changePayment=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEPAYMENTFIELD,name,value,model});
}
  

}

export const resetPayment=()=>{

  return dispatch => {
    dispatch({type:RESETPAYMENT,});
}
}

export const resetPayments=()=>{

    return dispatch => {
      dispatch({type:RESETPAYMENTS,});
  }
  
}

// export const savePayment=(vendor)=>{

//   return dispatch => {
//     savePaymentService(vendor,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updatePayment=(vendor)=>{

//     return dispatch => {
//       savePaymentService(vendor,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }




