import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Form, Card, Col, FloatingLabel } from "react-bootstrap";

import MasterToolBar from "./MasterToolBar"
import Table from "./Table"
import { notification } from "../action/notificationAction";

import {
  setEnableMobileMenu
} from '../constant/constant';
import { changeContact, loadAllContact, loadContact, resetContact, saveContactService } from "../action/contactAction";



export default function VendorContact({ permitted }) {

  
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);


  const contacts = useSelector(
    (state) => state.contact.contacts
  );

  const contact = useSelector(
    (state) => state.contact.contact
  );



  const handleClose = () => {
    setShow(false);
    setErrors({});
    setSelectedContactId(null);
    dispatch(resetContact());
    dispatch(loadAllContact());
  }

  const handleShow = (selected) => {
    setShow(true);
    setErrors({});

    if (selected && selected.original.id) {

      setSelectedContactId(selected.original.id);
      dispatch(loadContact(selected.original.id));
    }
    else {
      setSelectedContactId(null);
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        className:'text-wrap w-150',
        textFilter: true
      },
      {
        Header: 'ContactPerson',
        accessor: (cell)=> {
          return (cell['contact_person']??"").replace(/,/g, ", ")
        },
        className:'text-wrap w-200',
        textFilter: true
      },
      
      {
        Header: 'Phone',
        accessor: (cell)=> {
          return (cell['contact_number']??"").replace(/,/g, ", ")
        },
        className:'text-wrap w-200',
        textFilter: true
      },

      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Designation',
        accessor: 'designation',
      },
      {
        Header: 'Division',
        accessor: 'division',
      }, {
        Header: 'Make',
        accessor: 'make',
      },
      
    ],
    []
  )

  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let dataToPost = {};
      let method = "POST";

      if (!contact.id) {
        dataToPost = {
          ...contact,
         
        }
      }
      else {
        dataToPost = {
          ...contact,
        
        };
        method = "PATCH"
      }

      saveContactService(dataToPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetContact());
      })
        .catch(err => {
          dispatch(notification(err));
        })

    }

  }

  const findFormErrors = () => {

    const { name } = contact
    const newErrors = {}

    if (!name || name === '') newErrors.name = 'Name cannot be blank!'


    return newErrors
  }

  const setField = (field, value, model) => {
    dispatch(changeContact({ name: field, value }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  React.useEffect(() => {
    dispatch(loadAllContact());
  }, [dispatch]);

  React.useEffect(()=>{
    dispatch(setEnableMobileMenu(false));
  },[dispatch])

  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`CONTRACT_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`CONTRACT_UPDATE`));

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      <MasterToolBar createPermission updatePermission id={contact.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose}></MasterToolBar>
      {
        !show ? (

          <Container fluid >
            <Table columns={columns} data={contacts} title="Vendor Contacts" edit={handleShow} ></Table>
          </Container>) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>Vendor Contact</Card.Header>
              <Card.Body className="p-0">
                <Form>
                  <Row className="m-auto">

                    <Card className="p-0 col-md-6 col-sm-12">

                      <Card.Body>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingName" label="Vendor Name" >
                            <Form.Control type="text" placeholder="Vendor Name" isInvalid={!!errors.name} value={contact.name ?? ""} onChange={e => setField('name', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                       

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingEmail" label="Email" >
                            <Form.Control type="email" placeholder="Email" isInvalid={!!errors.mail} value={contact.mail_id ?? ""} onChange={e => setField('mail_id', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.mail} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingPhone" label="Phone Number">
                            <Form.Control type="text" placeholder="Phone" isInvalid={!!errors.phone} value={contact.contact_number ?? ""} onChange={e => setField('contact_number', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingContact" label="Contact Person">
                            <Form.Control type="text" placeholder="Contact Person" isInvalid={!!errors.contact} value={contact.contact_person ?? ""} onChange={e => setField('contact_person', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.contact} </Form.Control.Feedback>
                          </FloatingLabel>
                            <Form.Text className="text-muted">
                              #Use comma to seperate multiple contact person and phone number  
                            </Form.Text>

                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingDesignation" label="Designation" >
                            <Form.Control type="text" placeholder="Vendor Name" isInvalid={!!errors.designation} value={contact.designation ?? ""} onChange={e => setField('designation', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                      </Card.Body>
                    </Card>

                    <Card className="p-0 col-md-6 col-sm-12">

                      <Card.Body>
                       

                        
                        {/* <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingAddr2" label="Address Line 2">
                            <Form.Control type="text" placeholder="Billing Address 2 " value={vendor.address.address2 ?? ""} onChange={e => setField('address2', e.target.value, 'address')} />
                          </FloatingLabel>
                          </Col>
                        </Row> */}
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingCategory" label="Category">
                            <Form.Control type="text" placeholder="Category" isInvalid={!!errors.category} value={contact.category ?? ""} onChange={e => setField('category', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.category} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingDivision" label="Division">
                            <Form.Control type="text" placeholder="Division" isInvalid={!!errors.division} value={contact.division ?? ""} onChange={e => setField('division', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.division} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingMake" label="Make">
                            <Form.Control type="text" placeholder="Make" isInvalid={!!errors.make} value={contact.make ?? ""} onChange={e => setField('make', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.make} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingAddr" label="Address">
                            <Form.Control type="textarea"  style={{ height: '100px' }} placeholder="Address" isInvalid={!!errors.address1} value={contact.address ?? ""} onChange={e => setField('address', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.address1} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        

                      </Card.Body>
                    </Card>

                   

                  </Row>

                </Form>
              </Card.Body>
            </Card>


          </Container>)
      } </React.Fragment>);
}

// }
