import { LOADALLACTIVITY,
    LOADACTIVITY,
    RESETACTIVITY,
    CHANGEACTIVITYFIELD,SET_NOTIFICATION, LOADACTIVITYLOOKUP} from "../constant/constant";
import {pAxios} from "..";
// import store from "../app/store";


function loadAllActivityService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/activity`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLACTIVITY, activity: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load activity data",variant:"danger",err}  })
            });
    });

}

function loadActivityService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/activity/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADACTIVITY, activity: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
          });
  });

}

function loadActivityLookUpService(){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/activity?status=active&attributes=id,name&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADACTIVITYLOOKUP, activity: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load activity lookup",variant:"danger",err}  })
            });
    });
  
  }


export function saveActivityService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/activity`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
                resolve({ message: `Activity ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETACTIVITY});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save vendor data",variant:"danger",err}  )
          });
  });

}


 export const loadAllActivity=()=>{

    return dispatch => {

        loadAllActivityService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadActivity=(id)=>{

  return dispatch => {

      loadActivityService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeActivity=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEACTIVITYFIELD,name,value,model});
}
  

}

export const resetActivity=()=>{

  return dispatch => {
    dispatch({type:RESETACTIVITY,});
}
  
}

// export const saveActivity=(vendor)=>{

//   return dispatch => {
//     saveActivityService(vendor,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateActivity=(vendor)=>{

//     return dispatch => {
//       saveActivityService(vendor,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }




  export const loadActivityLookup=()=>{

    return dispatch => {
  
        loadActivityLookUpService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }