import {
    LOADROLEMAPPING,
    CHANGEROLEMAPPING,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.rolemapping }, action) {

    switch (action.type) {
        case LOADROLEMAPPING:
            return {...state,roleMapping:[...action.roleMapping]}
       
        
        case CHANGEROLEMAPPING:
            return {...state, roleMapping:[
                ...state.roleMapping.map(e=>{
  
                    if(e.permissionid===action.pid){
                      return {...e, [action.field] : action.value?1:0 }
                    }
                    else{
                      return e;
                    }
                  })
            ]};
      
        default:
            return { ...state };
    }
}