import { LOADALLCONTACT,
    LOADCONTACT,
    RESETCONTACT,
    CHANGECONTACTFIELD,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";


function loadAllContactService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/contacts`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLCONTACT, contact: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load contacts data",variant:"danger",err}  })
            });
    });

}

function loadContactService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/contacts/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADCONTACT, contact: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load contact data",variant:"danger",err}  })
          });
  });

}

export function saveContactService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/contacts`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
               resolve({ message: `Contact ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETCONTACT});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save contact data",variant:"danger",err}  )
          });
  });

}


 export const loadAllContact=()=>{

    return dispatch => {

        loadAllContactService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadContact=(id)=>{

  return dispatch => {

      loadContactService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeContact=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGECONTACTFIELD,name,value,model});
}
  

}

export const resetContact=()=>{

  return dispatch => {
    dispatch({type:RESETCONTACT,});
}
  
}



