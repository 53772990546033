import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Form, Card, Col, FloatingLabel, Button, Badge } from "react-bootstrap";

import MasterToolBar from "./MasterToolBar"
import Table from "./Table"
import { states } from '../constant/in_state'
import { changeLabour, loadAllLabour, loadLabour, loadLabourLookup, resetLabour, saveLabourService } from "../action/labourAction";
import { isObjectEmpty } from "../util/util";

import { notification } from "../action/notificationAction";
// import { loadAllCustomer, updateStatus } from '../action/customerAction';
import { Typeahead } from "react-bootstrap-typeahead";
import {
  setEnableMobileMenu
} from '../constant/constant';


export default function Labour({ permitted }) {

  const typeRef = React.useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState("active");
  const dispatch = useDispatch();


  // eslint-disable-next-line no-unused-vars
  const [selectedLabourId, setSelectedLabourId] = useState(null);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);


  const costcenters = useSelector(
    (state) => state.costcenter.costcenters
  );


  const labour = useSelector(
    (state) => state.labour.labour
  );

  const labours = useSelector(
    (state) => state.labour.labours
  );

  const lookup = useSelector(
    (state) => state.lookup.category
  );



  const handleClose = () => {
    setErrors({});
    setShow(false);
    setSelectedLabourId(null);
    dispatch(resetLabour());
    dispatch(loadAllLabour(status));
  }

  const handleShow = (selected) => {
    setErrors({});
    setShow(true);
    if (selected && selected.original.id) {

      setSelectedLabourId(selected.original.id);
      dispatch(loadLabour(selected.original.id));
    }
    else {
      setSelectedLabourId(null);
    }
  }


  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let datatoPost = {};
      let method = "POST";

      if (!labour.id) {

        datatoPost = {
          ...labour,
          categoryid: labour.categoryid !== "0" ? labour.categoryid : null,
          status: 'Y',
          address: isObjectEmpty(labour.address) ? null : labour.address,
          aadhar: isObjectEmpty(labour.aadhar) ? null : labour.aadhar,
          bank: isObjectEmpty(labour.bank) ? null : labour.bank,
          //   client: isObjectEmpty(labour.client)  ? null : labour.client          
        };
      }
      else {
        datatoPost = {
          ...labour,
          address: isObjectEmpty(labour.address) ? null : labour.address,
          aadhar: isObjectEmpty(labour.aadhar) ? null : labour.aadhar,
          bank: isObjectEmpty(labour.bank) ? null : labour.bank,
          //   client: isObjectEmpty(project.client) ? null : project.client
        };
        method = "PATCH"
      }

      saveLabourService(datatoPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetLabour());
        dispatch(loadLabourLookup());
      })
        .catch(err => {
          notification(err);
        })

    }

  }

  const findFormErrors = () => {

    const { name, categoryid, costcenter } = labour
    const newErrors = {}

    if (!name || name === '') newErrors.name = 'Name cannot be blank!';

    if (!categoryid || categoryid === "0") newErrors.category = 'Select a labour category!'

    if (!costcenter || costcenter === "0") newErrors.costcenter = 'Select a cost center!'

    return newErrors
  }

  const setField = (field, value, model) => {
    dispatch(changeLabour({ name: field, value, model }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const  fnCostCenter = (lookup,costcenter)=>{

    const cc =  lookup?.CLIENT?.find(e=> e.id===Number(costcenter))

    return cc? cc.name : "";
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        textFilter: true
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        textFilter: true
      },
      {
        Header: 'Address',
        accessor: 'address.address1',
        className: 'w-500',
        textFilter: true
      },

      {
        Header: 'Category',
        accessor: 'categoryname',

      },
      {
        Header: 'Cost Center',
        accessor: (cell)=> <Badge className="me-1"> {fnCostCenter(lookup, cell['costcenter'])} </Badge>,
        hideFilter: true
      },
      {
        Header: 'Wage',
        accessor: 'wage',
        textFilter: true
      }, {
        Header: 'Closed',
        accessor: 'closedDate',
        show: status === 'active'
      }
    ],
    [status, lookup]
  )

  React.useEffect(() => {
    dispatch(loadAllLabour(status));
  }, [dispatch, status]);


  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])

  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`LABOUR_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`LABOUR_UPDATE`));

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      <MasterToolBar createPermission updatePermission id={labour.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose}></MasterToolBar>
      {
        !show ? (

          <Container fluid >
            <Table columns={columns} data={labours} title="Labour" edit={handleShow} hideColumn={status === 'active' ? ['closedDate'] : []}> </Table>
          </Container>) : (
          <Container fluid >
            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>

                <h6 className="mb-0">Labour  </h6>

                <Button variant="secondary" className="ms-auto" size="sm" onClick={() => { }}
                  title="Transaction History">
                  <i className="fa fa-history d-md-none d-sm-inline"></i>
                  <p className="d-none d-md-inline">Transaction</p></Button>

              </Card.Header>
              <Card.Body className="p-0">
                <Form>
                  <Row className="m-auto">

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title><i className="fa fa-user-o "></i> Labour Info</Card.Title>
                        <hr></hr>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingName" label="Labour Name">
                            <Form.Control type="Name" placeholder="Labour Name" isInvalid={!!errors.name} value={labour.name ?? ""} onChange={e => setField('name', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingPhone" label="Phone Number">
                            <Form.Control type="text" placeholder="Phone" isInvalid={!!errors.phone} value={labour.phone ?? ""} onChange={e => setField('phone', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>


                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingPf" label="Pf Number">
                            <Form.Control type="text" placeholder="PF" isInvalid={!!errors.pfno} value={labour.pfno ?? ""} onChange={e => setField('pfno', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.pfno} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>


                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingEsi" label="ESIC No">
                            <Form.Control type="text" placeholder="Esic no" isInvalid={!!errors.esicno} value={labour.esicno ?? ""} onChange={e => setField('esicno', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.esicno} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingWage" label="Wage">
                            <Form.Control type="text" placeholder="Wage" isInvalid={!!errors.wage} value={labour.wage ?? ""} onChange={e => setField('wage', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.wage} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingCategory" label="Category">
                            <Form.Select aria-label="Type" isInvalid={!!errors.category} value={labour.categoryid ?? "0"} onChange={e => setField('categoryid', e.target.value)}>
                              <option value="0">Select Category</option>
                              {
                                lookup && lookup.LABOUR ? (
                                  lookup.LABOUR.map((e, i) => {
                                    return <option key={`cat_${i}`} value={e.id} >{e.name}</option>
                                  })
                                ) : null
                              }


                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors.category} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        {
                          costcenters ? (
                            <Row className="mb-3">
                              <Col>



                                <FloatingLabel controlId="costcenterSelect" label="Cost Center" >

                                  <Form.Select id="categorySelect" aria-label="Category"
                                    isInvalid={!!errors.costcenter}
                                    value={labour.costcenter ?? "0"}
                                    onChange={e => setField("costcenter", e.target.value)}
                                  >

                                    <option value={0}>Select Cost Center</option>
                                    {
                                      costcenters ? costcenters.map((e, i) => {
                                        return <option key={`cate${i}`} value={e.id}>{e.name}</option>
                                      }) : null
                                    }

                                  </Form.Select>
                                  <Form.Control.Feedback type='invalid'>{errors.costcenter} </Form.Control.Feedback>
                                </FloatingLabel>


                              </Col>
                            </Row>
                          ) : null
                        }


                      </Card.Body>
                    </Card>

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title> <i className="fa fa-address-book-o "></i> Address</Card.Title>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingAadhar" label="Aadhar">
                            <Form.Control type="text" placeholder="Aadhar" isInvalid={!!errors.aadhar} value={labour.aadhar.number ?? ""} onChange={e => setField('number', e.target.value, 'aadhar')} />
                            <Form.Control.Feedback type='invalid'>{errors.aadhar} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingAddr"
                            label="Aadhar address"
                          >
                            <Form.Control type="text" placeholder="Aadhar address" isInvalid={!!errors.address1} value={labour.address.address1 ?? ""} onChange={e => setField('address1', e.target.value, 'address')} />
                            <Form.Control.Feedback type='invalid'>{errors.address1} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        {/* <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingAddr2"
                            label="Aadhar address line 2"
                          >
                            <Form.Control type="text" placeholder="Aadhar address line 2 " value={labour.address.address2 ?? ""} onChange={e => setField('address2', e.target.value, 'address')} />
                          </FloatingLabel>
                          </Col>
                        </Row> */}
                        <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingCity"
                            label="City"
                          >
                            <Form.Control type="text" placeholder="City" isInvalid={!!errors.city} value={labour.address.city ?? ""} onChange={e => setField('city', e.target.value, 'address')} />
                            <Form.Control.Feedback type='invalid'>{errors.city} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col>
                            <Typeahead
                              id="floating-label-state"
                              onChange={(e) => {
                                setField('state', e[0], 'address')
                              }}
                              clearButton
                              onBlur={() => {
                                if (!labour.address.state) {
                                  typeRef.current?.clear();
                                }
                              }}
                              options={states}
                              ref={typeRef}
                              placeholder="Choose a state..."
                              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                return (

                                  <FloatingLabel controlId="floatingLabel" label="Select a state...">
                                    <Form.Control
                                      {...inputProps}
                                      ref={(node) => {
                                        inputRef(node);
                                        referenceElementRef(node);
                                      }}
                                    />
                                  </FloatingLabel>

                                );
                              }}
                              selected={labour.address.state ? [labour.address.state] : []}
                            />
                            {/* <FloatingLabel controlId="floatingState" label="State" >
                            <Form.Select aria-label="State" isInvalid={!!errors.state} value={labour.address.state ?? "0"} onChange={e => setField('state', e.target.value, 'address')}>
                              <option value="0">Select State</option>
                              {
                                states.map((e, i) => {
                                  return <option key={`state_${i}`} value={e}>{e}</option>
                                })
                              }

                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors.state} </Form.Control.Feedback>
                          </FloatingLabel> */}
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingZip"
                              label="Pincode"
                            >
                              <Form.Control type="text" placeholder="Pincode" isInvalid={!!errors.pincode} value={labour.address.pincode ?? ""} onChange={e => setField('pincode', e.target.value, 'address')} />
                              <Form.Control.Feedback type='invalid'>{errors.pincode} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <Form.Group controlId="formFile" >
                              <Form.Label>Aadhar Card</Form.Label>
                              <Form.Control type="file" />
                            </Form.Group>
                          </Col>
                        </Row>

                      </Card.Body>
                    </Card>

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title className={labour?.bank?.verified === 'Y' ? 'text-success' : 'text-danger'}> <i className="fa fa-university "></i> Bank Details - {labour?.bank?.verified === 'Y' ? 'Verified' : labour?.bank?.verified === 'N' ? 'Unverified' : ''}</Card.Title>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingbank"
                            label="Bank Name"
                          >
                            <Form.Control type="text" placeholder="Bank" isInvalid={!!errors.bankname} value={labour.bank.name ?? ""} onChange={e => setField('name', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.bankname} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingBranch"
                            label="Branch"
                          >
                            <Form.Control type="text" placeholder="Branch" isInvalid={!!errors.branch} value={labour.bank.branch ?? ""} onChange={e => setField('branch', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.branch} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingIfsc"
                            label="IFSC Code"
                          >
                            <Form.Control type="text" placeholder="IFSC Code" isInvalid={!!errors.ifsc} value={labour.bank.ifsc ?? ""} onChange={e => setField('ifsc', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.ifsc} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel
                            controlId="floatingAcc"
                            label="Account Number"
                          >
                            <Form.Control type="text" placeholder="Account Number" isInvalid={!!errors.accountnumber} value={labour.bank.number ?? ""} onChange={e => setField('number', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.accountnumber} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingPan" label="PAN Number">
                              <Form.Control type="text" placeholder="PAN Number" isInvalid={!!errors.pan} value={labour.pan ?? ""} onChange={e => setField('pan', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.pan} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingGpay" label="Gpay Number">
                              <Form.Control type="text" placeholder="Gpay Number" isInvalid={!!errors.gpay} value={labour.bank.gpay_number ?? ""} onChange={e => setField('gpay_number', e.target.value,'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.gpay} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingGpayName" label="Gpay Name">
                              <Form.Control type="text" placeholder="Gpay Name" isInvalid={!!errors.gpayName} value={labour.bank.gpay_name ?? ""} onChange={e => setField('gpay_name', e.target.value,'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.gpayName} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingBalance" label="Balance">
                            <Form.Control type="text" placeholder="Balance" value={labour.balance ?? ""} readOnly />
                          </FloatingLabel>
                          </Col>
                          <Col> <FloatingLabel controlId="floatingPaid" label="Paid">
                            <Form.Control type="text" placeholder="Balance" value={labour.paid ?? ""} readOnly />
                          </FloatingLabel>
                          </Col>
                        </Row>




                      </Card.Body>
                    </Card>

                  </Row>

                </Form>

              </Card.Body>
            </Card>


          </Container>)
      } </React.Fragment>);
}

// }
