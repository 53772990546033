import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Tooltip} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";

import {setHeaderBackgroundColor, setBackgroundColor} from '../../constant/constant';

function TooltipItem({id,item}){

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const toggle = ()=> {
       setTooltipOpen(!tooltipOpen);
    }


    
        return (
            <Fragment>
                <div className={item.class} id={'Tooltip-' +id} />
                <Tooltip isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
                    {item.text}
                </Tooltip>
            </Fragment>
        );
    
}

// Gradients

export function ColorGradients({selected, setSelectedColor}) {
  

    
    const dispatch = useDispatch();

    const[tooltips, setTooltips] = React.useState([
        {
            text: 'Primary',
            class: 'swatch-holder swatch-holder-lg bg-primary header-text-light'
        },
        {
            text: 'Success',
            class: 'swatch-holder swatch-holder-lg bg-success header-text-light'
        },
        {
            text: 'Info',
            class: 'swatch-holder swatch-holder-lg bg-info header-text-light'
        },
        {
            text: 'Warning',
            class: 'swatch-holder swatch-holder-lg bg-warning header-text-dark'
        },
        {
            text: 'Danger',
            class: 'swatch-holder swatch-holder-lg bg-danger header-text-light'
        },
        {
            text: 'Focus',
            class: 'swatch-holder swatch-holder-lg bg-focus header-text-light'
        },
        {
            text: 'Alt',
            class: 'swatch-holder swatch-holder-lg bg-alternate header-text-light'
        },
        {
            text: 'Dark',
            class: 'swatch-holder swatch-holder-lg bg-dark header-text-light'
        },
        {
            text: 'Happy Green',
            class: 'swatch-holder swatch-holder-lg bg-happy-green header-text-light'
        },
        {
            text: 'Premium Dark',
            class: 'swatch-holder swatch-holder-lg bg-premium-dark header-text-light'
        },
        {
            text: 'Love Kiss',
            class: 'swatch-holder swatch-holder-lg bg-love-kiss header-text-light'
        },
        {
            text: 'Grow Early',
            class: 'swatch-holder swatch-holder-lg bg-grow-early header-text-light'
        },
        // {
        //     text: 'Strong Bliss',
        //     class: 'swatch-holder swatch-holder-lg bg-strong-bliss header-text-light'
        // },
        // {
        //     text: 'Warm Flame',
        //     class: 'swatch-holder swatch-holder-lg bg-warm-flame header-text-dark'
        // },
        // {
        //     text: 'Tempting Azure',
        //     class: 'swatch-holder swatch-holder-lg bg-tempting-azure header-text-dark'
        // },
        // {
        //     text: 'Sunny Morning',
        //     class: 'swatch-holder swatch-holder-lg bg-sunny-morning header-text-dark'
        // },
        // {
        //     text: 'Mean Fruit',
        //     class: 'swatch-holder swatch-holder-lg bg-mean-fruit header-text-dark'
        // },
        // {
        //     text: 'Night Fade',
        //     class: 'swatch-holder swatch-holder-lg bg-night-fade header-text-dark'
        // },
        // {
        //     text: 'Amy Crisp',
        //     class: 'swatch-holder swatch-holder-lg bg-amy-crisp header-text-dark'
        // },
        {
            text: 'Malibu Beach',
            class: 'swatch-holder swatch-holder-lg bg-malibu-beach header-text-light'
        },
        {
            text: 'Under the lake',
            class: 'swatch-holder swatch-holder-lg bg-deep-blue header-text-light'
        },
        // {
        //     text: 'Mixed Hopes',
        //     class: 'swatch-holder swatch-holder-lg bg-mixed-hopes header-text-light'
        // },
        // {
        //     text: 'Happy Itmeo',
        //     class: 'swatch-holder swatch-holder-lg bg-happy-itmeo header-text-dark'
        // },
        {
            text: 'Crimson Tide',
            class: 'swatch-holder swatch-holder-lg bg-happy-fisher header-text-dark'
        },
        // {
        //     text: 'Arielle Smile',
        //     class: 'swatch-holder swatch-holder-lg bg-arielle-smile header-text-dark'
        // },
        {
            text: 'Ripe Malin',
            class: 'swatch-holder swatch-holder-lg bg-ripe-malin header-text-light'
        },
        {
            text: 'Vicious Stance',
            class: 'swatch-holder swatch-holder-lg bg-vicious-stance header-text-light'
        },
        {
            text: 'Midnight Bloom',
            class: 'swatch-holder swatch-holder-lg bg-midnight-bloom header-text-light'
        },
        {
            text: 'Night Sky',
            class: 'swatch-holder swatch-holder-lg bg-night-sky header-text-light'
        },
        {
            text: 'Slick Carbon',
            class: 'swatch-holder swatch-holder-lg bg-slick-carbon header-text-light'
        },
        {
            text: 'Royal',
            class: 'swatch-holder swatch-holder-lg bg-royal header-text-light'
        },
        {
            text: 'Asteroid',
            class: 'swatch-holder swatch-holder-lg bg-asteroid header-text-light'
        },
        {
            text: 'Crimson Red', 
            class: 'swatch-holder swatch-holder-lg bg-crimsonred header-text-light'

        }
    ])

    const toggleColor = (color) => {

        if(selected === '2'){
            
            setSelectedColor(`${color.class.replace('swatch-holder swatch-holder-lg','').replace('header-text','sidebar-text')} `)
            dispatch(setBackgroundColor(`${color.class.replace('swatch-holder swatch-holder-lg','').replace('header-text','sidebar-text')} `));
        }
        else{
            setSelectedColor(`${color.class.replace('swatch-holder swatch-holder-lg','')}`);
            dispatch(setHeaderBackgroundColor(`${color.class.replace('swatch-holder swatch-holder-lg','')}`));
        }

    }


    
        return (
            <Fragment>
               
                {tooltips.map((tooltip, i) => {
                    return (
                        <span onClick={() => toggleColor(tooltip)} key={i} >
                            <TooltipItem key={i} item={tooltip} id={i} />
                        </span>
                    )
                })}
            </Fragment>
        );
    
}

// export default ColorGradients;
