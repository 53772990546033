import {
    LOADALLINVOICE,
    LOADINVOICE,
    RESETINVOICE,
    CHANGEINVOICEFIELD,
    ADDINVOICEITEM,
    initialState,
    REMOVEINVOICEITEM,
    RESETINVOICEITEMS,
    CHANGEINVOICEITEMS
} from "../constant/constant";

export default function reducer(state = { ...initialState.invoice }, action) {

    switch (action.type) {
        case LOADALLINVOICE:
            return { ...state, invoices: [...action.invoice.rows] }

        case LOADINVOICE:
            return { ...state, invoice: { ...action.invoice } };
        case CHANGEINVOICEITEMS:
            return {...state,invoice:calculateTotal({...state.invoice,...action.item})};
        case CHANGEINVOICEFIELD:
            if (action.idx) {
                return {
                    ...state,
                    invoice: calculateTotal({
                        ...state.invoice,

                        items: [...state.invoice.items ? (
                            state.invoice.items.map(e => {
                                if (e.idx === action.idx) {
                                    return { ...e, [action.name]: action.value }
                                }
                                return e;
                            })
                        ) : []]

                    })
                };
            }
            else {
                return { ...state, invoice: calculateTotal({ ...state.invoice, [action.name]: action.value }) };
            }
        case ADDINVOICEITEM:
            return { ...state, invoice: calculateTotal({ ...state.invoice, items: state.invoice.items ? [...state.invoice.items, { ...(action.row ?? {}), idx: action.idx }] : ([...{ ...(action.row ?? {}), idx: action.idx }]) }) }

        case REMOVEINVOICEITEM:
            return { ...state, invoice: calculateTotal({ ...state.invoice, items: [...state.invoice.items.filter(e => e.idx !== action.idx)] }) }

        case RESETINVOICE:
            return { ...state, invoice: { ...initialState.invoice.invoice }}

        case RESETINVOICEITEMS:
            return { ...state, invoice: { } }

        default:
            return { ...state };
    }
}



function calculateTotal(invoice){

    if(invoice.items && invoice.items.length > 0){

        
             
           invoice.items =[...invoice.items.map((e) => {

            let basic = (Number(e.rate ?? 0) * Number(e.qty ?? 0)).toFixed(2);
            let gstvalue = ( (Number(basic ?? 0) * Number(e.gstpercentage ?? 0))/100).toFixed(2); 

            return{...e,basic :basic,  gstvalue, amount: (Number(basic)+Number(gstvalue)).toFixed(2)}
            
        })]
       

       invoice.gst = Number(invoice.items.map((e) => Number(e.gstvalue??0))
       .reduce((prev, curr) => prev + curr, 0)).toFixed(2)

       invoice.basic=Number((invoice.items.map((e) => Number(e.basic ?? 0))
                .reduce((prev, curr) => prev + curr, 0)) ).toFixed(2)
       
    }

    invoice.gross = (Number(invoice.basic ?? 0) + Number(invoice.gst ?? 0) + Number(invoice.misc  ?? 0)).toFixed(2)
    
    return {...invoice}
}