import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Container, Table,Form, Alert } from "react-bootstrap";

import { pAxios } from "..";

import { warningNotification } from "../action/notificationAction";
import { LOADROLEMAPPING } from "../constant/constant";
import {
  setEnableMobileMenu
} from '../constant/constant';



export default function Role({ permitted }) {


  const dispatch = useDispatch();
  // const [roleMapping,setRoleMapping] =  useState([]);

  const roleMapping = useSelector(
    (state) => state.roleMapping.roleMapping
  );

  // const roles = ['MASTER','DIRECTOR','FINANCE','ACCOUNTS','PROJECTMANAGER','SITEENGINEER','SUPERVISOR'];


  const [globalFilter, setGlobalFilter] = React.useState("");


  const loadRoles = () => {

    pAxios.get(`/api/rolemapping`)
      .then((res) => {
        dispatch({ type: LOADROLEMAPPING, roleMapping: (res.data) })
      })
      .catch(err => {
        // setRoleMapping([]);
        dispatch(warningNotification("Failed to load rolemapping data"))
      })
  }


  const columns = React.useMemo(
    () => [
      {
        Header: 'Permission',
        accessor: 'permission',
      },
      {
        Header: 'MASTER',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['MASTER'] === 1} key={`${cell['permission']}-MASTER`} name={`${cell['permission']}-MASTER`}
            onChange={(e) => { changeRole(cell['permissionid'], 1, e.target.checked) }} />;
        },
      },
      {
        Header: 'DIRECTOR',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['DIRECTOR'] === 1} key={`${cell['permission']}-DIRECTOR`} name={`${cell['permission']}-DIRECTOR`}
            onChange={(e) => { changeRole(cell['permissionid'], 2, e.target.checked) }} />;
        },
      },
      {
        Header: 'FINANCE',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['FINANCE'] === 1} key={`${cell['permission']}-FINANCE`} name={`${cell['permission']}-FINANCE`}
            onChange={(e) => { changeRole(cell['permissionid'], 3, e.target.checked) }} />;
        },
      },
      {
        Header: 'ACCOUNTS',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['ACCOUNTS'] === 1} key={`${cell['permission']}-ACCOUNTS`} name={`${cell['permission']}-ACCOUNTS`}
            onChange={(e) => { changeRole(cell['permissionid'], 4, e.target.checked) }} />;
        },

      },
      {
        Header: 'PROJECTMANAGER',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['PROJECTMANAGER'] === 1} key={`${cell['permission']}-PROJECTMANAGER`} name={`${cell['permission']}-PROJECTMANAGER`}
            onChange={(e) => { changeRole(cell['permissionid'], 5, e.target.checked) }} />;
        },
      },
      {
        Header: 'SITEENGINEER',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['SITEENGINEER'] === 1} key={`${cell['permission']}-SITEENGINEER`} name={`${cell['permission']}-SITEENGINEER`}
            onChange={(e) => { changeRole(cell['permissionid'], 6, e.target.checked) }} />;
        },
      },
      {
        Header: 'SUPERVISOR',
        accessor: (cell) => {
          return <input type="checkbox" checked={cell['SUPERVISOR'] === 1} key={`${cell['permission']}-SUPERVISOR`} name={`${cell['permission']}-SUPERVISOR`}
            onChange={(e) => { changeRole(cell['permissionid'], 7, e.target.checked) }} />;
        },
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )




  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`USER_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`USER_UPDATE`));

  React.useEffect(() => {
    loadRoles();
    dispatch(setEnableMobileMenu(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRole = (pid, roleid, checked) => {


    if (checked) {
      pAxios.post(`/api/rolemapping?pid=${pid}&roleid=${roleid}`)
        .then((res) => {
          loadRoles();
        })
        .catch(err => {
          // setRoleMapping([]);
          dispatch(warningNotification("Failed to add role mapping"))
        })
    }
    else {

      pAxios.delete(`/api/rolemapping?pid=${pid}&roleid=${roleid}`)
        .then((res) => {
          loadRoles();
        })
        .catch(err => {
          // setRoleMapping([]);
          dispatch(warningNotification("Failed to add role mapping"))
        })
    }

    // dispatch({type:CHANGEROLEMAPPING, pid, field: roles[roleid-1], value: checked});

  }

  
  const theme = useSelector((state) => state.ThemeOptions);

  return (
    <React.Fragment>

      <Card className={`mt-1 d-none d-lg-block`}>
        <Card.Header className={`${theme.headerBackgroundColor} ` }>
            <span className='float-start p-1'><i className='fa fa-table '></i></span>
            <h6 className="float-start p-1 flex-fill">
              Role Mapping
            </h6>
            


            <div className="float-md-end">
              <Form.Control id="searchInput" type="text" placeholder={`Search ${roleMapping?.length} records`}
                value={globalFilter || ""}
                onChange={(e) => {
                 setGlobalFilter(e.target.value);

                }} />
            </div>
          </Card.Header>


        <Card.Body className="p-1">


          <Container fluid className="p-0 ">{

            roleMapping ?
              <Table className="table-fixed table-hover">
                <thead className={`${theme.headerBackgroundColor}`}>
                  <tr>
                    <th>Permission</th>
                    <th>MASTER</th>
                    <th>DIRECTOR</th>
                    <th>FINANCE</th>
                    <th>ACCOUNTS</th>
                    <th className="w-20">PROJECTMANAGER</th>
                    <th>SITEENG</th>
                    <th>SUPERVISOR</th>

                  </tr>
                </thead>
                <tbody>
                  {roleMapping.filter((row) => {
                    if (globalFilter) {
                      return row.permission.toLowerCase().includes(globalFilter.toLowerCase())
                    }
                    return true;
                  
                  }).map((row, index) => {
                    return (
                      <tr key={index}>
                        <td >{row.permission}</td>
                        <td> <input type="checkbox" checked={row['MASTER'] === 1}
                          key={`${row['permission']}-MASTER`}
                          name={`${row['permission']}-MASTER`}
                          onChange={(e) => { changeRole(row['permissionid'], 1, e.target.checked) }} /></td>

                        <td> <input type="checkbox" checked={row['DIRECTOR'] === 1}
                          key={`${row['permission']}-DIRECTOR`}
                          name={`${row['permission']}-DIRECTOR`}
                          onChange={(e) => { changeRole(row['permissionid'], 2, e.target.checked) }} /></td>

                        <td> <input type="checkbox" checked={row['FINANCE'] === 1}
                          key={`${row['permission']}-FINANCE`}
                          name={`${row['permission']}-FINANCE`}
                          onChange={(e) => { changeRole(row['permissionid'], 3, e.target.checked) }} /></td>

                        <td> <input type="checkbox" checked={row['ACCOUNTS'] === 1}
                          key={`${row['permission']}-ACCOUNTS`}
                          name={`${row['permission']}-ACCOUNTS`}
                          onChange={(e) => { changeRole(row['permissionid'], 4, e.target.checked) }} /></td>

                        <td className="w-20"> <input type="checkbox" checked={row['PROJECTMANAGER'] === 1}
                          key={`${row['permission']}-PROJECTMANAGER`}
                          name={`${row['permission']}-PROJECTMANAGER`}
                          onChange={(e) => { changeRole(row['permissionid'], 5, e.target.checked) }} /></td>

                        <td> <input type="checkbox" checked={row['SITEENGINEER'] === 1}
                          key={`${row['permission']}-SITEENGINEER`}
                          name={`${row['permission']}-SITEENGINEER`}
                          onChange={(e) => { changeRole(row['permissionid'], 6, e.target.checked) }} /></td>

                        <td> <input type="checkbox" checked={row['SUPERVISOR'] === 1}
                          key={`${row['permission']}-SUPERVISOR`}
                          name={`${row['permission']}-SUPERVISOR`}
                          onChange={(e) => { changeRole(row['permissionid'], 7, e.target.checked) }} /></td>



                      </tr>

                    )
                  })
                  }
                </tbody>

              </Table> : null
          }
          </Container>
        </Card.Body>
      </Card>
      <Alert className="d-block d-lg-none text-center m-5  " variant="warning">Device Resolution Not Supported </Alert>


    </React.Fragment >);








}