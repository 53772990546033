import React, {Fragment} from 'react';

import { Slider } from "react-burgers";

import {
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../constant/constant';

import { useDispatch, useSelector } from "react-redux";

export default function AppMobileMenu(){

    const theme = useSelector((state) => state.ThemeOptions);
    const dispatch = useDispatch();
    const [activeMobile, setActiveMobile] = React.useState(false);

    const toggleMobileSidebar = () => {
        console.log(!theme.enableMobileMenu);
        dispatch(setEnableMobileMenu(!theme.enableMobileMenu));
    }


    const toggleMobileSmall = () => {
    
        dispatch(setEnableMobileMenuSmall(!theme.enableMobileMenuSmall));
    }

    
        return (
            <Fragment>

                <div className="app-header__mobile-menu">
                    <div onClick={toggleMobileSidebar}>
                        <Slider
                            active={theme.enableMobileMenu}
                            type="elastic"
                             onClick={() => setActiveMobile(!activeMobile)}
                        />
                    </div>
                </div>
                {/* <div className="app-header__menu">
                    <span onClick={this.toggleMobileSmall}>
                        <Button size="sm"
                                className={cx("btn-icon btn-icon-only", {active: this.state.activeSecondaryMenuMobile})}
                                color="primary"
                                onClick={() => this.setState({activeSecondaryMenuMobile: !this.state.activeSecondaryMenuMobile})}>
                            <div className="btn-icon-wrapper"><FontAwesomeIcon icon={faEllipsisV}/></div>
                        </Button>
                    </span>
                </div> */}
            </Fragment>
        )
    
}

