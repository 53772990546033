import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Form, Card, Row, Col, FloatingLabel } from "react-bootstrap";
import MasterToolBar from "./MasterToolBar";
import Table from "./Table";
import { changeActivity, loadActivity, loadActivityLookup, loadAllActivity, resetActivity, saveActivityService } from "../action/activityAction";
import { notification } from "../action/notificationAction";

// import { loadAllUser,  getUserPassword } from "../action/userAction";
import {
  setEnableMobileMenu
} from '../constant/constant';

export default function Activity({ permitted }) {


  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [selectedActivityId, setSelectedActivityId] = useState();


  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState('active');

  const lookup = useSelector(
    (state) => state.lookup.category
  );


  const activities = useSelector(
    (state) => state.activity.activities
  );


  const activity = useSelector(
    (state) => state.activity.activity
  );

  const theme = useSelector((state) => state.ThemeOptions);



  // const materials = [{
  //   category: "AAAA",
  //   name: "AAA",
  //   spec: "123"
  // }]



  const handleShow = (seleted) => {

    if (seleted && seleted.original.id) {

      setShow(true);
      setSelectedActivityId(seleted.original.id);
      dispatch(loadActivity(seleted.original.id));
    }
    else {
      setSelectedActivityId({});
      setShow(true);
    }

  }

  const handleClose = () => {
    setSelectedActivityId(null);
    dispatch(resetActivity());
    dispatch(loadAllActivity(status));
    setShow(false);
  }

  React.useEffect(()=>{
    dispatch(setEnableMobileMenu(false));
  },[dispatch]);

  React.useEffect(() => {
    dispatch(loadAllActivity(status));
  }, [dispatch, status]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },

      {
        Header: 'Category',
        accessor: 'categoryname',

      },
      {
        Header: 'Name',
        accessor: 'name',
        textFilter: true
      },
      {
        Header: 'Spec',
        accessor: 'spec',
      }, {
        Header: 'Closed',
        accessor: 'closedDate',
        show: status === 'active'
      }
    ],
    [status]
  )


  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let dataToPost ={};
      let method="POST";

      if (!activity.id) {
        dataToPost={
          ...activity,
          status: "Y"
        };
      }
      else {
        dataToPost={
          ...activity
        };
        method="PATCH";
      }
      saveActivityService(dataToPost,method).then(res=>{
        dispatch(notification(res));
        handleClose();
        dispatch(resetActivity());
        dispatch(loadActivityLookup());
      })
      .catch(err=>{
        dispatch(notification(err));
      })
    }

  }
  const findFormErrors = () => {

    const { name, categoryid } = activity
    const newErrors = {}

    if (!name || name === '') newErrors.name = 'Name cannot be blank!'
    if (!categoryid || categoryid === "0") newErrors.category = 'Select a activity category!'

    return newErrors
  }



  const setField = (field, value, model) => {
    dispatch(changeActivity({ name: field, value, model }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }


  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`ACTIVITY_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`ACTIVITY_UPDATE`));


  return (
    <React.Fragment>
      <MasterToolBar createPermission updatePermission id={activity.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose}></MasterToolBar>
      {
        !show ? (

          <Container fluid >
            <Table columns={columns} data={activities} title="Activities" edit={handleShow} hideColumn={status === 'active' ? ['closedDate'] : []}> </Table>
          </Container>) : (
          <Container fluid >

            <Card >
              <Card.Header className={`${theme.headerBackgroundColor}`}>Activity Detial </Card.Header>
              <Card.Body>
                <Form>
                  <Row className="mb-3">
                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingCategory" label="Category">
                              <Form.Select id="floatingCategory" aria-label="category" isInvalid={!!errors.category} value={activity.categoryid ?? "0"} onChange={e => setField('categoryid', e.target.value)}>
                                <option value="0">Select Category</option>
                                {
                                  lookup && lookup.ACTIVITY ? (
                                    lookup.ACTIVITY.map((e, i) => {
                                      return <option key={`cat_${i}`} value={e.id} >{e.name}</option>
                                    })
                                  ) : null
                                }

                              </Form.Select>
                              <Form.Control.Feedback type='invalid'>{errors.category} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingName" label="Name">
                              <Form.Control  type="text" placeholder=" Name" isInvalid={!!errors.name} value={activity.name ?? ""} onChange={e => setField('name', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingSpec" label="Spec">
                              <Form.Control  type="text" placeholder="Spec" isInvalid={!!errors.spec} value={activity.spec ?? ""} onChange={e => setField('spec', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.spec} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>



                      </Card.Body>
                    </Card>

                  </Row>
                  {/* <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  ">

                
              </div> */}
                </Form>
              </Card.Body>
            </Card>


          </Container>)
      } </React.Fragment>);








}