import { LOADSUMMARYREPORT,RESETSUMMARYREPORT,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
// import store from "../app/store";


export function loadSummaryReportService(projectid, fromDate, toDate, clientCategory){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/report/summary?json=true${projectid?`&project=${projectid}`:''}${fromDate? `&fromDate=${fromDate}`:''}${toDate?`&toDate=${toDate}`:''}${clientCategory&&clientCategory!=='0'?`&clientCategory=${clientCategory}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADSUMMARYREPORT, summary: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load report data",variant:"danger",err}  })
            });
    });

}



export function loadInsightService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/dashboard/insight`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                reject({ message:"Failed to load insight data",variant:"danger",err}  )
            });
    });

}


export function loadHighlightService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/dashboard/highlights`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to load highlights data",variant:"danger",err}  )
            });
    });

}


export function loadHighlightChartService(category, status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/dashboard/highlightschart?load=true${category && category!=="0"?`&category=${category}`:''}${status ?`&status=${status}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to load highlights chart data",variant:"danger",err}  )
            });
    });

}

export function loadProfitPercentageService(fromDate, toDate, costcenter){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/dashboard/profitpercentage?load=true${fromDate?`&fromDate=${fromDate}`:''}${toDate?`&toDate=${toDate}`:''}${costcenter && costcenter!=="0"?`&costCenter=${costcenter}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
           if(response.data){                       
               resolve(response.data);
           }
        }).catch(err => {                
                reject({ message:"Failed to load profit percentage data",variant:"danger",err}  )
        });
    });

}



 export const loadSummaryReport=(projectid,fromDate, toDate, clientCategory)=>{

    return dispatch => {

        loadSummaryReportService(projectid, fromDate, toDate, clientCategory)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}



export const resetSummary=()=>{

    return dispatch => {
      dispatch({type:RESETSUMMARYREPORT,});
  }
    
  }

  export function loadPendingService({all,costcenter}){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/request?all=${all}${costcenter && costcenter!=="0"?"&costCenter="+costcenter:""}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to load pending request data",variant:"danger",err}  )
            });
    });

}

export function rejectPendingService(id){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/request/reject?id=${id}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to reject request",variant:"danger",err}  )
            });
    });

}

export function deletePendingService(id){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/request?id=${id}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to delete request",variant:"danger",err}  )
            });
    });

}

export function approvePendingService(id){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/request/approve?id=${id}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve(response.data);
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to reject request",variant:"danger",err}  )
            });
    });

}

export function savePendingService(data){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/request`,
            "headers": {
                'content-type': 'application/json'
            },
            data: JSON.stringify(data)
          
        }).then(response => {

           if(response.data){
                       
               resolve({message:response.data,variant:"success"});
           }
        })
            .catch(err => {
                
                reject({ message:"Failed to raise request",variant:"danger",err}  )
            });
    });

}
  