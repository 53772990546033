import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker'
import { Container, Button, Row, Form, Card, Col, FloatingLabel, Modal } from "react-bootstrap";

import RTable from "./Table"
import { approvePaymentService, authorizePaymentService, changePayment, deletepaymentService, loadAllPayment, loadPayment, loadPaymentService, resetPayment, resetPayments, reversepaymentService, savePaymentService, verifyPaymentService } from "../action/paymentAction";

import {
  format,
  subDays

} from "date-fns";
import { Confirm } from "./Confirm";
import { notification, warningNotification } from "../action/notificationAction";
import { numberCheck } from "../constant/numberCheck";
import { loadVendor, loadVendorPaymentService, resetVendor } from "../action/vendorAction";
import { Typeahead } from "react-bootstrap-typeahead";
import { savePendingService } from "../action/reportAction";
import {
  setEnableMobileMenu
} from '../constant/constant';


import { pAxios } from "..";
import { formatAccounting, formatCurrency } from "../constant/format";
import { filterClient, filterProjects, filterProjectsSearch } from "../constant/filterTypeahead";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

export default function Payment({ minDate, mode, permitted, dseleted, dback }) {


  const dispatch = useDispatch();

  const proTypeRef = React.useRef(null);
  const cltEntryTypeRef = React.useRef(null);
  const userEntryTypeRef = React.useRef(null);
  const labourEntryTypeRef = React.useRef(null);
  const proEntryTypeRef = React.useRef(null);
  const venEntryTypeRef = React.useRef(null);


  const lookup = useSelector(
    (state) => state.lookup.category
  );



  const payments = useSelector(
    (state) => state.payment.payments
  );


  const payment = useSelector(
    (state) => state.payment.payment
  );

  const [startDate, setStartDate] = useState(minDate ? new Date(minDate) : subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedPo, setSelectedPo] = useState({});
  const [execludeAuto, setExecludeAuto] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null, message: null, method: null });
  const [status, setStatus] = useState("active");
  const [vendorPayment, setVendorPayment] = useState(null);

  const [createPermission, setCreatePermission] = React.useState(false);
  const [updatePermission, setUpdatePermission] = React.useState(false);
  const [deletePermission, setDeletePermission] = React.useState(false);
  const [reversePermission, setReversePermission] = React.useState(false);
  const [authPermission, setAuthPermission] = React.useState(false);
  const [verifyPermission, setVerifyPermission] = React.useState(false);
  const [approvePermission, setApprovePermission] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);
  const [bank, setBank] = React.useState({});

  const clients = useSelector(
    (state) => state.lookup.client
  );

  const users = useSelector(
    (state) => state.lookup.user
  );

  const projects = useSelector(
    (state) => state.lookup.project
  );

  const vendors = useSelector(
    (state) => state.lookup.vendor
  );

  const labours = useSelector(
    (state) => state.lookup.labour
  );



  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );


  const handleClose = () => {

    dispatch(resetPayment());
    dispatch(resetVendor());

    if (!dseleted) {
      setShow(false);
      setErrors({});
      
    }
    else {
      dback();
    }
    setVendorPayment(null);

  }


  const handleShow = (selected) => {
    setShow(true);

    if (selected?.original.id) {
      setSelectedPo(selected.original.id);

      loadPaymentService(selected.original.id).then(r => {
        dispatch(r);

        if (r?.payment?.vendorid) {
           loadVendorPayment(r?.payment?.vendorid,r?.payment?.projectid, r?.payment?.id)
        }
      }).catch(err => {
        dispatch(notification(err))
      })

      // dispatch(loadPayment(selected.original.id));


    }
    else {
      setVendorPayment(null);
      setSelectedPo(null);
      dispatch(resetPayment());
      setField('date', format(new Date(), 'yyyy-MM-dd'))
    }
  }


  const loadVendorPayment = (vendorId, projectId, paymentId)=>{
   
    if(vendorId && projectId){
      loadVendorPaymentService(vendorId, projectId, paymentId)
      .then(res=>{
         if(res?.length>0){
           setVendorPayment(res[0]);
         }
      })
      .catch(err=>{
       setVendorPayment(null);
       dispatch(notification(err));
      });
    }
    else{
      setVendorPayment(null);
    }
   
  }

  const setField = (field, value, idx, model) => {
    dispatch(changePayment({ name: field, value, idx, model }));

    if (field === 'tax' || field === 'basic') {
      calculateGross(field, value)
    }
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const onChange = (dates) => {

    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }

  };


  React.useEffect(() => {
    return () => {
      dispatch(resetPayment())
    }
  }, []);



  React.useEffect(() => {

    dispatch(resetPayment());
    dispatch(resetPayments());
    dispatch(resetVendor());

    setCreatePermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_CREATE`));
    setUpdatePermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_UPDATE`));

    setDeletePermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_DELETE`));
    setReversePermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_REVERSE`));
    setAuthPermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_AUTH`));
    setVerifyPermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_VERIFY`));
    setApprovePermission(permitted(`PAYMENT-${mode === "Requested" ? "REQ" : "APPROVAL"}_APPROVE`));



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mode]);

  React.useEffect(() => {
    if (dseleted) {
      handleShow({ original: { id: dseleted } });
    }
    else {
      handleClose();
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status, costcenter]);

  React.useEffect(() => {
    setSelectedProjectId(null);
    proTypeRef?.current?.clear();
    proEntryTypeRef?.current?.clear();

    if (payment) {
      setField("projectid", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costcenter])

  React.useEffect(() => {
    if (!dseleted) {
      setStatus('active')
      setErrors({});
      setSelectedPo({});
      setShow(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const load = (sdate, edate) => {
    if ((startDate && endDate) || selectedProjectId) {
      dispatch(loadAllPayment({
        status,
        mode,
        startDate: sdate ? format(sdate, "yyyy-MM-dd") : startDate ? format(startDate, "yyyy-MM-dd") : null,
        endDate: edate ? format(edate, "yyyy-MM-dd") : endDate ? format(endDate, "yyyy-MM-dd") : null,
        projectid: selectedProjectId,
        execludeAuto,
        costcenter: costcenter === "0" ? null : costcenter

      }));
    }

  }


  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let dataToPost = {};
      let method = "POST";

      if (!payment.id) {
        dataToPost = {
          ...payment,
          type: "PAID",
          occurance: payment.occurance && payment.occurance !== "0" ? payment.occurance : "SINGLE",
          vendorid: payment.vendorid !== null ? payment.vendorid : null,
          labourid: payment.labourid && payment.labourid !== "0" ? payment.labourid : null,
          userid: payment.userid && payment.userid !== "0" ? payment.userid : null,
          projectid: payment.projectid && payment.projectid !== "0" ? payment.projectid : null,
          clientid: payment.clientid && payment.clientid !== "0" ? payment.clientid : null,
          screen_name: `Payment-${mode}`,

          //   client: isObjectEmpty(project.client)  ? null : project.client          
        };
      }
      else {
        dataToPost = {

          ...payment,

          occurance: payment.occurance && payment.occurance !== "0" ? payment.occurance : "SINGLE",
          vendorid: payment.vendorid !== null ? payment.vendorid : null,
          labourid: payment.labourid && payment.labourid !== "0" ? payment.labourid : null,
          userid: payment.userid && payment.userid !== "0" ? payment.userid : null,
          projectid: payment.projectid && payment.projectid !== "0" ? payment.projectid : null,
          clientid: payment.clientid && payment.clientid !== "0" ? payment.clientid : null,
          screen_name: `Payment-${mode}`,
          //   client: isObjectEmpty(project.client) ? null : project.client
        };
        method = "PATCH";
      }

      savePaymentService(dataToPost, method).then(res => {

        dispatch(resetPayment());
        dispatch(notification(res));
        handleClose();

        if(!payment.id){
          load();
        }
      })
        .catch(err => {
          dispatch(notification(err))
        })


    }

  }


  const approve = e => {

    approvePaymentService(payment.id, `Payment-${mode}`).then(res => {

      // dispatch(resetPayment());
      dispatch(notification(res));
      if (!dback) {
        dispatch(loadPayment(payment.id));
      }
      else {
        dback()
      }
      // handleClose();
    })
      .catch(err => {
        dispatch(notification(err));
        if (dback) {
          dback();
        }
      })

    //setErrors({})

  }



  const findFormErrors = () => {

    const { paymenton, date, occurance, basic, gross, mode, vendorid, labourid, projectid, vendor_name } = payment
    const newErrors = {}


    if (!date || date === "") newErrors.date = 'Date cannot be blank';

    if (!basic || basic === "") newErrors.basic = 'Basic cannot be blank';

    if (!gross || gross === "") newErrors.gross = 'Gross cannot be blank';

    if (!paymenton || paymenton === '0') newErrors.type = 'Select a payment receiver';

    if (paymenton !== 'LABOUR' && paymenton !== 'CLIENT' && paymenton !== 'EXPENSE' && paymenton !== 'TAX' && (!projectid || projectid === "0")) newErrors.project = 'Select a project!';

    if (paymenton !== 'LABOUR' && paymenton !== 'CLIENT' && paymenton !== 'EXPENSE' && paymenton !== 'TAX' && (!occurance || occurance === "0")) newErrors.occurance = "Select a occurance";

    if (!mode || mode === "0") newErrors.mode = 'Select a mode!';

    if ((paymenton === 'SUPPLIER' || paymenton === 'CONTRACTOR') && (!vendorid || vendorid === "0")) newErrors.name = 'Select a vendor!';

    if ((paymenton === 'MISCELLANEOUS') && !vendor_name) newErrors.vendor_name = 'Vendor Name cannot be blank!';

    if (paymenton === 'LABOUR' && (!labourid || labourid === "0")) newErrors.name = 'Select a labour!';

    return newErrors
  }

  const calculateGross = (field, value) => {
    if (field === 'tax') {
      setField("gross", Number(payment.basic ?? 0) + Number(value));
    }
    else {
      setField("gross", Number(payment.tax ?? 0) + Number(value));
    }
  }

  const showBalance = (vendorid) => {


    if (vendorPayment) {
      return (<React.Fragment>
        <Col xs={6}>
          <FloatingLabel controlId="floatingAlreadyPaid" label="Already Paid">
            <Form.Control readOnly type="text" placeholder="Already Paid" value={formatAccounting(vendorPayment.paid ?? "0")} />
          </FloatingLabel>
        </Col>
        <Col xs={6}>
          <FloatingLabel controlId="floatingBalpayable" label="Balance Payable">
            <Form.Control readOnly type="text" placeholder="Balance Payable" value={formatAccounting(Number(vendorPayment.inv_total ?? "0") - Number(vendorPayment.paid ?? "0"))} />
            <Form.Text className="text-muted">
              #Consider negative balance as Advance
            </Form.Text>
          </FloatingLabel>
        </Col>
        <Col xs={6}>
          <FloatingLabel controlId="floatingInvoiceTotal" label="Invoice Total">
            <Form.Control readOnly type="text" placeholder="Invoice Total" value={formatAccounting(vendorPayment.inv_total ?? "0")} />
          </FloatingLabel>
        </Col>
        <Col xs={6}>
          <FloatingLabel controlId="floatingPoTotal" label="Po Total">
            <Form.Control readOnly type="text" placeholder="Po Total" value={formatAccounting(vendorPayment.po ?? "0")} />
          </FloatingLabel>
        </Col>
      </React.Fragment>)
    }


  }


  const authPayment = (id) => {

    if (!payment.paiddate) {
      setErrors({ paiddate: "Paid should not be blank" });
    }
    else {

      authorizePaymentService(id, payment.paiddate, `Payment-${mode}`).then(res => {
        dispatch(notification(res));
        if (dback) {
          dback();
        }
        else {

          dispatch(loadPayment(id));
        }

      }).catch(err => {
        dispatch(notification(err));
        if (dback) {
          dback();
        }
      })
    }
  }

  const verifiy = e => {

    verifyPaymentService(payment.id, `Payment-${mode}`).then(res => {

      // dispatch(resetPayment());
      dispatch(notification(res));
      if (!dback) {
        dispatch(loadPayment(payment.id));
      }
      else {
        dback()
      }
      // handleClose();
    })
      .catch(err => {
        dispatch(notification(err));
        if (dback) {
          dback();
        }
      })

    //setErrors({})

  }


  const deletePayRequestOrder = (confirm, selected, remarks) => {

    if (confirm) {
      if (deletePermission) {

        deletepaymentService(selected.id, `Payment-${mode}`)
          .then(res => {
            dispatch(notification(res));
            load(startDate, endDate);
          })
          .catch(err => {
            dispatch(notification(err));
          })
      }
      else {
        savePendingService({
          table: 'Payment',
          pk_id: selected.id,
          type: 'Payment Delete',
          amount: selected.gross,
          sub: 'name',
          project: selected.projectid,
          name: selected.name,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          remarks,
          screen_name: `Payment-${mode}`

        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })
      }

    }

    setShowConfirm({ show: false, callBackData: null, message: null, showRemarks: false });
  }

  const reversePayment = (confirm, selected, remarks) => {

    if (confirm) {
      if (reversePermission) {

        reversepaymentService(selected.id, `Payment-${mode}`)
          .then(res => {
            dispatch(notification(res));
            load(startDate, endDate);
          })
          .catch(err => {
            dispatch(notification(err));
          })
      }
      else {

        savePendingService({
          table: 'Payment',
          pk_id: selected.id,
          type: 'Payment Reversal',
          amount: selected.gross,
          sub: 'name',
          project: selected.projectid,
          name: selected.name,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          remarks,
          screen_name: `Payment-${mode}`
        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })

      }

    }

    setShowConfirm({ show: false, callBackData: null, message: null, showRemarks: false });
  }

  const handleModalClose = () => {
    setShowModal(false);
    setBank({})
  }

  const handleModalShow = () => {


    pAxios.get(`/api/payment/bdetails?entity=${payment.paymenton}&id=
    ${payment.clientid ? payment.clientid :
        payment.labourid ? payment.labourid :
          payment.paymenton === 'EXPENSE' ? payment.userid :
            payment.paymenton === 'MISCELLANEOUS' ? payment.id :
              payment.vendorid}`)
      .then((res) => {
        if (res?.data.length > 0) {
          setBank(res.data[0])
          setShowModal(true);
        }
        else {
          setBank([]);
        }
      })
      .catch(err => {
        setBank([]);
        dispatch(warningNotification("Failed to load bank details"))
      })

  }


  const columns = React.useMemo(
    () => [

      {
        Header: 'id',
        accessor: "id"
      },
      {
        Header: 'Project',
        accessor: 'projectname',
      },
      {
        Header: "To / From",
        accessor: "paymenton"
      }, {
        Header: "Name",
        accessor: 'dname',
        textFilter: true
      },
      {
        Header: 'Mode',
        accessor: 'mode_name',
        className: 'w-100p'
      }, {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Date',
        accessor: (cell) => cell['date'] ? format(new Date(cell['date']), "dd-MM-yyyy") : "",
        className: 'w-100p'
      }, {
        Header: 'Gross',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Allocated',
        accessor: (cell) => formatCurrency(cell['allocated']),
        className: 'text-end',
        hideFilter: true

      }, {
        Header: "Requested By",
        accessor: "requested_by",
      },
      {
        Header: 'Status',
        accessor: (cell) => {
          return cell.auth_status === 'Y' ? 'Processed' : cell.approved_status === 'Y' ? 'Approved' : cell.verified_status === 'Y' ? 'Verified' : 'Pending'
        },
        id: 'status'
      },
      {
        Header: "Auth",
        accessor: (cell) => cell['auth_date'] ? format(new Date(cell['auth_date']), "dd-MM-yyyy") : "",
        id: "authdate",
        show: status === 'closed',
        className: 'w-100p'

      }, {
        Header: "Action",
        id: 'delete',
        hideFilter: true,
        accessor: (cell) => {
          return (
            <div className="text-center">
              {<Button size="sm" variant="outline-danger" className="pt-0 pb-0 me-2" title="Delete Payment Request" onClick={() => { setShowConfirm({ show: true, callBackData: cell, message: deletePermission ? "Are you sure want to delete payment?" : "You are not allowed to delete, however this will be created as a requested to elevated users!", method: deletePayRequestOrder, showRemarks: !deletePermission }); }}> <i className="fa fa-trash" role="button"></i></Button>}

            </div>)
        }
      }, {
        Header: "Action",
        id: 'reverse',
        hideFilter: true,
        accessor: (cell) => {

          if (cell.reversalstatus === 'Y') {
            return "Reversed"
          }
          else {
            return (<div className="text-center">
              {<Button size="sm" variant="outline-warning" className="pt-0 pb-0" title="Reverse Payment" onClick={() => { setShowConfirm({ show: true, callBackData: cell, message: reversePermission ? "Are you sure want to reverse payment?" : "You are not allowed to reverse, however this will be created as a requested to elevated users!", method: reversePayment, showRemarks: !reversePermission }); }}> <i className="fa fa-reply" role="button"></i></Button>}
            </div>);
          }
        }
      }],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, deletePermission, reversePermission, mode]
  )

  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])



  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>

      <Container fluid className={`${show ? 'd-none' : ''}`}>

        <Card>
          <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
            <h6 className="mb-0">Payment {mode} </h6>


            {mode === "Requested" && createPermission ?
              <Button variant="success" className="ms-auto" size="sm" onClick={() => handleShow()} title="New Request">
                <i className="fa fa-plus d-md-none d-sm-inline"></i>
                <p className="d-none d-md-inline">Add New</p></Button>
              : null}

          </Card.Header>
          <Card.Body>
            <Row >
              <Col sm={12} md={3}>
                <div style={{ position: "relative" }} className="dp">
                  <DateRangePicker maxDate={new Date()} id="payDateRangeFilter"
                    format="dd-MM-yyyy"
                    onChange={onChange}
                    value = {[startDate, endDate]}
                    calendarIcon={<i className="fa fa-calendar"></i>}
                    // clearIcon = {null}
                    className="form-control"
                    isClearable={true}
                  ></DateRangePicker>
                  <label className="black-text" htmlFor="payDateRangeFilter">Payment Date</label>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <Typeahead
                  id="floating-label-pro-filter"
                  onChange={(e) => {
                    setSelectedProjectId(e[0]?.id)
                  }}
                  clearButton
                  labelKey="name"
                  onBlur={() => {
                    if (!selectedProjectId) {
                      proTypeRef.current?.clear();
                    }
                  }}
                  options={filterProjectsSearch(projects, costcenter)}

                  ref={proTypeRef}
                  placeholder="Choose A Project"
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                        <Form.Control
                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />
                      </FloatingLabel>

                    );
                  }}
                  selected={selectedProjectId ? projects.filter(e => e.id === selectedProjectId) : []}
                />
              </Col>
              <Col sm={12} md={3}>
                <div className="form-check form-switch pt-3">
                  <input className="form-check-input" type="checkbox" id="flexSwitchGeneralExpense"
                    checked={execludeAuto}
                    onChange={(e) => {
                      setExecludeAuto(e.target.checked)

                    }} />
                  <label className="form-check-label" htmlFor="flexSwitchGeneralExpense"><b>Exclude Auto  </b></label>
                </div>
              </Col>
              <Col >
                {
                  !show ? (<div className="d-grid flex-fill justify-content-md-end pt-3">

                    <Button variant="primary" size="sm" onClick={() => load()}>Search</Button>
                  </div>) : ""}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <RTable columns={columns} data={payments} title="Requested Payment" edit={handleShow} status={status} changeStatus={setStatus}
          hideColumn={status === 'active' ? ['authdate', 'reverse', 'authdate', 'allocated'] : ['delete', 'auth']}
        > </RTable>

      </Container>
      {
        !show ? null : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="mb-0">{mode === "Requested" ? `${!payment.id ? "New" : "Edit"} Payment Request` : "Payment Detail"}</h6>
                <div className="ms-auto">
                  {
                   ((payment.id && updatePermission && payment.auth_status === 'N') || (!payment.id && createPermission)) ?
                      <Button className="me-2" variant="success" size="sm" title={!payment.id ? "Save" : "Update"} onClick={handleSubmit}>

                        <i className="fa fa-save d-md-none d-sm-inline"></i>
                        <p className="d-none d-md-inline"> {!payment.id ? "Save" : "Update"}</p> </Button> : null
                     
                  }

                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p></Button>
                </div></Card.Header>
              <Card.Body>
                <Row className="g-2">
                  <Col sm={12} md={6}>
                    <Row className="g-2">

                      <Col sm={12}  >
                        <FloatingLabel controlId="floatingType" label={`Payment ${payment.paymenton === "CLIENT" ? "From" : "To"}`}>
                          <Form.Select
                            disabled={mode !== "Requested"}
                            id="floatingType" isInvalid={!!errors.type} value={payment.paymenton ?? "0"}
                            onChange={e => {
                              setField("paymenton", e.target.value);
                              setField("vendorid", null);
                              setField("labourid", null);
                              setField("projectid", null);
                              setField("userid", null);

                              if (e.target.value === "MISCELLANEOUS") {
                                setField("vendorid", 0);
                              }
                            }}>
                            <option >Select payment to</option>
                            <option value="SUPPLIER">Supplier</option>
                            <option value="CONTRACTOR">Contractor</option>
                            <option value="EXPENSE">Wallet</option>
                            <option value="MISCELLANEOUS">Miscellaneous</option>
                            <option value="TAX">GST</option>


                            {
                              mode === "Requested" ? null :
                                <React.Fragment>
                                  <option value="LABOUR">Labour</option>
                                  <option value="CLIENT">Client</option>
                                </React.Fragment>
                            }

                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.type} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      {
                        payment.paymenton && payment.paymenton === "LABOUR" ? (
                          <Col sm={12} >

                            <Typeahead
                              id="floating-labour-lbl"
                              onChange={(e) => {
                                setField('labourid', e[0]?.id)
                                setField('name', e[0]?.name);
                              }}
                              onBlur={() => {
                                if (!payment.labourid) {
                                  labourEntryTypeRef.current?.clear();

                                }
                              }}
                              clearButton
                              labelKey="name"
                              options={labours}
                              ref={labourEntryTypeRef}
                              placeholder="Labour"
                              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                return (

                                  <FloatingLabel controlId="floatingLabour" label="Labour">
                                    <Form.Control
                                      isInvalid={!!errors.name}
                                      {...inputProps}
                                      ref={(node) => {
                                        inputRef(node);
                                        referenceElementRef(node);
                                      }}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                  </FloatingLabel>

                                );
                              }}
                              selected={payment.labourid ? labours.filter(e => e.id === Number(payment.labourid)) : []}
                            />

                          </Col>) :
                          (payment.paymenton && payment.paymenton === "CLIENT" ?

                            (
                              <Col sm={12} >
                                <Typeahead
                                  clearButton
                                  id="floating-label-clt"
                                  onChange={(e) => {
                                    setField('clientid', e[0]?.id);
                                    setField('name', e[0]?.name);
                                    setField('projectid', null);
                                  }}
                                  onBlur={() => {
                                    if (!payment.clientid) {
                                      cltEntryTypeRef.current?.clear();
                                      proEntryTypeRef.current?.clear();
                                      setField('projectid', null);
                                      setField('name', null);
                                    }
                                  }}
                                  labelKey="name"
                                  options={filterClient(clients, costcenter)}
                                  ref={cltEntryTypeRef}
                                  placeholder="Client"
                                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                    return (

                                      <FloatingLabel controlId="floatingClient" label="Client">
                                        <Form.Control
                                          isInvalid={!!errors.name}
                                          {...inputProps}
                                          ref={(node) => {
                                            inputRef(node);
                                            referenceElementRef(node);
                                          }}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                      </FloatingLabel>

                                    );
                                  }}
                                  selected={payment.clientid ? clients.filter(e => e.id === Number(payment.clientid)) : []}
                                />

                              </Col>
                            ) :
                            (payment.paymenton && payment.paymenton === "EXPENSE" ? (
                              <Col sm={12} >

                                <Typeahead
                                  id="floating-user-lbl"
                                  onChange={(e) => {
                                    setField('userid', e[0]?.id)
                                    setField('name', e[0]?.name);
                                  }}
                                  clearButton
                                  onBlur={() => {
                                    if (!payment.userid) {
                                      userEntryTypeRef.current?.clear();

                                    }
                                  }}
                                  labelKey="name"
                                  options={users ?? []}
                                  ref={userEntryTypeRef}
                                  placeholder="User"
                                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                    return (

                                      <FloatingLabel controlId="floatingUser" label="User">
                                        <Form.Control
                                          isInvalid={!!errors.name}
                                          {...inputProps}
                                          ref={(node) => {
                                            inputRef(node);
                                            referenceElementRef(node);
                                          }}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                      </FloatingLabel>

                                    );
                                  }}
                                  selected={payment.userid ? users.filter(e => e.id === Number(payment.userid)) : []}
                                />

                              </Col>
                            ) : (payment.paymenton && payment.paymenton === "TAX" ?  null

                              : <React.Fragment>
                              <Col sm={12} >
                                <Typeahead clearButton
                                  id="floating-label-pro"
                                  onChange={(e) => {
                                    setField('projectid', e[0]?.id)

                                    if (e[0] && e[0].id && payment.vendorid){
                                      loadVendorPayment(payment.vendorid, e[0].id, payment.id);
                                    }
                                  }}
                                  labelKey="name"
                                  onBlur={() => {
                                    if (!payment.projectid) {
                                      proEntryTypeRef.current?.clear();
                                    }
                                  }}
                                  options={payment.clientid ? filterProjects(projects, costcenter).filter(e => Number(e.clientid) === Number(payment.clientid)) : filterProjects(projects, costcenter)}

                                  ref={proEntryTypeRef}

                                  placeholder="Project"
                                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                    return (

                                      <FloatingLabel controlId="floatingProject" label="Project">
                                        <Form.Control isInvalid={!!errors.project}
                                          {...inputProps}
                                          ref={(node) => {
                                            inputRef(node);
                                            referenceElementRef(node);
                                          }}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                                      </FloatingLabel>

                                    );
                                  }}
                                  selected={payment.projectid ? projects.filter(e => e.id === Number(payment.projectid)) : []}
                                />

                              </Col>
                              <Col sm={12} >
                                {
                                  payment.paymenton && payment.paymenton === "MISCELLANEOUS"
                                    ? (
                                      <FloatingLabel controlId="floatingVendorName" label="Vendor Name" >
                                        <Form.Control type="text" placeholder="Vendor Name" isInvalid={!!errors.vendor_name} value={payment.vendor_name ?? ""} onChange={e => setField("vendor_name", e.target.value)} />
                                        <Form.Control.Feedback type='invalid'>{errors.vendor_name} </Form.Control.Feedback>
                                      </FloatingLabel>


                                    ) :
                                    (
                                      <Typeahead clearButton
                                        id="floating-label-ven"
                                        onChange={(e) => {
                                          setField('vendorid', e[0]?.id);
                                          setField('name', e[0]?.name);
                                          if (e[0] && e[0].id){
                                            loadVendorPayment(e[0].id,payment.projectid,payment.id)
                                          }
                                            
                                        }}
                                        onBlur={() => {
                                          if (!payment.vendorid) {
                                            venEntryTypeRef.current?.clear();
                                            setField('vendorid', null);
                                            setField('name', null);
                                          }
                                        }}
                                        labelKey="name"
                                        options={vendors && payment.paymenton && payment.paymenton !== "0" ?
                                          vendors.filter(e => e.category === payment.paymenton) : []}
                                        ref={venEntryTypeRef}
                                        placeholder="Vendor"
                                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                          return (

                                            <FloatingLabel controlId="floatingVendorName" label="Vendor">
                                              <Form.Control isInvalid={!!errors.name}
                                                {...inputProps}
                                                ref={(node) => {
                                                  inputRef(node);
                                                  referenceElementRef(node);
                                                }}
                                              />
                                              <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                            </FloatingLabel>

                                          );
                                        }}
                                        selected={payment.vendorid ? vendors.filter(e => e.id === Number(payment.vendorid)) : []}
                                      />)

                                }
                              </Col>

                            </React.Fragment>  )
                            )

                          )
                      }


                      <Col sm={12} >

                        <div style={{ position: "relative" }} className="dp">
                          <DatePicker maxDate={new Date()}
                            minDate={minDate ? new Date(minDate) : null}
                            id="paymentDate"
                            value={payment.date ? new Date(payment.date) : ""}

                            format="dd-MM-yyyy"
                            calendarIcon={<i className="fa fa-calendar"></i>}
                            clearIcon = {null}
                            className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                            //isInvalid={true}
                        
                            onChange={(date) => setField("date", format(date, "yyyy-MM-dd"))}
                          ></DatePicker>
                          <label className="black-text" htmlFor="paymentDate">Payment Date</label>
                        </div>

                      </Col>
                      <Col xs={6} >
                        <FloatingLabel controlId="floatingPayMode" label="Payment Mode">
                          <Form.Select id="floatingPayMode" aria-label="Payment Mode" isInvalid={!!errors.mode} value={payment.mode ?? "0"}
                            onChange={e => setField("mode", e.target.value)}>
                            <option value="0">Select Mode</option>
                            {
                              lookup && lookup.PAYMENT_MODE ? lookup.PAYMENT_MODE.map((e, i) => {
                                return <option value={e.id} key={`mode_${i}`} data-name={e.name} >{e.name}</option>
                              }) : null
                            }
                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.mode} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      {
                        payment.paymenton && (payment.paymenton === "LABOUR" || payment.paymenton === "CLIENT" || payment.paymenton === "EXPENSE") ? null :
                          (<Col xs={6}> <FloatingLabel controlId="floatingPayType" label="Payment occurance">
                            <Form.Select id="floatingPayType" aria-label="Payment occurance" isInvalid={!!errors.occurance} value={payment.occurance ?? "0"} onChange={e => setField("occurance", e.target.value)}>
                              <option value="0">Select a payment occurance</option>
                              <option value="SINGLE">Single</option>
                              <option value="RA">RA</option>
                              <option value="FINAL">Final</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors.occurance} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>)
                      }


                      {
                        payment.vendorid ? showBalance(payment.vendorid) : ""
                      }


                    </Row>
                  </Col>

                  <Col sm={12} md={6}>
                    <Row className="g-2">
                      <Col sm={12} >
                        <FloatingLabel controlId="floatingBasic" label="Amount" >
                          <Form.Control type="text" onKeyPress={numberCheck} placeholder="Amount" isInvalid={!!errors.basic} value={payment.basic ?? "0"} onChange={e => setField("basic", e.target.value)} />
                          <Form.Control.Feedback type='invalid'>{errors.basic} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col sm={12} >
                        <FloatingLabel controlId="remarks" label="Remarks" >
                          <Form.Control as="textarea" placeholder="Remarks" value={payment.remarks ?? ""} onChange={e => setField("remarks", e.target.value)} />
                        </FloatingLabel>
                      </Col>
                      {/* {
                        payment.paymenton && (payment.paymenton === "LABOUR" || payment.paymenton === "CLIENT" || payment.paymenton === "EXPENSE") ? null :
                          <React.Fragment>
                            <Row className=" bg-secondary pb-3 g-2">

                              <Col sm={12} className="pt-2">
                                <Form.Check id="gstCheck" label="GST" checked={payment.isgst && payment.isgst === "Y"} onChange={(e) => { setField("isgst", e.target.checked ? "Y" : "N") }} />
                              </Col>

                              {
                                payment.isgst && payment.isgst === "Y" ? (
                                  <React.Fragment>
                                    <Col xs={6} >
                                      <FloatingLabel controlId="floatingInvocieNo" label="Invoice No">
                                        <Form.Control type="text" placeholder="Invoice Number" isInvalid={!!errors.invocieno} value={payment.invoicenumber ?? ""} onChange={e => setField("invoicenumber", e.target.value)} />
                                        <Form.Control.Feedback type='invalid'>{errors.invoiceno} </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>

                                    <Col xs={6} >

                                      <div style={{ position: "relative" }} className="dp">
                                        <DatePicker maxDate={new Date()} id="gstdate"
                                          placeholderText="Select Invoice Date"
                                          dateFormat="dd-MM-yyyy"
                                          selected={payment.invoicedate ? new Date(payment.invoicedate) : ""}
                                          onChange={(date) => setField("invoicedate", format(date, "yyyy-MM-dd"))}

                                          className={`form-control ${!!errors.invoicedate ? "is-invalid" : ""}`}
                                        ></DatePicker>
                                        <label className="black-text" htmlFor="gstdate">Invoice Date</label>
                                      </div>


                                    </Col>

                                    <Col sm={12} >
                                      <FloatingLabel controlId="floatingTax" label="Tax">
                                        <Form.Control onKeyPress={numberCheck} type="text" placeholder="Tax" isInvalid={!!errors.tax} value={payment.tax} onChange={e => setField("tax", e.target.value)} />
                                        <Form.Control.Feedback type='invalid'>{errors.tax} </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                  </React.Fragment>) : ""

                              }

                            </Row>
                            <Col sm={12} >
                              <FloatingLabel controlId="floatingGross" label="Gross" >
                                <Form.Control onKeyPress={numberCheck} type="text" readOnly placeholder="Gross" isInvalid={!!errors.Gross} value={payment.gross ?? "0"} onChange={e => setField("gross", e.target.value)} />
                                <Form.Control.Feedback type='invalid'>{errors.gross} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </React.Fragment>
                      } */}
                      {

                      }

                      {
                        mode === "Requested" ? "" : (
                          <React.Fragment>
                            {mode === "Requested" ? "" : (<Col sm={12} >
                              <FloatingLabel controlId="floatingRequest" label="Requested By">
                                <Form.Control type="text" placeholder="Requested By" readOnly value={payment.requested_by ?? ""} />

                              </FloatingLabel>
                            </Col>)}
                            {/* <Col sm={12} >
                              <FloatingLabel controlId="floatingPaidAmount" label="Paid Amount" >
                                <Form.Control type="text" placeholder="Paid Amount" isInvalid={!!errors.paid} />
                                <Form.Control.Feedback type='invalid'>{errors.paid} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col> */}

                            <Col sm={12} >
                              <div style={{ position: "relative" }} className="dp">
                                <DatePicker maxDate={new Date()}
                                  minDate={minDate ? new Date(minDate) : null}
                                  id="paidDate"
                                  value={payment.paiddate ? new Date(payment.paiddate) : ""}
                                  onChange={(date) => setField("paiddate", format(date, "yyyy-MM-dd"))}
                                  // startDate={startDate}                                 
                                  format="dd-MM-yyyy"
                                  calendarIcon={<i className="fa fa-calendar"></i>}
                                  clearIcon = {null}
                                  className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                                  //isInvalid={true}
                              
                                ></DatePicker>
                                <label className="black-text" htmlFor="paidDate" >{payment.type === 'RECEIVED' ? "Received" : "Paid"} Date</label>
                              </div>
                            </Col>

                            <Col sm={12} >
                              <FloatingLabel controlId="reference" label="Reference" >
                                <Form.Control type="text" placeholder="Reference" value={payment.reference ?? ""} onChange={e => setField("reference", e.target.value)} />
                              </FloatingLabel>
                            </Col>

                          </React.Fragment>)
                      }

                      {
                        (payment && payment.mode && [20, 22].includes(Number(payment.mode))) ?
                          <Col sm={12} >
                            <FloatingLabel controlId="floatingReciverName" label="Receiver Name">
                              <Form.Control type="text" placeholder="Receiver Name" readOnly={mode !== "Requested"} value={payment.receiver_name ?? ""}
                                onChange={(e) => { setField("receiver_name", e.target.value) }}
                              />

                            </FloatingLabel>
                          </Col> : null
                      }
                      {
                        (payment && payment.mode && [20, 22].includes(Number(payment.mode))) ?
                          <Col sm={12} >
                            <FloatingLabel controlId="floatingReciverNumber" label="Receiver Number">
                              <Form.Control type="text" placeholder="Receiver Number" readOnly={mode !== "Requested"} value={payment.receiver_number ?? ""}
                                onChange={(e) => { setField("receiver_number", e.target.value) }}
                              />

                            </FloatingLabel>
                          </Col> : null
                      }
                      {
                        (payment && payment.mode && (payment.paymenton !== 'MISCELLANEOUS' || mode !== 'Requested') && [23, 24].includes(Number(payment.mode))) ?
                          <Col sm={12}>
                            <Button variant="link" onClick={() => {
                              handleModalShow()
                            }}>
                              Bank Details
                            </Button>
                          </Col>
                          : null
                      }
                      {
                        (payment && (payment.paymenton === 'MISCELLANEOUS' && mode === 'Requested') && [23, 24].includes(Number(payment.mode)) ?
                          (
                            <React.Fragment>
                              <Col sm={12}>
                                <FloatingLabel controlId="floatingbank" label="Bank Name">
                                  <Form.Control type="text" placeholder="Bank" isInvalid={!!errors.bankname} value={payment.bank?.name} onChange={e => setField('name', e.target.value, null, 'bank')} />
                                  <Form.Control.Feedback type='invalid'>{errors.bankname} </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col sm={12}>
                                <FloatingLabel controlId="floatingBranch" label="Branch">
                                  <Form.Control type="text" placeholder="Branch" isInvalid={!!errors.branch} value={payment.bank?.branch} onChange={e => setField('branch', e.target.value, null, 'bank')} />
                                  <Form.Control.Feedback type='invalid'>{errors.branch} </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col sm={12}>
                                <FloatingLabel controlId="floatingIfsc" label="IFSC Code">
                                  <Form.Control type="text" placeholder="IFSC Code" isInvalid={!!errors.ifsc} value={payment.bank?.ifsc} onChange={e => setField('ifsc', e.target.value, null, 'bank')} />
                                  <Form.Control.Feedback type='invalid'>{errors.ifsc} </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col sm={12}>
                                <FloatingLabel controlId="floatingAcc" label="Account Number">
                                  <Form.Control type="text" placeholder="Account Number" isInvalid={!!errors.accountnumber} value={payment.bank?.number} onChange={e => setField('number', e.target.value, null, 'bank')} />
                                  <Form.Control.Feedback type='invalid'>{errors.accountnumber} </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </React.Fragment>
                          ) : null)
                      }


                      <Col sm={4} className="pt-2">
                        <Form.Check id="verifyCheck" label="Verification Status" disabled checked={payment.verified_status && payment.verified_status === "Y"} onChange={e => setField("verified_status", e.target.checked ? "Y" : "N")} />
                      </Col>
                     
                      <Col sm={4} className="pt-2">
                        <Form.Check id="approvedStatusCheck" label="Approved Status" disabled checked={payment.approved_status && payment.approved_status === "Y"} onChange={e => setField("approved_status", e.target.checked ? "Y" : "N")} />
                      </Col>

                      <Col sm={4} className="pt-2">
                        <Form.Check id="paidStatusCheck" label="Processed Status" disabled checked={payment.auth_status && payment.auth_status === "Y"} onChange={e => setField("auth_status", e.target.checked ? "Y" : "N")} />
                      </Col>




                    </Row>
                  </Col>

                  <div className="d-grid d-md-flex gap-2 justify-content-md-end mt-2 h-100 align-items-end">
                    {mode === "Requested" ?
                      null
                      : (
                        <React.Fragment>
                          {verifyPermission ? 
                            <React.Fragment>
                              <Button className="me-1" disabled={payment.verified_status && payment.verified_status === "Y"} variant="outline-success" size="sm" title="Verify" onClick={() => verifiy(payment.id)}> Verify </Button>
                              {payment.verified_status && payment.verified_status === "Y" ? null :
                                <Button className="me-1" variant="outline-danger" size="sm" title="Reject" onClick={() => deletePayRequestOrder(true, payment, '')}> Reject </Button>}
                            </React.Fragment>
                               : null}
                          {approvePermission && payment.verified_status && payment.verified_status === "Y" ? <Button className="me-1" disabled={payment.approved_status && payment.approved_status === "Y"} variant="outline-success" size="sm" title="Approve" onClick={approve}> Approve </Button> : null}
                          {authPermission && payment.approved_status && payment.approved_status === "Y" ? <Button className="me-1" disabled={payment.auth_status && payment.auth_status === "Y"} variant="outline-success" size="sm" title="Authorize" onClick={() => authPayment(payment.id)}> Process </Button> : null}
                        </React.Fragment>
                      )
                    }
                  </div>
                </Row>


              </Card.Body>
            </Card>
          </Container>)
      }
      <Confirm show={showConfirm} confirmation={showConfirm.message} confirmCallBack={showConfirm.method} showRemarks={showConfirm.showRemarks}></Confirm>

      <Modal show={showModal} onHide={() => { handleModalClose() }} size="sm">
        <Modal.Header closeButton >
          <h6 className={bank?.verified === 'Y' ? 'text-success' : 'text-danger'}>Bank Details - {bank?.verified === 'Y' ? 'Verified' : bank?.verified === 'N' ? 'Unverified' : ''}</h6>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Container fluid className="p-0">

            <Form.Group as={Row} className="mb-1 m-auto" controlId="formBankName">
              <Form.Label column sm="4">
                Bank Name
              </Form.Label>
              <Col sm="8">
                <Form.Label column>{bank?.name ?? ""}</Form.Label> 
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 m-auto border-bottom" controlId="formBranch">
              <Form.Label column sm="4">
                Branch
              </Form.Label>
              <Col sm="8">
                <Form.Label column>{bank?.branch ?? ""} </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 m-auto border-bottom" controlId="formIfsc">
              <Form.Label column sm="4">
                IFSC Code
              </Form.Label>
              <Col sm="8">
                <Form.Label column>{bank?.ifsc ?? ""} </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 m-auto border-bottom" controlId="formAccNumber">
              <Form.Label column sm="4">
                Account
              </Form.Label>
              <Col sm="8" column>
                <Form.Label column >{bank?.number ?? ""}</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 m-auto border-bottom" controlId="formGpayNumber">
              <Form.Label column sm="4">
                Gpay
              </Form.Label>
              <Col sm="8">
                <Form.Label column> {bank?.gpay_number ?? ""} </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 m-auto" controlId="formGpayName">
              <Form.Label column sm="4">
                Gpay Name
              </Form.Label>
              <Col sm="8">
                <Form.Label column>{bank?.gpay_name ?? ""} </Form.Label>
              </Col>
            </Form.Group>



          </Container>

        </Modal.Body>

      </Modal>

    </React.Fragment >);
}

// }
