import {
    LOADALLCLIENT,
    LOADCLIENT,
    RESETCLIENT,
    CHANGECLIENTFIELD, SET_NOTIFICATION, LOADCLIENTLOOKUPS, RESETCLIENTS
} from "../constant/constant";
import { pAxios } from "..";
// import store from "../app/store";


function loadAllClientService(status,unallocated) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/client?status=${status}${unallocated?"&unallocated="+unallocated:""}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLCLIENT, client: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load client data", variant: "danger", err } })
            });
    });

}

function loadClientService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/client/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADCLIENT, client: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load client data", variant: "danger", err } })
            });
    });

}

export function loadClientLookupUpService() {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/client?&attributes=id,name,gstin,categoryid,status&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADCLIENTLOOKUPS, client: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load client lookup", variant: "danger", err } })
            });
    });

}


export function saveClientService(data, method) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/client`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                    resolve({ message: `Client ${method === "POST" ? "created" : "updated"} successfull`, variant: "success", err: "" })
                // });
                // resolve({ type: RESETCLIENT });
            }
        })
            .catch(err => {

                reject({ message: "Failed to save client data", variant: "danger", err } )
            });
    });

}


export const loadAllClient = (status,unallocated) => {

    return dispatch => {

        loadAllClientService(status,unallocated)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadClient = (id) => {

    return dispatch => {

        loadClientService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}


export const loadClientLookup = () => {

    return dispatch => {

        loadClientLookupUpService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}





export const changeClient = ({ name, value, model }) => {

    return dispatch => {
        dispatch({ type: CHANGECLIENTFIELD, name, value, model });
    }


}

export const resetClient = () => {

    return dispatch => {
        dispatch({ type: RESETCLIENT, });
    }
}


export const resetClients = () => {

    return dispatch => {
        dispatch({ type: RESETCLIENTS, });
    }

}

// export const saveClient = (client) => {

//     return dispatch => {
//         saveClientService(client, "POST")
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }


// export const updateClient = (client) => {

//     return dispatch => {
//         saveClientService(client, "PATCH")
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }




