import React from "react";
import { useDispatch } from "react-redux";

import { Card, Button, Form, FloatingLabel, Row, Col } from "react-bootstrap";
import { login } from "../action/loginAction"
import { settoken, gettoken } from "../constant/key"
import logo from "../assets/brand.jpg"
import { pAxios } from "..";


export default function Login() {

  const [userId, setUserId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const dispatch = useDispatch();



  React.useEffect(() => {

    pAxios.get("/api/auth/key")
      .then(res => {
        if(res?.data){
          settoken(res.data.publicKey)
        }

      })
      .catch(err => {
        console.log(err)
      });
  }, [])


  const handleLoginSubmit = (e) => {

    e.preventDefault();

    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      dispatch(login(userId, password))
    }

  }

  const findFormErrors = () => {

    const newErrors = {}
    if (!userId || userId === "") newErrors.userId = 'User Id cannot be blank!'
    if (!password || password === "") newErrors.password = 'Password cannot be blank!'


    return newErrors
  }

  return (

    <div className="position-absolute top-50 start-50 translate-middle ">
      <Card className="border card-login" >
        <Card.Body>
          <img id="profile-img" className="profile-img-card" src={logo} alt="logo" style={{ width: "150px" }} />
          <Form autoComplete="off" onSubmit={handleLoginSubmit}>
            <input type="hidden" value="hidden" />

            <Row className="mb-3 ">
              <Col>
                <FloatingLabel label="User Name">
                  <Form.Control size="sm" type="text" placeholder="User Name" isInvalid={!!errors.userId}
                    onChange={(e) => {
                      setUserId(e.target.value);
                      setErrors({ ...errors, userId: null });
                    }}
                    // onKeyPress={numberCheck}
                    value={userId} />
                  <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>

                </FloatingLabel>
              </Col>
            </Row>

            <Row className="mb-3 ">
              <Col>
                <FloatingLabel label="Password">
                  <Form.Control size="sm" type="password" placeholder="Password" isInvalid={!!errors.password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors({ ...errors, password: null });
                    }}
                    value={password} />
                  <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>

                </FloatingLabel>
              </Col>
            </Row>

            <div className="d-grid gap-2 mb-3">
              <Button variant="primary" type="submit" size="sm"  disabled={!gettoken()}>
                Login <i className="fa fa-arrow-right"></i>
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card> </div>);
}
