import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Card, Col, FloatingLabel, Form } from "react-bootstrap";

import { loadSummaryReport, loadSummaryReportService, resetSummary } from "../action/reportAction";
import { notification, warningNotification } from "../action/notificationAction";
import { pAxios } from "..";
import fileDownload from 'js-file-download';

import { Typeahead } from "react-bootstrap-typeahead";
import {
  LOADSUMMARYREPORT,
  setEnableMobileMenu
} from '../constant/constant';
import { formatAccounting } from "../constant/format";
import { filterClient, filterProjects, filterProjectsSearch } from "../constant/filterTypeahead";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function Summary({ permitted }) {

  const dispatch = useDispatch();


  const cltTypeRef = React.useRef(null);
  const proTypeRef = React.useRef(null);


  const [selectedProject, setSelectedProject] = React.useState("0");
  const [selectedClient, setSelectedClient] = React.useState("0");

  const getCurrentFinancialYear = () => {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1)
    } else {
      fiscalyear = today.getFullYear()
    }
    return fiscalyear
  }


  const startYear = getCurrentFinancialYear() + 1;


  const [summaryYear, setSummaryYear] = React.useState(startYear);


  const summary = useSelector(
    (state) => state.report.summary
  );

  const clients = useSelector(
    (state) => state.lookup.client
  );


  const projects = useSelector(
    (state) => state.lookup.project
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );


  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    dispatch(resetSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costcenter]);


  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = () => {

    dispatch(resetSummary());

    let projectId = null;

    if (selectedProject && selectedProject !== "0") {
      projectId = selectedProject
    }
    else if ((!selectedProject || selectedProject === "0") && selectedClient !== "0") {
      projectId = projects.filter(e => Number(e.clientid) === Number(selectedClient)).map(e => e.id).join(",")
    }
   


    if ( (projectId && projectId !== '') || costcenter) {
      let fromDate, toDate;

      if (Number(summaryYear) !== 0) {
        fromDate = `${Number(summaryYear) - 1}-04-01`
        toDate = `${Number(summaryYear)}-03-31`
      }

      loadSummaryReportService(projectId, fromDate, toDate, costcenter).then(
        (response) => {

          const resData = response.summary;

          dispatch(response);

          setData({
            labels: ["Sale", "Purchase", "Expense", "Profit"],
            datasets: [
              {
                label: "# %",
                data: [resData.cpay ?? 0, resData.vpay ?? 0, (Number(resData.general ?? 0) + Number(resData.salary ?? 0)), resData.profit ?? 0],
                backgroundColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",

                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",

                ]
              }
            ]
          });
        }
      ).catch(err => {
        dispatch(notification(err))
      })


    }
    else {
      dispatch(warningNotification("Select client or project"))
    }

  }

  const handleDownload = (e) => {

    let projectId = null;

    if (selectedProject && selectedProject !== "0") {
      projectId = selectedProject
    }
    else if ((!selectedProject || selectedProject === "0") && selectedClient !== "0") {
      projectId = projects.filter(e => Number(e.clientid) === Number(selectedClient)).map(e => e.id).join(",")
    }

    if (projectId && projectId !== '') {

      const data = {
        project: projectId,
      }


      if (Number(summaryYear) !== 0) {
        data.fromDate = `${Number(summaryYear) - 1}-04-01`
        data.toDate = `${Number(summaryYear)}-03-31`
      }

      let url = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      }).join('&')

      pAxios({
        "method": "GET",
        "url": "/api/auth",

      }).then(r => {

        pAxios.get(`/api/report/summary?${url}`, {
          responseType: 'blob',
        })
          .then((res) => {
            fileDownload(res.data, `summary-report.xlsx`)
          })
      })

    }
    else {
      dispatch(warningNotification("Select client or project"))
    }

  }


  const theme = useSelector((state) => state.ThemeOptions);




  return (
    <React.Fragment>
      <Container fluid >

        <Card>

          <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
            <h6 className="text-start flex-fill">Summary  </h6>
            <div className="align-items-end ">
              <Button className="btn-alt" size="sm" onClick={handleDownload}>
                <i className="fa fa-download d-md-none d-sm-flex"></i>

                <p className="d-none d-md-inline" > Download </p>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <Row className="mb-3 g-2">

              <Col sm={12} md={3} >

                <FloatingLabel controlId="floatingType" label='Financial Year'>
                  <Form.Select
                    value={summaryYear}
                    onChange={e => { setSummaryYear(e.target.value) }}

                    id="floatingType">
                    <option value="0">All</option>
                    {
                      [...Array(10)].filter((e, i) => startYear - i > 2021).map((e, i) =>

                        <option key={`year_${i}`} value={startYear - i}>{startYear - (i + 1)} - {startYear - i}</option>

                      )
                    }

                  </Form.Select>

                </FloatingLabel>
              </Col>



              <Col sm={12} md={3} >


                <Typeahead
                  id="floating-label-clt-filter"
                  onChange={(e) => {
                    setSelectedClient(e[0]?.id)
                    setSelectedProject(null)
                  }}
                  clearButton
                  onBlur={() => {
                    if (!selectedClient) {
                      cltTypeRef.current?.clear();
                      proTypeRef.current?.clear();
                      setSelectedProject(null)
                    }
                  }}
                  labelKey="name"
                  options={filterClient(clients, costcenter)}
                  ref={cltTypeRef}
                  placeholder="Choose A Client"
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingClientFilter" label="Select A Client">
                        <Form.Control
                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />
                      </FloatingLabel>

                    );
                  }}
                  selected={selectedClient ? clients.filter(e => e.id === selectedClient) : []}
                />

              </Col>
              <Col sm={12} md={3} >
                <Typeahead
                  id="floating-label-pro-filter"
                  onChange={(e) => {
                    setSelectedProject(e[0]?.id)
                  }}
                  clearButton
                  labelKey="name"
                  onBlur={() => {
                    if (!selectedProject) {
                      proTypeRef.current?.clear();
                    }
                  }}
                  options={projects ?
                    filterProjectsSearch(projects, costcenter).filter(e => !selectedClient || Number(e.clientid) === Number(selectedClient)  )  : []}

                  ref={proTypeRef}
                  placeholder="Choose A Project"
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                        <Form.Control
                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />
                      </FloatingLabel>

                    );
                  }}
                  selected={selectedProject ? projects.filter(e => e.id === selectedProject) : []}
                />
              </Col>

              <Col >
                <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">

                  <Button variant="success" size="sm" onClick={() => search()}>Search</Button>
                </div>
              </Col>

            </Row>
            <hr />
            <Row className="mb-3 g-2 justify-content-center">
              <Col sm={12} md={6} lg={3} className="position-relative">


                <Card>
                  <Card.Body>
                    {/* <Card.Title className="text-center">
                      <Row className={`${summary?.profit > 0 ? 'text-success' : 'text-danger'}`}>

                        <Col className="text-end border-end">&#x20b9; {formatAccounting(summary ? summary.profit : 0)}</Col>
                        <Col className="text-start"> {formatAccounting(summary ? summary.profitpercentage : 0)}%</Col>
                      </Row>
                    </Card.Title> */}
                    {data ? <React.Fragment>
                      <Doughnut data={data} options={{
                        rotation: -90,
                        circumference: 180,
                        cutout: "60%",
                        maintainAspectRatio: true,
                        responsive: true,

                        layout: {
                          padding: {
                            bottom: -500
                          }
                        }

                      }} />

                      <div
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center"
                        }}
                      >
                        <div className={`${summary?.profit > 0 ? 'text-success' : 'text-danger'} border-bottom fw-bold`}>
                          &#x20b9; {formatAccounting(summary ? summary.profit : 0)}
                        </div>
                        <div className={`${summary?.profit > 0 ? 'text-success' : 'text-danger'} fw-bold`}>

                          {formatAccounting(summary ? summary.profitpercentage > 100 ? 100 : summary.profitpercentage < -100 ? -100 : summary.profitpercentage : 0)}%
                        </div>
                        <div>
                        </div>

                      </div>
                    </React.Fragment> : null}
                    <Card.Subtitle className="mb-2 text-muted text-center h7">Profit</Card.Subtitle>
                    <Row className="summary"><Col > Clash Flow</Col> <Col> {formatAccounting(summary ? summary.cash : 0)}%</Col> </Row>

                  </Card.Body>
                </Card>
              </Col>

              {/* <Col sm={12} md={3} >

                



              </Col> */}
              <Col sm={12} lg={3} >

                <Card>
                  <Card.Body>
                    <Card.Title   >
                      <Row className={`${summary?.coutstanding > 0 ? 'text-success' : 'text-danger'}`}>
                        <Col className="text-center">
                          <i className="fa fa-arrow-circle-down pe-4 text-success"></i>
                          &#x20b9;
                          {formatAccounting(Number(summary ? summary.coutstanding : 0))}</Col>
                      </Row>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center h7"> Client</Card.Subtitle>
                    <Row className="summary"><Col > Basic</Col> <Col> <i className="fa fa-rupee"></i> {formatAccounting(summary ? summary.cbasic : 0)}</Col> </Row>
                    <Row className="summary"><Col> Tax</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.ctax : 0)}</Col> </Row>
                    <Row className="summary"><Col> Inclusive</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.cinclusive : 0)}</Col> </Row>
                    <Row className="summary"><Col> Received</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.cpay : 0)}</Col> </Row>

                  </Card.Body>
                </Card>

              </Col>

              <Col sm={12} lg={3} >

                <Card>
                  <Card.Body>

                    <Card.Title >
                      <Row className={`${summary?.voutstanding > 0 ? 'text-success' : 'text-danger'}`}>
                        <Col className="text-center">
                          <i className="fa fa-arrow-circle-up pe-4 text-danger"></i>
                          &#x20b9;
                          {formatAccounting(summary ? summary.voutstanding : 0)}</Col>
                      </Row>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center h7">Vendor</Card.Subtitle>

                    <Row className="summary"><Col > Basic</Col> <Col> <i className="fa fa-rupee"></i> {formatAccounting(summary ? summary.vbasic : 0)}</Col> </Row>
                    <Row className="summary"><Col> Tax</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.vtax : 0)}</Col> </Row>
                    <Row className="summary"><Col> Inclusive</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.vinclusive : 0)}</Col> </Row>
                    <Row className="summary"><Col> Paid to Labour</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.lab : 0)}</Col> </Row>
                    <Row className="summary"><Col> Paid</Col> <Col ><i className="fa fa-rupee"> </i> {formatAccounting(summary ? summary.vpay : 0)}</Col> </Row>


                  </Card.Body>
                </Card>

              </Col>
              <Col sm={12} lg={3} >
                <Card >
                  <Card.Body>

                    <Card.Title className="h6">
                      <Row className={`text-danger m-1`}>
                        <Col className="text-center fw-bold">
                          <i className="fa fa-arrow-circle-up pe-4 text-danger"></i>
                          &#x20b9;
                          {formatAccounting(summary ? (Number(summary.general ?? 0) + Number(summary.salary ?? 0)) : 0)}</Col>
                      </Row>
                    </Card.Title>
                    <Card.Subtitle className="mb-1 text-muted text-center h7">Expense</Card.Subtitle>

                    <Row className="summary"><Col xs={4}> General</Col> <Col>  &#x20b9; {formatAccounting(summary ? summary.general : 0)}</Col> </Row>
                    <Row className="summary"><Col xs={4}> Salary</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.salary : 0)}</Col> </Row>



                  </Card.Body>
                </Card>
              </Col>
            </Row>

          </Card.Body>

        </Card>





      </Container>
      {/* <Confirm show={showConfirm} confirmation="Are you sure?" confirmCallBack={deletePayRequestOrder}></Confirm> */}
    </React.Fragment >);
}

// }
