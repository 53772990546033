import {
    LOADALLLABOUR,
    LOADLABOUR,
    RESETLABOUR,
    CHANGELABOURFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.labour }, action) {

    switch (action.type) {
        case LOADALLLABOUR:
            return {...state,labours:[...action.labour.rows]}
       
        case LOADLABOUR:
            return { ...state, labour:{...action.labour} };
        case CHANGELABOURFIELD:
            if(action.model)
                return { ...state,labour:{...state.labour,[action.model]:{...(state.labour[action.model] ?? {}),[action.name]:action.value}}};
            else
                return { ...state,labour:{...state.labour,[action.name]:action.value}};
        case RESETLABOUR:
            return {...state,labour:{...initialState.labour.labour}}
        default:
            return { ...state };
    }
}