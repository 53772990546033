
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { pAxios } from "..";
import { removeLocal } from "../constant/resetLocal"



function PrivateRoute({ component: Component, path, isAuth, ...rest }) {

  const [auth, setAuth] = React.useState(false);
  const [isTokenValidated, setIsTokenValidated] = React.useState(false);

  React.useEffect(() => {
    // send jwt to API to see if it's valid
    let token = localStorage.getItem("token");
    if (token) {
      pAxios({
        "method": "GET",
        "url": "/api/auth",

      }).then(response => {

        if (response.status && response.status === 200) {
          setAuth(true);
        }
        else {
          setAuth(false);
          removeLocal();
        }

        setIsTokenValidated(true);
      }).catch(err => {

        setAuth(false);
        setIsTokenValidated(true);
        removeLocal();
      });
    }
    else {

      setIsTokenValidated(true);
    }

    

  }, []);




  if (!isTokenValidated) {
    return ("");
  }
  else {

    return (
      localStorage.getItem("token") && auth ?
        <Route  {...rest} render={props => <Component {...props} />} />
        : <Redirect to="/login" />);

  }

}

export default PrivateRoute;