import {LOADALLUSER,LOADUSER,RESETUSER,CHANGEUSERFIELD,GETPASSWORD,SET_NOTIFICATION, LOADUSERLOOKUP, } from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";


function loadAllUserService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/users?status=${status}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLUSER, user: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load user data",variant:"danger",err}  })
            });
    });

}

function loadUserService(userid){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/users/${userid}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADUSER, user: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load user data",variant:"danger",err}  })
          });
  });

}


export function loadUserLookUpService(){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/users?attributes=id,name,status,client_category&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADUSERLOOKUP, user: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load user lookup",variant:"danger",err}  })
            });
    });
  
  }

function getPasswordUserService(userId){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/users/${userId}/password`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data && response.data.plain){
                       
               resolve({ type: GETPASSWORD, password: (response.data.plain),userId });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to get user password",variant:"danger",err}  })
            });
    });
  
  }

export function saveUserService(user,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": method,
          "url": `/api/users`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(user)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
            // type: SET_NOTIFICATION, notification:
               resolve({ message: `User ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" });
            // });
            // resolve({ type: RESETUSER});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save user data",variant:"danger",err} )
          });
  });

}


function updatePasswordService(password){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "PATCH",
            "url": `/api/password`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify({password})
          
        }).then(response => {
  
           if(response.data){
                 
            store.dispatch({
              type: SET_NOTIFICATION, notification:
                  { message: `Password reset successfully`, variant: "success", err: "" }
              });
               resolve({ type: RESETUSER});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to reset password",variant:"danger",err}  })
            });
    });
  
  }

  function resetPasswordService(userId){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "PATCH",
            "url": `/api/resetpassword?userId=${userId}`,
            "headers": {
                'content-type': 'application/json'
            },
            
          
        }).then(response => {
  
           if(response.data){
                 
            store.dispatch({
              type: SET_NOTIFICATION, notification:
                  { message: `Password reset successfully`, variant: "success", err: "" }
              });
              
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to reset password",variant:"danger",err}  })
            });
    });
  
  }

 export const loadAllUser=(status)=>{

    return dispatch => {

        loadAllUserService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadUser=(userid)=>{

  return dispatch => {

      loadUserService(userid)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));

  }

}

export const getUserPassword=(userid)=>{

    return dispatch => {
  
        getPasswordUserService(userid)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
  
    }
  
  }
  
export const updatePassword=(password)=>{

    return dispatch => {
  
        updatePasswordService(password)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
  
    }
  
  }

export const resetPassword=(userId)=>{

    return dispatch => {
  
        resetPasswordService(userId)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
  
    }
  
  }
  

  

export const changeUser=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEUSERFIELD,name,value,model});
}
  

}

export const resetUser=()=>{

  return dispatch => {
    dispatch({type:RESETUSER,});
}
  
}

// export const saveUser=(user)=>{

//   return dispatch => {
//     saveUserService(user,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateUser=(user)=>{

//     return dispatch => {
//       saveUserService(user,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }


  
export const loadUserLookup=()=>{

    return dispatch => {
  
        loadUserLookUpService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }

