import {
    LOADALLACTIVITY,
    LOADACTIVITY,
    RESETACTIVITY,
    CHANGEACTIVITYFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.activity }, action) {

    switch (action.type) {
        case LOADALLACTIVITY:
            return {...state,activities:[...action.activity.rows]}
       
        case LOADACTIVITY:
            return { ...state, activity:{...action.activity} };
        case CHANGEACTIVITYFIELD:
            if(action.model)
                return { ...state,activity:{...state.activity,[action.model]:{...(state.activity[action.model] ?? {}),[action.name]:action.value}}};
            else
                return { ...state,activity:{...state.activity,[action.name]:action.value}};
        case RESETACTIVITY:
            return {...state,activity:{...initialState.activity.activity}}
        default:
            return { ...state };
    }
}