import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Card, Col, Form, FloatingLabel } from "react-bootstrap";

import { pAxios } from "..";
import fileDownload from 'js-file-download'

import { warningNotification } from "../action/notificationAction";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  setEnableMobileMenu
} from '../constant/constant';


export default function Bill({ permitted }) {

 
  const proTypeRef = React.useRef(null);


  const dispatch = useDispatch();
  const [selectedName, setSelectedName] = useState(null);
  
  const [selectedProject, setSelectedProject] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState([]);



  const projects = useSelector(
    (state) => state.lookup.project
  );



  React.useEffect(() => {
    setTableData([]);
    setSelectedName(null);
    setSelectedProject(null);
    dispatch(setEnableMobileMenu(false));
  }, []);

  const handleDownload = () => {

    if (selectedProject && selectedProject !== "0") {

      const data = {
        
        projectid: selectedProject,
        selectedName: selectedName

      }

      let url = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      }).join('&')

      pAxios({
        "method": "GET",
        "url": "/api/auth",

      }).then(r => {

        pAxios.get(`/api/statement/bill/download?${url}`, {
          responseType: 'blob',
        })
          .then((res) => {
            fileDownload(res.data, `Bill registry of - ${selectedName} .XLSX`)
          })
      })

    }
    else {
      dispatch(warningNotification("Select a project"));
    }
  }

  const theme = useSelector((state) => state.ThemeOptions);



  return (
    <React.Fragment>
      <Container fluid >

        <Card>

          <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
            <h6 className="float-start flex-fill">Bill Register  </h6>
            {/* <div className="d-grid flex-fill d-md-flex justify-content-md-end h-100 align-items-end ">
              <Button variant="success" size="sm" onClick={() => { }}>
                <i className="fa fa-file-pdf d-md-none d-sm-inline"></i>

                <p className="d-none d-md-inline"> Download </p>
              </Button>
            </div> */}
          </Card.Header>

          <Card.Body>
            <Row className="mb-3 g-2">

             
              
                  <Col sm={12} md={3} >
                    <Typeahead
                      id="floating-label-pro-filter"
                      onChange={(e) => {
                        setSelectedProject(e[0]?.id)
                        setSelectedName(e[0]?.name);
                      }}
                      clearButton
                      labelKey="name"
                      onBlur={() => {
                        if (!selectedProject) {
                          proTypeRef.current?.clear();
                          setSelectedName(null);
                        }
                      }}
                      options={projects ? projects: []}

                      ref={proTypeRef}
                      placeholder="Choose A Project"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedProject ? projects.filter(e => e.id === selectedProject) : []}
                    />
                  </Col>
              



              <Col >
                <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">

                  {/* <Button variant="primary" size="sm" onClick={search}>Search</Button> */}
                  <Button variant="success" size="sm" onClick={handleDownload}>Download</Button>
                </div>
              </Col>

            </Row>

            <hr />




            {/* <Table columns={columnsInvoice} data={tableData} /> */}



          </Card.Body>

        </Card>





      </Container>
      {/* <Confirm show={showConfirm} confirmation="Are you sure?" confirmCallBack={deletePayRequestOrder}></Confirm> */}
    </React.Fragment >);
}

// }
