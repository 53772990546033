import {
    LOADALLVENDOR,
    LOADVENDOR,
    RESETVENDOR,
    CHANGEVENDORFIELD,
    RESETVENDORS,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.vendor }, action) {

    switch (action.type) {
        case LOADALLVENDOR:
            return { ...state, vendors: [...action.vendor.rows] }

        case LOADVENDOR:
            return { ...state, vendor: { ...action.vendor } };
        case CHANGEVENDORFIELD:
            if (action.model)
                return { ...state, vendor: { ...state.vendor, [action.model]: { ...(state.vendor[action.model] ?? {}), [action.name]: action.value } } };
            else
                return { ...state, vendor: { ...state.vendor, [action.name]: action.value } };
        case RESETVENDOR:
            return { ...state, vendor: { ...initialState.vendor.vendor } }
        case RESETVENDORS:
            return { ...state, vendors:[] }
        default:
            return { ...state };
    }
}