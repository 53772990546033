import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker';
import { Container, Form, Card, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import Table from "./Table";
import { loadLabour, loadMandayService, resetLabour } from "../action/labourAction";
import { changePayment, deletepaymentService, loadAllPayReq, loadPayment, resetPayment, savePaymentService } from "../action/paymentAction";
import { format, startOfMonth, subDays } from "date-fns";
import { notification, warningNotification } from "../action/notificationAction";
import { Confirm } from "./Confirm"
import { numberCheck } from "../constant/numberCheck";
import { Typeahead } from "react-bootstrap-typeahead";
import { savePendingService } from "../action/reportAction";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";
import { filterLabour, filterLabourSearch } from "../constant/filterTypeahead";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';



// import { loadAllUser,  getUserPassword } from "../action/userAction";

export default function MandayPayment({ minDate, permitted }) {

  const dispatch = useDispatch();
  const labourEntryTypeRef = React.useRef(null);
  const labourTypeRef = React.useRef(null);
  // const proEntryTypeRef = React.useRef(null);

  const [startDate, setStartDate] = useState(minDate ? new Date(minDate) : subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [seletedLabour, setSelectedLabour] = useState("0");
  // eslint-disable-next-line no-unused-vars
  const [seletedPayment, setSelectedPayment] = useState(null);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState('active');
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null });




  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`WAGE-REQ_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`WAGE-REQ_UPDATE`));
  // eslint-disable-next-line no-unused-vars
  const [deletePermission, setDeletePermission] = React.useState(permitted(`WAGE-REQ_DELETE`));



  const lookup = useSelector(
    (state) => state.lookup.category
  );

  const [labourPayments, setLabourPayments] = useState([]);

  const labours = useSelector(
    (state) => state.lookup.labour
  );

  const labour = useSelector(
    (state) => state.labour.labour
  );


  const projects = useSelector(
    (state) => state.lookup.project
  );

  const payment = useSelector(
    (state) => state.payment.payment
  );

  const payments = useSelector(
    (state) => state.payment.payments
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );



  const handleShow = (seleted) => {

    setLabourPayments([]);
    if (seleted && seleted.original.id) {

      setShow(true);
      setSelectedPayment(seleted.original.id);
      dispatch(loadPayment(seleted.original.id));
      loadLabourPendingDays(seleted.original.labourid);
      dispatch(loadLabour(seleted.original.labourid, "total,wage,paid,advance"))
    }
    else {
      dispatch(resetPayment());
      setSelectedPayment("0");
      setShow(true);
      setLabourPayments([]);
      setField("date", format(new Date(), "yyyy-MM-dd"));
    }

  }

  const handleClose = () => {
    setSelectedPayment(null);
    setErrors({})
    setShow(false);
    dispatch(resetPayment());
    dispatch(resetLabour());
    setLabourPayments([]);
    
  }


  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, costcenter]);


  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',

      },
      {
        Header: 'Name',
        accessor: 'labourname',
        id: 'name',
        textFilter: true
      }, {
        Header: 'Category',
        accessor: 'category'
      }, {
        Header: 'Wage',
        accessor: (cell) => formatCurrency(cell['wage']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Date',
        accessor: (cell) => cell['date'] ? format(new Date(cell['date']), "dd-MM-yyyy") : "",
      }, {
        Header: 'Requested Amount',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: "Requested By",
        accessor: "requestedby",
      }, {
        Header: 'Status',
        accessor: (cell) => {
          return cell.reversal_status === 'Y' ? "Reversed" : cell.approved_status === 'Y' ? 'Approved' : cell.auth_status === 'Y' ? 'Processed' : 'Pending'
        }
      }, {
        Header: "Auth/Rev Date",
        id: "auth",
        accessor: (cell) => cell["auth_date"] ? format(new Date(cell["auth_date"]), "dd-MM-yyyy") : "",
        show: status === 'closed'
      }, {
        Header: "Action",
        id: 'action',
        hideFilter: true,
        accessor: (cell) => {
          return (
            <div className="text-center">
              {<Button size="sm" variant="outline-danger" className="pt-0 pb-0" title="Delete Payment Request" onClick={() => { setShowConfirm({ show: true, callBackData: cell }); }}> <i className="fa fa-trash" role="button"></i></Button>}
            </div>)
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, deletePermission]
  );

  const columnDays = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',

      },
      {
        Header: 'Date',
        accessor: 'date',
        hideFilter: true
      },

      {
        Header: 'Project',
        accessor: 'projectname',
        hideFilter: true
      },
      {
        Header: 'Paid',
        accessor: (cell) => formatCurrency(cell['paid']),
        hideFilter: true,
        Footer: "Total"
      },
      {
        Header: 'Pending',
        accessor: (cell) => formatCurrency((Number(cell['base'] ?? 0)*Number(cell['factor']??1)) - Number(cell['paid'] ?? 0)),
        Footer: data => {
          // Only calculate total visits if rows change

          const total = React.useMemo(
            () =>
              data.rows.reduce((sum, row) => ( (Number(row.original['base'] ?? 0)*Number(row.original['factor']??1)) - Number(row.original['paid'] ?? 0)) + sum, 0),
            [data.rows]
          )

          return formatCurrency(total)
        },
        hideFilter: true
      }
    ],
    []
  )

  const onChange = (dates) => {

    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }

  };

  const load = () => {
    if (startDate && endDate) {
      dispatch(loadAllPayReq({ status, startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd"), type: "PAID", labourid: seletedLabour !== "0" ? seletedLabour : null, costcenter }));
    }

  }

  const loadLabourPendingDays = (selectedLabour) => {


    if (selectedLabour) {

      loadMandayService(selectedLabour, null, null, "P")
        .then(res => {
          setLabourPayments([...res.rows]);

        })
        .catch(err => {
          setLabourPayments([])
          dispatch(notification(err));
        })
    }
    else {
      // dispatch(warningNotification("Select labour to view"));
      setLabourPayments([])
    }

  }

  const setField = (field, value, idx) => {
    dispatch(changePayment({ name: field, value, idx }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
    else if (payment.auth_status && payment.auth_status === 'Y') {
      dispatch(warningNotification("Payment Already Processed, not allowed to update"))
    }
    else {

      let dataToPost = {};
      let method = "POST";

      if (!payment.id) {
        dataToPost = {
          ...payment,
          paymenton: "LABOUR",
          basic: payment.gross,
          occurance: 'SINGLE',
          type: "PAID",
          screen_name: "Manday",
          costcenter: labours.find(e => e.id === Number(payment.labourid))?.costcenter ?? null,
          //   client: isObjectEmpty(project.client)  ? null : project.client          
        };
      }
      else {
        dataToPost = {
          ...payment,
          basic: payment.gross,
          screen_name: "Manday",
          costcenter: labours.find(e => e.id === Number(payment.labourid))?.costcenter ?? null,
          //   client: isObjectEmpty(project.client) ? null : project.client
        };
        method = "PATCH"
      }

      savePaymentService(dataToPost, method).then(res => {

        dispatch(resetPayment());
        dispatch(notification(res));
        handleClose();

        if(!payment.id){
          load();
        }

      })
        .catch(err => {
          dispatch(notification(err))
        })

    }

  }

  const findFormErrors = () => {

    const { date, gross, mode, labourid } = payment
    const newErrors = {}


    if (!date || date === "") newErrors.date = 'Date cannot be null';
    if (!labourid || labourid === "") newErrors.labour = 'Select a labour';
    if (!gross || gross === "") newErrors.gross = 'Gross cannot be null';
    if (!mode || mode === "0") newErrors.mode = 'Select a mode!';

    return newErrors
  }


  const showBalance = () => {


    let lab = labour;

    return (<React.Fragment>

      <Col className="mb-3">
        <FloatingLabel controlId="floatingDailyWag" label="Daily Wage" >
          <Form.Control placeholder="Daily Wage" readOnly value={lab.wage ?? 0} />
        </FloatingLabel>
      </Col>
      <Col className="mb-3">
        <FloatingLabel controlId="floatingAmountToPaid" label="Balance/Advance" >
          <Form.Control placeholder="Balance/Advance" readOnly value={Number(lab.total ?? 0) - Number(lab.paid ?? 0)} />
          <Form.Text className="text-muted">
            #Consider negative balance as Advance
          </Form.Text>
        </FloatingLabel>
      </Col>

    </React.Fragment>)

  }

  const deletePayRequestOrder = (confirm, selected, remarks) => {


    if (confirm) {
      if (deletePermission) {

        deletepaymentService(selected.id, 'Manday').then(res => {
          dispatch(notification(res));
          load();
        }).catch(err => {
          dispatch(notification(err))
        })
      }
      else {

        savePendingService({
          table: 'Payment',
          pk_id: selected.id,
          type: 'Labour Payment Reversal',
          amount: selected.amount,
          name: selected.labourname,
          project: selected?.projectid,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          remarks

        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })
      }

    }
    setShowConfirm({ show: false, callBackData: null });
  }


  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])


  React.useEffect(() => {
    setSelectedLabour(null);
    labourEntryTypeRef?.current?.clear();
    labourTypeRef?.current?.clear();
    if (payment) {
      setField("labourid", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costcenter])

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>

      <Container fluid className={`${show ? 'd-none' : ''}`}>
        <Card>
          <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
            <h6 className="flex-fill text-start">Manday Payment</h6>
            {createPermission ?
              <div >
                <Button variant="success" size="sm" onClick={() => { handleShow() }} title="New Request">
                  <i className="fa fa-plus d-md-none d-sm-inline"></i>
                  <p className="d-none d-md-inline">Add New</p>
                </Button>
              </div>
              : null}

          </Card.Header>
          <Card.Body>
            <Row className="mb-3 g-2">

              <Col sm={12} md={4}>
                <div style={{ position: "relative" }} className="dp">
                  <DateRangePicker id="paymentDateRangeFilter" maxDate={new Date()}
                    
                    format="dd-MM-yyyy"
                    
                    onChange={onChange}
                    value = {[startDate, endDate]}
                    calendarIcon={<i className="fa fa-calendar"></i>}
                    // clearIcon = {null}
                    className="form-control"
                    isClearable={true}
                  ></DateRangePicker>
                  <label className="black-text" htmlFor="paymentDaterangeFilter">Payment Date</label>
                </div>
              </Col>
              <Col sm={12} md={4}>

                <Typeahead
                  id="floating-labour-lbl"
                  onChange={(e) => {
                    setSelectedLabour(e[0]?.id);

                  }}
                  clearButton
                  onBlur={() => {
                    if (!seletedLabour) {
                      labourTypeRef.current?.clear();

                    }
                  }}
                  labelKey="name"
                  options={filterLabourSearch(labours, costcenter)}
                  ref={labourTypeRef}
                  placeholder="Select a labour"
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingLabour" label="Select a labour">
                        <Form.Control

                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />

                      </FloatingLabel>

                    );
                  }}
                  selected={seletedLabour ? labours.filter(e => e.id === Number(seletedLabour)) : []}
                />


              </Col>
              <Col >
                <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end align-items-end h-100  ">

                  <Button variant="primary" size="sm" onClick={() => { handleClose() }}>Search</Button>
                </div>
              </Col>
            </Row>

          </Card.Body>
        </Card>
        <Table columns={columns} data={payments} title="Wage Request" edit={handleShow} status={status}
          changeStatus={setStatus} hideColumn={status === 'active' ? ['auth'] : ['action']} pagination={true}>

        </Table>
      </Container>
      {
        !show ? (

          null) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="flex-fill text-start">Labour Day's </h6>
                <div >
                  {((payment.id && updatePermission && payment.auth_status === 'N') || (!payment.id && createPermission)) ?
                    <Button className="me-1" variant="success" size="sm" onClick={handleSubmit} title="Save / Update">
                      <i className="fa fa-save d-md-none d-sm-inline"></i>
                      <p className="d-none d-md-inline">{!payment.id ? "Save" : "Update"}</p>
                    </Button> : null}

                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p>
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Row >

                  <Card className="col-sm-12 col-md-6 border-0 mb-0">
                    <Card.Body className="pt-0 pb-0 ps-0">
                      <Col className="mb-3">

                        <Typeahead
                          id="floating-labour-lbl"
                          onChange={(e) => {
                            setField('labourid', e[0]?.id);
                            setField('name', e[0]?.name);
                            loadLabourPendingDays(e[0]?.id);

                            if (e[0] && e[0].id) {
                              dispatch(loadLabour(e[0].id, "total,wage,paid,advance"));
                            }
                            else {
                              dispatch(resetLabour());

                            }
                          }}
                          clearButton
                          onBlur={() => {
                            if (!payment.labourid) {
                              labourEntryTypeRef.current?.clear();
                              dispatch(resetLabour());
                              setLabourPayments([])
                            }
                          }}
                          labelKey="name"
                          options={filterLabour(labours, costcenter)}
                          ref={labourEntryTypeRef}
                          placeholder="Select a labour"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingLabour" label="Select a labour">
                                <Form.Control
                                  isInvalid={!!errors.labour}
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.labour} </Form.Control.Feedback>
                              </FloatingLabel>

                            );
                          }}
                          selected={payment.labourid ? labours.filter(e => e.id === Number(payment.labourid)) : []}
                        />


                      </Col>

                      {/* <Col className="mb-3" >
                        <Typeahead clearButton
                          id="floating-label-pro"
                          onChange={(e) => {
                            setField('projectid', e[0]?.id)
                          }}
                          labelKey="name"
                          onBlur={() => {
                            if (!payment.projectid) {
                              proEntryTypeRef.current?.clear();
                            }
                          }}
                          options={projects && payment.clientid ? projects.filter(e => Number(e.clientid) === Number(payment.clientid)) : projects}

                          ref={proEntryTypeRef}

                          placeholder="Project"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingProject" label="Project">
                                <Form.Control isInvalid={!!errors.project}
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                              </FloatingLabel>

                            );
                          }}
                          selected={payment.projectid ? projects.filter(e => e.id === Number(payment.projectid)) : []}
                        />

                      </Col> */}


                      {
                        showBalance()
                      }
                    </Card.Body>
                  </Card>


                  <Card className="col-sm-12 col-md-6 border-0 mb-0">
                    <Card.Body className="pt-0 pb-0 pe-0">
                      <Col className="mb-3">
                        <FloatingLabel label="Amount To Be Paid" controlId="amountInp">

                          <Form.Control onKeyPress={numberCheck} placeholder="Amount to be paid" isInvalid={!!errors.gross} value={payment.gross ?? ""} onChange={e => setField("gross", e.target.value)} />
                          <Form.Control.Feedback type='invalid'>{errors.gross} </Form.Control.Feedback>
                        </FloatingLabel>

                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel label="Mode of Payment" controlId="payModeSelect">

                          <Form.Select id="payModeSelect" aria-label="Mode of payment" isInvalid={!!errors.mode} value={payment.mode ?? "0"} onChange={e => setField("mode", e.target.value)}>
                            <option value="0">Select Mode</option>

                            {
                              lookup && lookup.PAYMENT_MODE ? lookup.PAYMENT_MODE.map((e, i) => {
                                return <option value={e.id} key={`mode_${i}`}>{e.name}</option>
                              }) : null
                            }

                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.mode} </Form.Control.Feedback>
                        </FloatingLabel>

                      </Col>
                      <Col className="mb-3">

                        <div style={{ position: "relative" }} className="dp">
                          <DatePicker maxDate={new Date()}
                            minDate={minDate ? new Date(minDate) : null}
                            id="payDate"
                            value={payment.date ? new Date(payment.date) : ""}
                            format="dd-MM-yyyy"
                            calendarIcon={<i className="fa fa-calendar"></i>}
                            clearIcon = {null}
                            className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                            //isInvalid={true}
                        
                            onChange={(date) => setField("date", format(date, "yyyy-MM-dd"))}
                          ></DatePicker>
                          <label className="black-text" htmlFor="payDate">Payment Date</label>
                        </div>

                        {/* <FloatingLabel controlId="floatingPaymentDate" label="Payment Date" >

                          <Form.Control placeholder="Payment Date" />
                        </FloatingLabel> */}

                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingReference" label="Remarks">
                          <Form.Control as="textarea" placeholder="Remarks" value={payment.remarks ?? ""} onChange={e => setField("remarks", e.target.value)} />

                        </FloatingLabel>
                      </Col>


                      {
                        (payment && payment.mode && [20, 22].includes(Number(payment.mode))) ?
                          <Row className="mb-3">
                            <Col sm={12} md={6}>
                              <FloatingLabel controlId="floatingReciverName" label="Receiver Name">
                                <Form.Control type="text" placeholder="Receiver Name" value={payment.receiver_name ?? ""}
                                  onChange={(e) => { setField("receiver_name", e.target.value) }}
                                />

                              </FloatingLabel>
                            </Col>

                            <Col sm={12} md={6}>
                              <FloatingLabel controlId="floatingReciverName" label="Receiver Number">
                                <Form.Control type="text" placeholder="Receiver Number" value={payment.receiver_number ?? ""}
                                  onChange={(e) => { setField("receiver_number", e.target.value) }}
                                />

                              </FloatingLabel>
                            </Col> </Row> : null
                      }
                    </Card.Body>
                  </Card>
                </Row>
                <Table columns={columnDays} data={labourPayments} footer="true"></Table>
              </Card.Body>
            </Card>
          </Container>)
      }
      <Confirm show={showConfirm} confirmation={deletePermission ? "Are you sure?" : "You are not allowed to delete, however this will be created as a requested to elevated users!"} confirmCallBack={deletePayRequestOrder} showRemarks={!deletePermission}></Confirm>
    </React.Fragment>);

}