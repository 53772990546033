import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Form, Card, Col, FloatingLabel, Badge } from "react-bootstrap";

import MasterToolBar from "./MasterToolBar"
import Table from "./Table"
import { states } from '../constant/in_state'
import { loadAllClient, loadClient, resetClient, changeClient, loadClientLookup, saveClientService } from '../action/clientAction';
import { isObjectEmpty } from "../util/util";

import { notification } from "../action/notificationAction";
import { Typeahead } from 'react-bootstrap-typeahead'
import {
    setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";

export default function Client({ permitted }) {

    const [status, setStatus] = useState("active");
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        setErrors({})
    }, []);

    React.useEffect(() => {

        dispatch(setEnableMobileMenu(false));

    }, [dispatch]);

    const client = useSelector(
        (state) => state.client.client
    );


    const users = useSelector(
        (state) => state.lookup.user
    );


    const clients = useSelector(
        (state) => state.client.clients
    );


    const categories = useSelector(
        (state) => state.lookup.category
    );

    const handleClose = () => {
        setShow(false);
        setErrors({});
        setSelectedClientId(null);
        dispatch(resetClient());
        dispatch(loadAllClient(status));
    }

    const handleShow = (seletedUser) => {
        setErrors({});
        setShow(true);
        if (seletedUser && seletedUser.original.id) {
            setSelectedClientId(seletedUser.original.id);
            dispatch(loadClient(seletedUser.original.id));
        }
        else {
            setSelectedClientId(null);
        }
    }
    const handleSubmit = e => {

        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            let dataToPost = {};
            let method = "POST";

            if (!client.id) {
                dataToPost = {
                    ...client,
                    status: 'Y',
                    address: isObjectEmpty(client.address) ? null : client.address,
                    bank: isObjectEmpty(client.bank) ? null : client.bank,
                    users: client.users ? client.users.join(",") : ""
                };
            }
            else {
                method = "PATCH";
                dataToPost = {
                    ...client,
                    address: isObjectEmpty(client.address) ? null : client.address,
                    bank: isObjectEmpty(client.bank) ? null : client.bank,
                    users: client.users ? client.users.join(",") : ""
                };
            }

            saveClientService(dataToPost, method).then(res => {

                dispatch(resetClient());
                dispatch(notification(res));
                handleClose();
                dispatch(loadClientLookup());
            })
                .catch(err => {
                    dispatch(notification(err))
                })


        }


    }

    const findFormErrors = () => {

        const { name, email, categoryid } = client
        const newErrors = {}

        if (!name || name === '') newErrors.name = 'Name cannot be blank!'
        if (email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
            newErrors.mail = 'Invalid email address'
        }
        if (!categoryid || categoryid === "0") newErrors.categoryid = "Select a catergory!"

        return newErrors
    }



    const setField = (field, value, model) => {
        dispatch(changeClient({ name: field, value, model }));
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'id'
            },
            {
                Header: 'Name',
                accessor: 'name',
                id: 'name',
                textFilter:true
                
            },
            {
                Header: 'Gstin',
                accessor: 'gstin',
                hideFilter:true
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                textFilter: true
            },
            {
                Header: 'Address1',
                accessor: 'address.address1',
                className: 'text-truncate w-500',
                textFilter: true
            },
            {
                Header: 'Cost Center',
                accessor: (cell) =>  <Badge className="me-1">{cell['category']['name']}</Badge>,
                hideFilter: true
            },
            {
                Header: 'Balance',
                accessor: (cell)=> formatCurrency(cell['balance']),
                className: 'text-end',
                hideFilter: true

            }, {
                Header: 'Closed',
                accessor: 'closedDate',
                show: status === 'active'
            }
        ],
        [status]
    )


    React.useEffect(() => {
        dispatch(loadAllClient(status));
    }, [dispatch, status]);


    // eslint-disable-next-line no-unused-vars
    const [createPermission, setCreatePermission] = React.useState(permitted(`CLIENT_CREATE`));
    // eslint-disable-next-line no-unused-vars
    const [updatePermission, setUpdatePermission] = React.useState(permitted(`CLIENT_UPDATE`));

    const typeRef = React.useRef(null);
    const typeRefUser = React.useRef(null)
    const theme = useSelector((state) => state.ThemeOptions);

    return (
        <React.Fragment>
            <MasterToolBar createPermission updatePermission id={client.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose}></MasterToolBar>
            {
                !show ? (

                    <Container fluid >
                        <Table columns={columns} data={clients} title="Client" edit={handleShow} status={status} changeStatus={setStatus} hideColumn={status === 'active' ? ['closedDate'] : []}> </Table>
                    </Container>) : (
                    <Container fluid >
                        <Card>
                            <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>Client</Card.Header>
                            <Card.Body className="p-0">
                                <Form>
                                    <Row className="m-auto">

                                        <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                                            <Card.Body>
                                                <Card.Title><i className="fa fa-user-o "></i> Client Info</Card.Title>
                                                <hr></hr>

                                                <Row className="mb-3">

                                                    <Col> <FloatingLabel controlId="floatingName" label="Client Name">
                                                        <Form.Control type="Name" placeholder="User Name" isInvalid={!!errors.name} value={client.name ?? ""} onChange={e => setField('name', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingGstin" label="GSTIN" >
                                                        <Form.Control type="text" placeholder="GSTIN" isInvalid={!!errors.gstin} value={client.gstin ?? ""} onChange={e => setField('gstin', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.gstin} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">

                                                    <Col> <FloatingLabel controlId="floatingEmail" label="Email">
                                                        <Form.Control type="email" placeholder="Email" isInvalid={!!errors.mail} value={client.email ?? ""} onChange={e => setField('email', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.mail} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Col> <FloatingLabel controlId="floatingPhone" label="Phone Number">
                                                        <Form.Control type="text" placeholder="Phone" isInvalid={!!errors.phone} value={client.phone ?? ""} onChange={e => setField('phone', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">

                                                    <Col> <FloatingLabel controlId="floatingContact" label="Contact Person">
                                                        <Form.Control type="text" placeholder="contact_person" isInvalid={!!errors.contact_person} value={client.contact_person ?? ""} onChange={e => setField('contact_person', e.target.value)} />
                                                        <Form.Control.Feedback type='invalid'>{errors.contact_person} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col className="mb-3">
                                                        <FloatingLabel controlId="categorySelect" label="Cost Center" >

                                                            <Form.Select id="categorySelect" aria-label="Category" isInvalid={!!errors.categoryid} value={client.categoryid ?? "0"}
                                                                onChange={e => setField("categoryid", e.target.value)}
                                                            >
                                                                <option value="0">Select Cost Center</option>
                                                                {
                                                                    categories && categories.CLIENT ? categories.CLIENT.map((e, i) => {
                                                                        return <option key={`exp_${i}`} value={e.id}>{e.name}</option>
                                                                    }) : null
                                                                }

                                                            </Form.Select>
                                                            <Form.Control.Feedback type='invalid'>{errors.categoryid} </Form.Control.Feedback>
                                                        </FloatingLabel>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <label>Users Involved </label>
                                                        <div className="bg-secondary p-1">

                                                            {
                                                                users.filter(e => client.users.includes(e.id)).
                                                                    map(e => <Badge className="me-1"> {e.name} </Badge>)
                                                            }
                                                        </div>


                                                        {/* <Form.Text className="text-muted">
                                                            #User with elevated privileges will be added automatically
                                                        </Form.Text> */}
                                                    </Col>
                                                </Row>


                                            </Card.Body>
                                        </Card>

                                        <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                                            <Card.Body>
                                                <Card.Title> <i className="fa fa-address-book-o "></i> Billing Address</Card.Title>
                                                <hr></hr>

                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingAddr" label="Billing Address">
                                                        <Form.Control type="text" placeholder="Billing Address" isInvalid={!!errors.address1} value={client.address.address1 ?? ""} onChange={e => setField('address1', e.target.value, 'address')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.address1} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                {/* <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingAddr2" label="Billing Address Line 2">
                                                        <Form.Control type="text" placeholder="Billing Address Line 2 " value={client.address.address2 ?? ""} onChange={e => setField('address2', e.target.value, 'address')} />
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row> */}
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingCity" label="City">
                                                        <Form.Control type="text" placeholder="City" isInvalid={!!errors.city} value={client.address.city ?? ""} onChange={e => setField('city', e.target.value, 'address')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.city} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Col>
                                                        <Typeahead
                                                            id="floating-label-state"
                                                            onChange={(e) => {
                                                                setField('state', e[0], 'address')
                                                            }}
                                                            onBlur={() => {
                                                                if (!client.address.state) {
                                                                    typeRef.current?.clear();
                                                                }
                                                            }}
                                                            clearButton
                                                            options={states}
                                                            ref={typeRef}
                                                            placeholder="Choose a state..."
                                                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                                                return (

                                                                    <FloatingLabel controlId="floatingLabel" label="Select a state...">
                                                                        <Form.Control
                                                                            {...inputProps}
                                                                            ref={(node) => {
                                                                                inputRef(node);
                                                                                referenceElementRef(node);
                                                                            }}
                                                                        />
                                                                    </FloatingLabel>

                                                                );
                                                            }}
                                                            selected={client.address.state ? [client.address.state] : []}
                                                        />

                                                        {/* <FloatingLabel controlId="floatingState" label="State" >
                                                            <Form.Select id="floatingState" aria-label="State" isInvalid={!!errors.state} value={client.address.state ?? "0"} onChange={e => setField('state', e.target.value, 'address')}>
                                                                <option value="0">Select State</option>
                                                                {
                                                                    states.map((e, i) => {
                                                                        return <option key={`state_op_${i}`} value={e}>{e}</option>
                                                                    })
                                                                }

                                                            </Form.Select>
                                                            <Form.Control.Feedback type='invalid'>{errors.state} </Form.Control.Feedback>
                                                        </FloatingLabel> */}
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel controlId="floatingZip" label="Pincode">
                                                            <Form.Control type="text" placeholder="Pincode" isInvalid={!!errors.pincode} value={client.address.pincode ?? ""} onChange={e => setField('pincode', e.target.value, 'address')} />
                                                            <Form.Control.Feedback type='invalid'>{errors.pincode} </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>


                                            </Card.Body>
                                        </Card>

                                        <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                                            <Card.Body>
                                                <Card.Title className={client?.bank?.verified === 'Y' ? 'text-success' : 'text-danger'}> <i className="fa fa-university "></i> Bank Details - {client?.bank?.verified === 'Y' ? 'Verified' : client?.bank?.verified === 'N' ? 'Unverified' : ''}</Card.Title>
                                                <hr></hr>
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingbank" label="Bank Name">
                                                        <Form.Control type="text" placeholder="Bank" isInvalid={!!errors.bankname} value={client.bank.name ?? ""} onChange={e => setField('name', e.target.value, 'bank')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.bankname} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingBranch" label="Branch">
                                                        <Form.Control type="text" placeholder="Branch" isInvalid={!!errors.branch} value={client.bank.branch ?? ""} onChange={e => setField('branch', e.target.value, 'bank')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.branch} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingIfsc" label="IFSC Code"
                                                    >
                                                        <Form.Control type="text" placeholder="IFSC Code" isInvalid={!!errors.ifsc} value={client.bank.ifsc ?? ""} onChange={e => setField('ifsc', e.target.value, 'bank')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.ifsc} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingAcc" label="Account Number"
                                                    >
                                                        <Form.Control type="text" placeholder="Account Number" isInvalid={!!errors.accountnumber} value={client.bank.number ?? ""} onChange={e => setField('number', e.target.value, 'bank')} />
                                                        <Form.Control.Feedback type='invalid'>{errors.accountnumber} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <FloatingLabel controlId="floatingPan" label="PAN Number">
                                                            <Form.Control type="text" placeholder="PAN Number" isInvalid={!!errors.pan} value={client.pan ?? ""} onChange={e => setField('pan', e.target.value)} />
                                                            <Form.Control.Feedback type='invalid'>{errors.pan} </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                    <Row className="mb-3">
                                                        <Col>
                                                            <FloatingLabel controlId="floatingGpay" label="Gpay Number">
                                                                <Form.Control type="text" placeholder="Gpay Number" isInvalid={!!errors.gpay} value={client.bank.gpay_number ?? ""} onChange={e => setField('gpay_number', e.target.value,'bank')} />
                                                                <Form.Control.Feedback type='invalid'>{errors.gpay} </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <FloatingLabel controlId="floatingGpayName" label="Gpay Name">
                                                                <Form.Control type="text" placeholder="Gpay Name" isInvalid={!!errors.gpayName} value={client.bank.gpay_name ?? ""} onChange={e => setField('gpay_name', e.target.value,'bank')} />
                                                                <Form.Control.Feedback type='invalid'>{errors.gpayName} </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>

                                                <Row className="mb-3">
                                                    <Col> <FloatingLabel controlId="floatingBalance" label="Balance">
                                                        <Form.Control type="text" className="text-end" placeholder="Balance" value={formatCurrency(client.balance ?? "0")} readOnly />
                                                    </FloatingLabel>
                                                    </Col>
                                                    <Col> <FloatingLabel controlId="floatingPaid" label="Paid">
                                                        <Form.Control type="text" className="text-end" placeholder="Balance" value={formatCurrency(client.paid ?? "0")} readOnly />
                                                    </FloatingLabel>
                                                    </Col>
                                                </Row>


                                                <Row className="mb-3">
                                                    <Col>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" disabled={!client.id} type="checkbox" id="flexSwitchInvoiceType"
                                                                checked={client.status === "Y"} onChange={(e) => { setField('status', e.target.checked ? "Y" : "N") }} />
                                                            <label className="form-check-label" htmlFor="flexSwitchInvoiceType"><b>Is Active? </b>
                                                                {
                                                                    client.closedDate && client.status === 'N' ? <sub className="d-flex text-muted">{client.closedDate}</sub> : null
                                                                }
                                                            </label>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Card.Body>
                                        </Card>

                                    </Row>

                                </Form>

                            </Card.Body>
                        </Card>


                    </Container>)
            } </React.Fragment>);
}

// }
