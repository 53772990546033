import {
    LOADALLPROJECT,
    LOADPROJECT,
    RESETPROJECT,
    CHANGEPROJECTFIELD,
    RESETPROJECTS,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.project }, action) {

    switch (action.type) {
        case LOADALLPROJECT:
            return { ...state, projects: [...action.project.rows] }

        case LOADPROJECT:
            return { ...state, project: { ...action.project } };
        case CHANGEPROJECTFIELD:
            if (action.model)
                return { ...state, project: { ...state.project, [action.model]: { ...(state.project[action.model] ?? {}), [action.name]: action.value } } };
            else
                return { ...state, project: { ...state.project, [action.name]: action.value } };
        case RESETPROJECT:
            return { ...state, project: { ...initialState.project.project } }

        case RESETPROJECTS:
            return { ...state, projects:[] }
        default:
            return { ...state };
    }
}