import {
    LOADSUMMARYREPORT,RESETSUMMARYREPORT,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.report }, action) {

    switch (action.type) {
        case LOADSUMMARYREPORT:
            return { ...state, summary: { ...action.summary } }

        case RESETSUMMARYREPORT:
            return { ...state, summary: { } }

        default:
            return { ...state };
    }
}