import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Form, Card, Col, FloatingLabel, } from "react-bootstrap";


import RTable from "./Table"

import DatePicker from 'react-date-picker'

import { format } from "date-fns";

import { loadAllInvoice, loadInvoiceService, resetInvoice } from "../action/invoiceAction ";
// import { changeTds, loadAllTds, loadTds, resetTds, saveTdsService } from "../action/tdsAction";
import { Typeahead } from "react-bootstrap-typeahead"
import { notification, warningNotification } from "../action/notificationAction";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";
import { filterClient, filterProjects, filterProjectsSearch } from "../constant/filterTypeahead";
import { changeCreditNote, loadAllCreditNote, loadCreditNote, resetCreditNote, saveCreditNoteService } from "../action/creditAction";
import { tr } from "date-fns/locale";



export default function CreditNote({ minDate, permitted }) {

  const dispatch = useDispatch();

  const proTypeRef = React.useRef(null);

  const cltEntryTypeRef = React.useRef(null);
  const proEntryTypeRef = React.useRef(null);
  const venEntryTypeRef = React.useRef(null);


  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedTds, setSelectedTds] = useState({});
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState('active');
  const [invoice, setInvoice] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`CREDITNOTE_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`CREDITNOTE_UPDATE`));




  const creditnotes = useSelector(
    (state) => state.creditnote.creditnotes
  );

  const creditnote = useSelector(
    (state) => state.creditnote.creditnote
  );

  const client = useSelector(
    (state) => state.lookup.client
  );

  const vendor = useSelector(
    (state) => state.lookup.vendor
  );


  const project = useSelector(
    (state) => state.lookup.project
  );


  const invoices = useSelector(
    (state) => state.invoice.invoices
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );



  const handleClose = (skipLoad) => {
    setShow(false);
    setSelectedTds(null);
    setErrors({});

    if (!skipLoad) {
      load();
    }

    dispatch(resetCreditNote());
  }



  const handleShow = (selected) => {
    setShow(true);
    if (selected && selected.original.id) {
      setSelectedTds(selected.original.id);
      dispatch(loadCreditNote(selected.original.id));

      dispatch(loadAllInvoice('active', 'Client', selected.original.clientid, selected.original.projectid, 'id,number', null, null, null, null, costcenter))
    }
    else {
      setSelectedTds({});
      setField("date", format(new Date(), "yyyy-MM-dd"));
    }
  }





  const loadInvoices = (projectId, deductedon) => {

    if (projectId && projectId !== "0") {

      dispatch(loadAllInvoice('active', deductedon === "CLIENT" ? 'Client' : 'Vendor', deductedon === "CLIENT" ? creditnote.clientid : creditnote.vendorid, projectId, 'id,number', null, null, null, null, costcenter))
    }
    else {
      dispatch(resetInvoice());
    }

  }

  const loadInvoice = (invoiceId) => {

    if (invoiceId) {
      loadInvoiceService(invoiceId)
        .then(res => {
          setInvoice(res)
        })
        .catch(err => {
          setInvoice(null);
          dispatch(notification(err));

        });

    }
    else {
      setInvoice(null);
    }

  }

  const columns = React.useMemo(
    () => [

      {
        Header: 'Client',
        accessor: 'clientname',
        id: 'client'
      },
      {
        Header: 'Vendor',
        accessor: 'vendorname',
        id: 'vendor'
      },
      {
        Header: 'Project',
        accessor: 'projectname',
      },
      {
        Header: 'Date',
        accessor: (cell) => cell['date'] ? format(new Date(cell['date']), "dd-MM-yyyy") : "",
      }, {
        Header: 'Invoice',
        accessor: 'invoice_number',
        textFilter: true
      },
      {
        Header: 'Invoice Amount',
        accessor: (cell) => formatCurrency(cell['invoice_gross']),
        className: 'text-end',
        hideFilter: true
      },
      {
        Header: 'Amount',
        accessor: (cell) => formatCurrency(cell['amount']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Closed',
        accessor: (cell) => cell['closedDate'] ? format(new Date(cell['closedDate']), "dd-MM-yyyy") : "",
        show: status === 'active',
        id: 'closedDate'
      }],
    [status]
  )


  const load = () => {
    if (selectedProjectId) {

      dispatch(loadAllCreditNote({ status, projectid: selectedProjectId, costcenter }))
    }
    else {
      dispatch(loadAllCreditNote({ status, costcenter }))
    }
  }

  const setField = (field, value, idx) => {
    dispatch(changeCreditNote({ name: field, value, idx }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let dataToPost = {};
      let method = "POST";

      if (!creditnote.id) {
        dataToPost = {
          ...creditnote,
          type: 'RECEIVED'
        };
      }
      else {
        dataToPost = {
          ...creditnote
        };
        method = "PATCH";
      }

      saveCreditNoteService(dataToPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetCreditNote());
      })
        .catch(err => {
          dispatch(notification(err))
        })

    }

  }

  const findFormErrors = () => {

    const { clientid, vendorid, deductedon, projectid, date, amount, invoiceid } = creditnote
    const newErrors = {}

    if (!date || date === '') newErrors.date = 'date cannot be blank!';

    if (deductedon === "CLIENT" && (!clientid || clientid === "0")) newErrors.client = 'Select a client!'

    if (deductedon === "VENDOR" && (!vendorid)) newErrors.vendor = 'Select a vendor!'

    if (!projectid || projectid === "0") newErrors.project = 'Select a project!'

    if (!invoiceid || invoiceid === "0") newErrors.invoice = 'Select a invoice!'

    if (!amount || amount === "") newErrors.amount = 'Amount cannot be blank!'

    return newErrors
  }


  React.useEffect(() => {
    load();
    handleClose(true);
    setSelectedProjectId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, costcenter]);

  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])

  
  const handleChange = (e) => { 

    const balance = Number(invoice?.basic ?? 0);
    setErrors({...errors, amount:null});

    if (balance < Number(e.target.value)) {
     
      setErrors({...errors, amount:"Credit/Debit amount should not be greater than invoice value"});
      // dispatch(warningNotification("Tax amount should not be greater than invoice gst value"))
    }

    else {
      setField("amount", e.target.value)
      
    }
  }



  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      {
        !show ? (

          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 >Credit/Debit Note </h6>
                {createPermission ?
                  <div className="ms-auto">

                    {createPermission ? <Button variant="success" size="sm" onClick={() => handleShow()}> <i className="fa fa-plus d-md-none d-sm-inline"></i>
                      <p className="d-none d-md-inline">Add New</p></Button> : null}
                  </div> : null}
              </Card.Header>
              <Card.Body>
                <Row className="mb-3 g-2">
                  <Col sm={12} md={3} >

                    <Typeahead
                      id="floating-label-pro-filter"
                      onChange={(e) => {
                        setSelectedProjectId(e[0]?.id);
                      }}

                      clearButton
                      labelKey="name"
                      onBlur={() => {
                        if (!selectedProjectId) {
                          proTypeRef.current?.clear();
                        }
                      }}
                      options={filterProjectsSearch(project, costcenter)}

                      ref={proTypeRef}

                      placeholder="Choose s project"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingProjectFilter" label="Select a project">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedProjectId ? project.filter(e => e.id === Number(selectedProjectId)) : []}
                    />


                  </Col>


                  <Col>
                    {
                      !show ? (<div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">


                        <Button variant="primary" size="sm" onClick={load}>Search</Button>
                      </div>) : ""}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <RTable columns={columns} data={creditnotes} title="Credit/Debit Notes"
              // status={status}
              // changeStatus={setStatus} 
              hideColumn={status === 'active' ? ['closedDate'] : []}
              edit={handleShow} >



            </RTable>
          </Container>) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6>Credit/Debit Note </h6>
                <div className="ms-auto">

                  {((creditnote.id && updatePermission) || (!creditnote.id && createPermission)) ?
                    <Button variant="primary" size="sm" onClick={handleSubmit} title={creditnote.id ? "Update" : "Save"} className="me-2">
                      <i className="fa fa-save d-md-none d-sm-inline"></i>
                      <p className="d-none d-md-inline">  {creditnote.id ? "Update" : "Save"}</p></Button>
                    : null}
                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p>
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>

                <Row >

                  <Card className="col-sm-12 col-md-6 border-0 mb-0">
                    <Card.Body className="pt-0 pb-0">
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingTdsOn" label="Note On">
                          <Form.Select id="floatingTdsOn" isInvalid={!!errors.deductedon} value={creditnote?.deductedon ?? "0"}
                            onChange={e => {
                              setField("deductedon", e.target.value);
                              setField("clientid", null);
                              setField("vendorid", null)
                              setField("projectid", null);
                              loadInvoices("0");
                            }}
                          >

                            <option value="0">Select Note On</option>
                            <option value="CLIENT">Client</option>
                            <option value="VENDOR">Vendor</option>


                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.deductedon} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      {
                        !creditnote || !creditnote.deductedon || creditnote.deductedon === "0" ? null : (
                          <React.Fragment>
                            {

                              creditnote.deductedon === 'CLIENT' ?
                                (<Col className="mb-3">


                                  <Typeahead
                                    id="floating-label-clt"
                                    onChange={(e) => {
                                      setField('clientid', e[0]?.id)
                                      setField('projectid', null)
                                    }}
                                    clearButton
                                    onBlur={() => {
                                      if (!creditnote.clientid) {
                                        cltEntryTypeRef.current?.clear();
                                        proEntryTypeRef.current?.clear();
                                        setField('projectid', null);
                                        setField('clientid')
                                      }
                                    }}
                                    labelKey="name"
                                    options={filterClient(client, costcenter)}
                                    ref={cltEntryTypeRef}
                                    placeholder="Choose a client"
                                    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                      return (

                                        <FloatingLabel controlId="floatingClient" label="Select a client">
                                          <Form.Control
                                            isInvalid={!!errors.client}
                                            {...inputProps}
                                            ref={(node) => {
                                              inputRef(node);
                                              referenceElementRef(node);
                                            }}
                                          />
                                          <Form.Control.Feedback type='invalid'>{errors.client} </Form.Control.Feedback>
                                        </FloatingLabel>

                                      );
                                    }}
                                    selected={creditnote.clientid ? client.filter(e => e.id === Number(creditnote.clientid)) : []}
                                  />

                                </Col>)
                                : creditnote.deductedon === 'VENDOR' ? (
                                  <Col className="mb-3">

                                    <Typeahead
                                      id="floating-label-ven"
                                      onChange={(e) => {
                                        setField('vendorid', e[0]?.id);
                                        setField("projectid", null);
                                        loadInvoices("0");
                                      }}
                                      clearButton
                                      onBlur={() => {
                                        if (!creditnote.vendorid) {
                                          venEntryTypeRef.current?.clear();
                                          proEntryTypeRef.current?.clear();
                                          loadInvoices("0");
                                        }
                                      }}
                                      labelKey="name"
                                      options={vendor}
                                      ref={venEntryTypeRef}
                                      placeholder="Choose a vendor"
                                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                        return (

                                          <FloatingLabel controlId="floatingVendorId" label="Select a vendor">
                                            <Form.Control isInvalid={!!errors.mapping}
                                              {...inputProps}
                                              ref={(node) => {
                                                inputRef(node);
                                                referenceElementRef(node);
                                              }}
                                            />
                                            <Form.Control.Feedback type='invalid'>{errors.vendor} </Form.Control.Feedback>
                                          </FloatingLabel>

                                        );
                                      }}
                                      selected={creditnote.vendorid || creditnote.vendorid === 0 ? vendor.filter(e => e.id === Number(creditnote.vendorid)) : []}
                                    />

                                  </Col>) : null


                            }
                            <Col className="mb-3">
                              <Typeahead
                                id="floating-label-pro"
                                onChange={(e) => {
                                  setField('projectid', e[0]?.id)
                                  if (e[0] && e[0].id) {

                                    loadInvoices(e[0].id, creditnote.deductedon);
                                  }
                                  else {
                                    dispatch(resetInvoice());
                                  }
                                }}
                                clearButton
                                labelKey="name"
                                onBlur={() => {
                                  if (!creditnote.projectid) {
                                    proEntryTypeRef.current?.clear();
                                    dispatch(resetInvoice());
                                  }
                                }}
                                options={project && ((creditnote.clientid && creditnote.clientid !== "0") || creditnote.deductedon === 'VENDOR') ?
                                  filterProjects(project, costcenter).filter(e => Number(e.clientid) === Number(creditnote.clientid) || creditnote.deductedon === 'VENDOR') : []}

                                ref={proEntryTypeRef}

                                placeholder="Choose A Project"
                                renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                  return (

                                    <FloatingLabel controlId="floatingProject" label="Select A Project">
                                      <Form.Control isInvalid={!!errors.project}
                                        {...inputProps}
                                        ref={(node) => {
                                          inputRef(node);
                                          referenceElementRef(node);
                                        }}
                                      />
                                      <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                                    </FloatingLabel>

                                  );
                                }}
                                selected={creditnote.projectid ? project.filter(e => e.id === Number(creditnote.projectid)) : []}
                              />



                            </Col>
                          </React.Fragment>


                        )}
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingInvoice" label="Invoice">
                          <Form.Select id="floatingInvoice" aria-label="Invoice" isInvalid={!!errors.invoice}
                            value={creditnote?.invoiceid ?? "0"}
                            onChange={e => {
                              setField("invoiceid", e.target.value)
                              loadInvoice(e.target.value);
                            }}
                          >
                            <option value="0">Select a Invoice</option>
                            {
                              invoices ? invoices.map((e, i) => {
                                return <option key={`inv${i}`} value={e.id} >{e.number}</option>
                              }) : null
                            }

                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.invoice} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>



                    </Card.Body>

                  </Card>
                  <Card className="col-sm-12 col-md-6 border-0">
                    <Card.Body className="pt-0 pb-0" >
                      {invoice ? <Col className="mb-3">
                        <Row className="g-2">
                          <Col sm={6} >
                            <FloatingLabel controlId="floatingBasic" label="Basic" >
                              <Form.Control type="text"  placeholder="Basic" value={invoice?.basic ?? "0"} readOnly="true"/>
                              
                            </FloatingLabel>
                          </Col>
                          <Col sm={6} >
                            <FloatingLabel controlId="floatingGst" label="GST" >
                              <Form.Control type="text" placeholder="GST" value={invoice?.gst ?? "0"}  readOnly="true"/>
                            </FloatingLabel>
                          </Col>
                        </Row>
                      </Col> : null}
                      

                      <Col className="mb-3">

                        <div style={{ position: "relative" }} className="dp">
                          <DatePicker maxDate={new Date()}
                            minDate={minDate ? new Date(minDate) : null}
                            id="tdsDate"
                            format="dd-MM-yyyy"
                            calendarIcon={<i className="fa fa-calendar"></i>}
                            clearIcon = {null}
                            className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                            //isInvalid={true}
                            value={creditnote.date ? new Date(creditnote.date) : ""}
                            onChange={(date) => setField("date", format(date, "yyyy-MM-dd"))}  

                          ></DatePicker>
                          <label className="black-text" htmlFor="tdsDate"> Date</label>
                        </div>

                      </Col>


                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingAmount" label="Amount">
                          <Form.Control type="text" placeholder="Amount" isInvalid={!!errors.amount} 
                            value={creditnote.amount ?? ""} 
                            onChange={e => handleChange(e)} />
                          <Form.Control.Feedback type='invalid'>{errors.amount} 
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>



                    </Card.Body>
                  </Card>
                </Row>
              </Card.Body>
            </Card>
          </Container>)
      }
      {/* <Confirm show={showConfirm} confirmation="Are you sure?" confirmCallBack={deletePayRequestOrder}></Confirm> */}
    </React.Fragment >);
}

// }
