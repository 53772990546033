import {
    LOADALLPO,
    LOADPO,
    RESETPO,
    CHANGEPOFIELD, SET_NOTIFICATION, ADDPOITEM, REMOVEPOITEM, LOADPOLOOKUP
} from "../constant/constant";
import { pAxios } from "..";
import { toDate } from "date-fns";



function loadAllPoService(status, side, filter, projectId, fromDate, toDate, costcenter) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/po?status=${status}&side=${side}${filter!==null ? "&filter="+filter :""}${projectId?"&projectId="+projectId:""}${fromDate?`&fromDate=${fromDate}`:''}${toDate?`&toDate=${toDate}`:''}${costcenter && costcenter!=="0"?"&costcenter="+costcenter:""}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLPO, po: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load po data", variant: "danger", err } })
            });
    });

}

function loadPoService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/po/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADPO, po: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load vendor data", variant: "danger", err } })
            });
    });

}


function loadPoLookupService(side) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/po?status=active&side=${side}&attributes=id,number,projectid,vendorid&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADPOLOOKUP, po: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load material data", variant: "danger", err } })
            });
    });

}

export function savePoService(data, method) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/po`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                //     type: SET_NOTIFICATION, notification:
                   resolve({ message: `Po ${method === "POST" ? "created" : "updated"} successfull`, variant: "success", err: "" })
                // });
                // resolve({ type: RESETPO });
            }
        })
            .catch(err => {

                reject({message: "Failed to save vendor data", variant: "danger", err } )
            });
    });

}


export function chanagePoStatusService(id,status, screen_name) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/po/status?id=${id}&status=${status}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                        resolve({ message: `PO status changed successfull`, variant: "success", err: "" });
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to change po status", variant: "danger", err } )
            });
    });

}


export function deletepoService(id,status,screen_name) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/po?id=${id}&status=${status}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                        resolve({ message: `PO delete successfull`, variant: "success", err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to delete PO ", variant: "danger", err } )
            });
    });

}



export const loadAllPo = (status, side, filter, projectId, fromDate, toDate, costcenter) => {

    return dispatch => {

        loadAllPoService(status, side, filter, projectId, fromDate, toDate, costcenter)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadPo = (id) => {

    return dispatch => {

        loadPoService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}




export const changePo = ({ name, value, idx }) => {

    return dispatch => {
        dispatch({ type: CHANGEPOFIELD, name, value, idx });
    }
}


export const addPoItem=(idx)=>{

    return dispatch => {
      dispatch({type:ADDPOITEM,idx});
  }
}


export const removePoItem=(idx)=>{

    return dispatch => {
    dispatch({type:REMOVEPOITEM,idx});
  }
}


export const resetPo = () => {

    return dispatch => {
        dispatch({ type: RESETPO, });
    }

}

// export const savePo = (po) => {

//     return dispatch => {
//         savePoService(po, "POST")
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }


// export const updatePo = (po) => {

//     return dispatch => {
//         savePoService(po, "PATCH")
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }


export const loadPoLookup=(side)=>{

    return dispatch => {
  
        loadPoLookupService(side)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }




//   export const changePoStatus = (id,status) => {

//     return dispatch => {
//         chanagePoStatusService(id,status)
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }

// export const deletePo = (id) => {

//     return dispatch => {
//         deletepoService(id)
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }