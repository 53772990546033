import {
    LOADALLCREDIT,
    LOADCREDIT,
    RESETCREDIT,
    CHANGECREDITFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.creditnote }, action) {

    switch (action.type) {
        case LOADALLCREDIT:
            return {...state,creditnotes:[...action.creditnote.rows]}
       
        case LOADCREDIT:
            return { ...state, creditnote:{...action.creditnote} };
        case CHANGECREDITFIELD:
            return { ...state, creditnote:{...state.creditnote,[action.name]:action.value}};
        case RESETCREDIT:
            return {...state,creditnote:{...initialState.creditnote.creditnote}}
        default:
            return { ...state };
    }
}