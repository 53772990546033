import {
    LOADALLINVOICE,
    LOADINVOICE,
    RESETINVOICE,
    CHANGEINVOICEFIELD, SET_NOTIFICATION, ADDINVOICEITEM, REMOVEINVOICEITEM, RESETINVOICEITEMS, CHANGEINVOICEITEMS
} from "../constant/constant";
import { pAxios } from "..";


function loadAllInvoiceService({status, side, filter, projectId,attributes,execludeAuto,execludePaid, fromDate, toDate, costcenter}) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/invoice?status=${status}&side=${side}${filter!=null ? "&filter="+filter :""}${projectId?"&projectId="+projectId:""}${attributes?"&attributes="+attributes:""}${execludeAuto?'&execludeAuto=Y':''}${execludePaid?'&execludePaid=Y':''}${fromDate?`&fromDate=${fromDate}`:''}${toDate?`&toDate=${toDate}`:''}${costcenter && costcenter!=="0"?"&costcenter="+costcenter:""}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLINVOICE, invoice: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load invoice data", variant: "danger", err } })
            });
    });

}

export function loadInvoiceService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/invoice/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

          //  if (response.data) {

                resolve(response.data);
            //}
        })
            .catch(err => {

                reject({ message: "Failed to load purchase order data", variant: "danger", err } )
            });
    });

}

export function loadPurchaseOrderService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/po/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {
            resolve(response.data??{});

        })
            .catch(err => {

                reject({ message: "Failed to load purchase order data", variant: "danger", err })
            });
    });

}

export function loadPurchaseOrderQtyService(poid,invoiceid) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/invoice/qty?poid=${poid}${invoiceid?"&invoiceid="+invoiceid:""}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {
            resolve(response.data??{});

        })
            .catch(err => {

                reject({ message: "Failed to load po qty data", variant: "danger", err })
            });
    });

}



export function saveInvoiceService(data, method) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/invoice`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                //     type: SET_NOTIFICATION, notification:
                       resolve({ message: `Invoice ${method === "INVOICEST" ? "created" : "updated"} successfull`, variant: "success", err: "" })
                // });
                // resolve({ type: RESETINVOICE });
            }
        })
            .catch(err => {

                reject({message: "Failed to save invoice data", variant: "danger", err } )
            });
    });

}


export function chanageInvoiceStatusService(id,status) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/invoice/status?id=${id}&status=${status}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                        resolve({ message: `Invoice status changed successfull`, variant: "success", err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to change invoice status", variant: "danger", err })
            });
    });

}


export function deleteInvoiceService(id,status) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/invoice?id=${id}&status=${status}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                       resolve({ message: `Invoice delete successfull`, variant: "success", err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to delete invoice ", variant: "danger", err })
            });
    });

}

export function allocateInvoiceService(data,filter,id, type) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/invoice/allocate?id=${id}&filter=${filter}&type=${type}`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                       resolve({ message: `Invoice delete successfull`, variant: "success", err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to delete invoice ", variant: "danger", err })
            });
    });

}


export function markAsAllocatedService(id, screen_name) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "PATCH",
            "url": `/api/payment/allocated?id=${id}&screen_name=${screen_name}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                // store.dispatch({
                    // type: SET_NOTIFICATION, notification:
                       resolve({ message: `Payment marked as allocated`, variant: "success", err: "" })
                // });
            }
        })
            .catch(err => {

                reject({ message: "Failed to update status ", variant: "danger", err })
            });
    });

}


export const loadAllInvoice = (status, side, filter, projectId, attributes, execludeAuto,execludePaid,fromDate, toDate, costcenter) => {

    return dispatch => {

        loadAllInvoiceService({status, side, filter, projectId, attributes, execludeAuto,execludePaid, fromDate, toDate, costcenter})
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadInvoice = (res) => {

    return dispatch => {
        dispatch({ type: LOADINVOICE, invoice:res });
    }
}



export const changeInvoice = ({ name, value, idx }) => {

    return dispatch => {
        dispatch({ type: CHANGEINVOICEFIELD, name, value, idx });
    }
}


export const addInvoiceItem=(idx,row)=>{

    return dispatch => {
      dispatch({type:ADDINVOICEITEM,idx,row});
  }
}


export const removeInvoiceItem=(idx)=>{

    return dispatch => {
    dispatch({type:REMOVEINVOICEITEM,idx});
  }
}


export const resetInvoice = () => {

    return dispatch => {
        dispatch({ type: RESETINVOICE, });
    }

}


export const resetInvoiceWithItems = () => {

    return dispatch => {
        dispatch({ type: RESETINVOICEITEMS, });
    }

}

export const changeInvoiceItems = (item) => {

    return dispatch => {
        dispatch({ type: CHANGEINVOICEITEMS, item });
    }

}


// export const saveInvoice = (invoice) => {

//     return dispatch => {
//         saveInvoiceService(invoice, "POST")
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }


// export const updateInvoice = (invoice) => {

//     return dispatch => {
//         saveInvoiceService(invoice, "PATCH")
//             .then(response => dispatch(response))
//             .catch(err => dispatch(err));
//     }

// }
