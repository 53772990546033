import React from 'react';
import { Home } from './component/Home'
import { AppMain } from './component/Layout/AppMain'
import Login from './component/Login'
import PrivateRoute from "./component/PrivateRoute";
import PublicRoute from "./component/PublicRoute";
import { Router, Switch, Redirect } from 'react-router-dom';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

import { history } from './app/history';
import Notification from "./component/Notification";
import CustomLoader from "./component/CustomLoader";
// import { loadAllLookupsService } from './action/lookupAction';



function App() {

  return (
    <React.Fragment>
    <CustomLoader />
     <div className="bg-dark-cust">
      <Notification />
      <Router history={history}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute path="/" component={AppMain} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      </div> 
    </React.Fragment>

  );
}

export default App;
