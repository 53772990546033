import sideBar6 from '../assets/utils/images/sidebar/abstract10.jpg';


export const LOADING= "loading";


export const LOADUSER="loadUser";
export const RESETUSER="resetUser";
export const GETPASSWORD="getPassword";
export const LOADALLUSER="loadAllUser";
export const CHANGEUSERFIELD="changeUserField";
export const REFRESHTOKEN="refreshToken";
export const UPDATE_USER_WALLET="updateUserWallet";
export const UPDATE_COSTCENTER="updateCostCenter";
export const LOAD_COSTCENTERS="loadCostCenters";
export const UPDATE_DASH_SPREAD="updateDashSpread";
export const UPDATE_DASH_SPREAD_CLOSED="updateDashSpreadClosed";
export const UPDATE_DASH_PENDING="updateDashPending";
export const UPDATE_DASH_PROFIT="updateDashProfit";


export const LOADCLIENT="loadClient";
export const RESETCLIENT="resetClient";
export const RESETCLIENTS="resetClients";
export const LOADALLCLIENT="loadAllClient";
export const CHANGECLIENTFIELD="changeClientField";
export const SAVECLIENT="saveClient";
export const UPDATECLIENT ="updateClient";


export const LOADALLVENDOR="loadAllVendor";
export const LOADVENDOR="loadVendor";
export const CHANGEVENDORFIELD="changeVendorField";
export const SAVEVENDOR="saveVendor";
export const UPDATEVENDOR="updateVendor";
export const RESETVENDOR="resetVendor";
export const RESETVENDORS="resetVendors";


export const LOADALLPROJECT="loadAllProject";
export const LOADPROJECT="loadProject";
export const CHANGEPROJECTFIELD="changeProjectField";
export const SAVEPROJECT="saveProject";
export const UPDATEPROJECT="updateProject";
export const RESETPROJECT="resetProject";
export const RESETPROJECTS="resetProjects";

export const LOADALLLABOUR="loadAllLabour";
export const LOADLABOUR="loadLabour";
export const CHANGELABOURFIELD="changeLabourField";
export const SAVELABOUR="saveLabour";
export const UPDATELABOUR="updateLabour";
export const RESETLABOUR="resetLabour";

export const LOADALLCATEGORY="loadAllCategory";
export const LOADCATEGORY="loadCategory";
export const CHANGECATEGORYFIELD="changeCategoryField";
export const SAVECATEGORY="saveCategory";
export const UPDATECATEGORY="updateCategory";
export const RESETCATEGORY="resetCategory";

export const LOADALLMATERIAL="loadAllMaterial";
export const LOADMATERIAL="loadMaterial";
export const CHANGEMATERIALFIELD="changeMaterialField";
export const SAVEMATERIAL="saveMaterial";
export const UPDATEMATERIAL="updateMaterial";
export const RESETMATERIAL="resetMaterial";


export const LOADALLACTIVITY="loadAllActivity";
export const LOADACTIVITY="loadActivity";
export const CHANGEACTIVITYFIELD="changeActivityField";
export const SAVEACTIVITY="saveActivity";
export const UPDATEACTIVITY="updateActivity";
export const RESETACTIVITY="resetActivity";



export const LOADALLCONFIG="loadAllConfig";
export const CHANGECONFIGFIELD="changeConfigField";
export const SAVECONFIG="saveConfig";
export const UPDATECONFIG="updateConfig";




export const LOADALLEXPENSE="loadAllExpense";
export const LOADEXPENSE="loadExpense";
export const CHANGEEXPENSEFIELD="changeExpenseField";
export const SAVEEXPENSE="saveExpense";
export const UPDATEEXPENSE="updateExpense";
export const RESETEXPENSE="resetExpense";

export const LOADALLPAYMENT="loadAllPayment";
export const LOADPAYMENT="loadPayment";
export const CHANGEPAYMENTFIELD="changePaymentField";
export const SAVEPAYMENT="savePayment";
export const UPDATEPAYMENT="updatePayment";
export const RESETPAYMENT="resetPayment";
export const RESETPAYMENTS="resetPayments";


export const LOADALLCREDIT="loadAllCredit";
export const LOADCREDIT="loadCredit";
export const CHANGECREDITFIELD="changeCreditField";
export const SAVECREDIT="saveCredit";
export const UPDATECREDIT="updateCredit";
export const RESETCREDIT="resetCredit";
export const RESETCREDITS="resetCredits";

export const LOADALLTDS="loadAllTds";
export const LOADTDS="loadTds";
export const CHANGETDSFIELD="changeTdsField";
export const SAVETDS="saveTds";
export const UPDATETDS="updateTds";
export const RESETTDS="resetTds";
export const RESETTDSS="resetTdss";


export const LOADALLCONTACT="loadAllContact";
export const LOADCONTACT="loadContact";
export const CHANGECONTACTFIELD="changeContactField";
export const SAVECONTACT="saveContact";
export const UPDATECONTACT="updateContact";
export const RESETCONTACT="resetContact";
export const RESETCONTACTS="resetContacts";


export const LOGIN_USER="userLogin";
export const LOGOUT_USER="userLogout";
export const LOGIN="LOGIN";
export const RESETLOGIN="resetLogin";

export const SET_NOTIFICATION="setNotification";
export const RESET_NOTIFICATION = "resetNotification";

export const LOADLOOKUPS="loadLookups";
export const LOADCLIENTLOOKUPS="loadClientLookups";
export const LOADVENDORLOOKUPS="loadVendorLookups";
export const LOADPROJECTLOOKUPS="loadProjectLookups";
export const LOADLABOURLOOKUPS="loadLabourLookups";
export const LOADCATEGORYLOOKUP = "loadAllCategoryLookup";
export const LOADMATERIALLOOKUP = "loadAllMaterialLookup";
export const LOADACTIVITYLOOKUP = "loadAllActivityLookup";
export const LOADUSERLOOKUP = "loadAllUserLookup";
export const LOADPOLOOKUP = "loadAllPoLookup";



export const ADDPOITEM ="addPoitem";
export const REMOVEPOITEM="removePoitem";
export const CHANGEPOFIELD ="changePofield";
export const LOADALLPO ="loadAllPo";
export const LOADPO ="loadPo";
export const RESETPO="resetPo";


export const ADDINVOICEITEM ="addInvoiceitem";
export const REMOVEINVOICEITEM="removeInvoiceitem";
export const CHANGEINVOICEFIELD ="changeInvoicefield";
export const LOADALLINVOICE ="loadAllInvoice";
export const LOADINVOICE ="loadInvoice";
export const RESETINVOICE="resetInvoice";



export const CHANGEROLEMAPPING ="changeRoleMapping";
export const LOADROLEMAPPING ="loadAllRoleMapping";




export const RESETINVOICEITEMS="resetInvoiceItems";
export const CHANGEINVOICEITEMS="changeInvoiceItems";

export const RESETAUTHUSER = "reseyAuthUser";

export const LOADSUMMARYREPORT ="laodSummaryReport";
export const RESETSUMMARYREPORT ="resetSummaryReport";

export const PAGESIZE=50;

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';


export const initialState = { 
	
	wallet: 0,
	auth_user:{userid:null},
    user: { users:[],user:{address:{},aadhar:{},bank:{}}},
	client: { clients:[],client:{address:{},bank:{},users:[]}},
	notification: { message: null, variant: "default" },
    vendor: { vendors:[],vendor:{address:{},bank:{}}},
	project: { projects:[], project:{client:{}, address:{}}},
	fetch:{isLoading:false},
	lookup:{client:[],project:[],vendor:[],labour:[], categories:{}},
	labour:{labours:[], labour:{address:{},aadhar:{},bank:{}}},
	category:{categories:[],category:{}},
	material:{materials:[],material:{category:{}}},
	activity:{activities:[],activity:{}},
	po:{pos:[],po:{items:[{idx:1}]}},
	invoice:{invoices:[],invoice:{items:[{idx:1}]}},
	payment: {payments:[],payment:{}},
	tds: {tdsentries:[],tds:{}},
	expense:{expenses:[], expense:{}},
	report: {summary:{}},
	roleMapping:[],
	ThemeOptions:{
		backgroundColor: 'bg-asteroid sidebar-text-light',
		headerBackgroundColor: 'bg-header  header-text-light',
		enableMobileMenuSmall: '',
		enableBackgroundImage: true,
		enableClosedSidebar: true,
		enableFixedHeader: true,
		enableHeaderShadow: true,
		enableSidebarShadow: true,
		enableFixedFooter: true,
		enableFixedSidebar: true,
		colorScheme: 'white',
		backgroundImage: sideBar6,
		backgroundImageOpacity: 'opacity-06',
		// enablePageTitleIcon: true,
		// enablePageTitleSubheading: true,
		enablePageTabsAlt: false,
	},
	config:[],
	costcenter:{costcenters:[],costcenter:0},
	contact:{contacts:[], contact:{}},
	creditnote:{creditnotes:[], creditnote:{}},
	dashboard:{highlightChart:null, request:null, highlightChartClosed:null, profit:null}
	
	
};

export function sortByName(rows){

	return rows.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
 
 }

export function sortByText(rows){

	return rows.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
 
 }

 
export const setEnableBackgroundImage = enableBackgroundImage => ({
    type: SET_ENABLE_BACKGROUND_IMAGE,
    enableBackgroundImage
});

export const setEnableFixedHeader = enableFixedHeader => ({
    type: SET_ENABLE_FIXED_HEADER,
    enableFixedHeader
});

export const setEnableHeaderShadow = enableHeaderShadow => ({
    type: SET_ENABLE_HEADER_SHADOW,
    enableHeaderShadow
});

export const setEnableSidebarShadow = enableSidebarShadow => ({
    type: SET_ENABLE_SIDEBAR_SHADOW,
    enableSidebarShadow
});


export const setEnablePageTabsAlt = enablePageTabsAlt => ({
    type: SET_ENABLE_PAGE_TABS_ALT,
    enablePageTabsAlt
});

export const setEnableFixedSidebar = enableFixedSidebar => ({
    type: SET_ENABLE_FIXED_SIDEBAR,
    enableFixedSidebar
});

export const setEnableClosedSidebar = enableClosedSidebar => ({
    type: SET_ENABLE_CLOSED_SIDEBAR,
    enableClosedSidebar
});

export const setEnableMobileMenu = enableMobileMenu => ({
    type: SET_ENABLE_MOBILE_MENU,
    enableMobileMenu
});

export const setEnableMobileMenuSmall = enableMobileMenuSmall => ({
    type: SET_ENABLE_MOBILE_MENU_SMALL,
    enableMobileMenuSmall
});

export const setEnableFixedFooter = enableFixedFooter => ({
    type: SET_ENABLE_FIXED_FOOTER,
    enableFixedFooter
});

export const setBackgroundColor = backgroundColor => ({
    type: SET_BACKGROUND_COLOR,
    backgroundColor
});

export const setHeaderBackgroundColor = headerBackgroundColor => ({
    type: SET_HEADER_BACKGROUND_COLOR,
    headerBackgroundColor
});

export const setColorScheme = colorScheme => ({
    type: SET_COLOR_SCHEME,
    colorScheme
});

export const setBackgroundImageOpacity = backgroundImageOpacity => ({
    type: SET_BACKGROUND_IMAGE_OPACITY,
    backgroundImageOpacity
});

export const setBackgroundImage = backgroundImage => ({
    type: SET_BACKGROUND_IMAGE,
    backgroundImage
});
 