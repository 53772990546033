import { LOADALLMATERIAL,
    LOADMATERIAL,
    RESETMATERIAL,
    CHANGEMATERIALFIELD,SET_NOTIFICATION, LOADMATERIALLOOKUP} from "../constant/constant";
import {pAxios} from "..";
// import store from "../app/store";


function loadAllMaterialService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/material`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLMATERIAL, material: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load material data",variant:"danger",err}  })
            });
    });

}

function loadMaterialService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/material/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADMATERIAL, material: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
          });
  });

}



function loadMaterialUpService(){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/material?status=active&attributes=id,name&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADMATERIALLOOKUP, material: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load material lookup",variant:"danger",err}  })
            });
    });
  
  }


export function saveMaterialService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/material`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
            // type: SET_NOTIFICATION, notification:
               resolve({ message: `Material ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETMATERIAL});
         }
      })
          .catch(err => {
              
              reject({ message:"Failed to save vendor data",variant:"danger",err}  )
          });
  });

}


 export const loadAllMaterial=()=>{

    return dispatch => {

        loadAllMaterialService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadMaterial=(id)=>{

  return dispatch => {

      loadMaterialService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeMaterial=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEMATERIALFIELD,name,value,model});
}
  

}

export const resetMaterial=()=>{

  return dispatch => {
    dispatch({type:RESETMATERIAL,});
}
  
}

// export const saveMaterial=(vendor)=>{

//   return dispatch => {
//     saveMaterialService(vendor,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateMaterial=(vendor)=>{

//     return dispatch => {
//       saveMaterialService(vendor,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }




  
  export const loadMaterialLookup=()=>{

    return dispatch => {
  
        loadMaterialUpService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }
  