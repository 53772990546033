export const filterProjects = (projects,costcenter) => {

    if(!projects || projects.length===0){
      return [];
    }    
    return projects.filter(e => (!costcenter || costcenter==="0" || (e.categoryid === Number(costcenter)))  )
  
}


export const filterProjectsSearch = (projects,costcenter) => {

    if(!projects || projects.length===0){
      return [];
    }    
    return projects.filter(e => (!costcenter || costcenter==="0" || ( e.categoryid === Number(costcenter)))).map(e => {return {...e, "disabled": false} })   
}


export const filterClient = (clients,costcenter) => {

    if(!clients || clients.length===0){
      return [];
    }    
    return clients.filter(e => (!costcenter || costcenter==="0" || ( e.categoryid === Number(costcenter)))  )
  
}


export const filterClientsSearch = (clients,costcenter) => {

    if(!clients || clients.length===0){
      return [];
    }    
    return clients.filter(e => (!costcenter || costcenter==="0" || (e.categoryid === Number(costcenter)))).map(e => {return {...e, "disabled": false} })  
  
}


export const filterVendorsSearch = (vendors,costcenter) => {

    if(!vendors || vendors.length===0){
      return [];
    }    
    return vendors.map(e => {return {...e, "disabled": false} }); 
  
}

export const filterUsersSearch = (users,costcenter) => {

    if(!users || users.length===0){
      return [];
    }    
    return users.filter(e => (!costcenter || costcenter==="0" || ( e.client_category.includes(costcenter)))).map(e => {return {...e, "disabled": false} }); 
  
}

export const filterLabourSearch = (labours,costcenter) => {

    if(!labours || labours.length===0){
      return [];
    }    
    return labours.filter(e => (!costcenter || costcenter==="0" || ( e.costcenter === Number(costcenter)))).map(e => {return {...e, "disabled": false} }); 
  
}

export const filterLabour = (labours,costcenter) => {

    if(!labours || labours.length===0){
      return [];
    }    
    return labours.filter(e => (!costcenter || costcenter==="0" || ( e.costcenter === Number(costcenter)))  )
  
}

