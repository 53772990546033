import { LOADCATEGORYLOOKUP,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";


export function loadAllLookupService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/lookups`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADCATEGORYLOOKUP, category: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load lookup data",variant:"danger",err}  })
            });
    });

}
 
export const loadAllLookup=()=>{

    return dispatch => {

        loadAllLookupService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}
