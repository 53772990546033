import React from "react";

import { useSelector } from "react-redux";

function CustomLoader() {
  const isLoading = useSelector((state) => state.fetch.isLoading);

  return (
    <React.Fragment>
      <div id="overlay" style={{ display: isLoading ? "block" : "none" }}></div>
      {/* <ThreeCircles
        wrapperClass="cust-loader"
        
        color="#0d6efd"
         visible={isLoading}
        height={100}
        width={100}
        style={{
          //  display: isLoading?"flex":"none"
        }}
      /> */}
      <div className="cust-loader " id="preloader"  
        style={{ display: isLoading ? "flex" : "none" }} >
        <div className="preloader"></div>
        <div className="preloader2"></div>
      </div>
    </React.Fragment>
  );
}

export default CustomLoader;
