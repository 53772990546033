import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

import { pAxios } from "..";

import { warningNotification } from "../action/notificationAction";
import { CHANGECONFIGFIELD, LOADALLCONFIG } from "../constant/constant";

import DatePicker from 'react-date-picker'
import { format } from "date-fns";
import { loadAllConfig } from "../action/categoryAction";
import { ColorGradients } from "./Layout/ColorGradients";

export default function Config({ permitted }) {


  const dispatch = useDispatch();

  const config = useSelector(
    (state) => state.config.config
  );

  const loadConfig = () => {

    dispatch(loadAllConfig());

  }

  const [selected,setSelected] = React.useState(1)
  const [selectedColor,setSelectedColor] = React.useState(null)


  React.useEffect(() => {
    loadConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setField = (field, value, name) => {
    dispatch({ type: CHANGECONFIGFIELD, field, value, name });

  }

  const changeRole = (id, name, value,) => {

    pAxios({
      "method": "POST",
      "url": `/api/config`,
      "headers": {
        'content-type': 'application/json'
      },
      "data": JSON.stringify({
        id,
        config_name: name, 
        config_value: value
      })

    })
      .then((res) => {
        loadConfig();
      })
      .catch(err => {
        // setRoleMapping([]);
        dispatch(warningNotification("Failed to update config"))
      })


  }

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>

      <Card className={`mt-1`}>
        <Card.Header className={`${theme.headerBackgroundColor}`}>

          <h6 className="float-start p-1">
            Config
          </h6>
        </Card.Header>


        <Card.Body className="p-1">


          <Container fluid className="p-0">
            {
              config ?

                <Row>{
                  config.filter(e=>e.edittable === 'Y').map((e, i) => {
                    return (
                      <Col sm={12} md={4} key={`config-${i}`}>
                        <InputGroup className="mb-3" >
                          <InputGroup.Text id={`id_${e.config_name}_text`}>
                            {e.config_name}
                          </InputGroup.Text>
                          {
                            e.config_type === 'S' ?
                              <Form.Control disabled={e.edittable === 'N'} id={`id_${e.config_name}`} value={e.config_value}
                                onChange={(e) => setField("", e.target.value, e.config_name)}
                              />
                              :
                              <DatePicker maxDate={new Date()} id={`date_${i}`}
                                className={`form-control custom-padding `}
                                format="dd-MM-yyyy"
                                calendarIcon={<i className="fa fa-calendar"></i>}
                                clearIcon = {null}
                                //isInvalid={true}
                                value={e.config_value ? new Date(e.config_value) : ""}
                                onChange={(date) =>  setField("", format(date, "yyyy-MM-dd"), e.config_name)}  

                              ></DatePicker>
                          }

                          <Button size="sm" disabled={e.edittable === 'N'} variant="success" onClick={() => { changeRole(e.id, e.config_name, e.config_value) }}>Update</Button>
                        </InputGroup>
                      </Col>)
                  })
                }
                </Row>

                : (null)
            }
          </Container>


        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header className={`${theme.headerBackgroundColor}`}>
          <h6 className="text-start flex-fill">Color </h6>
          <select className="form-control w-25" value={selected} onChange={(e)=>{
            setSelected(e.target.value)
          }}>
            <option value="1">Header</option>
            <option value="2">Side Bar</option>
          </select>
          <Button variant="success" className="ms-1"
            onClick={() => { 
              changeRole(selected, Number(selected)===1?"headerBackgroundColor":"backgroundColor", selectedColor) 
            }}
          > Save</Button>
        </Card.Header>
        <Card.Body>
          <ColorGradients selected={selected} setSelectedColor={setSelectedColor}></ColorGradients>
        </Card.Body>
      </Card>


    </React.Fragment >);








}