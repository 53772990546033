import {
    LOGIN_USER, LOGOUT_USER, LOGIN, initialState, RESETAUTHUSER
} from "../constant/constant";




export default function reducer(state = { ...initialState.auth_user }, action) {

    switch (action.type) {
        case LOGIN_USER:
            return { ...state, auth_user: action.user };
       
        case LOGIN:
        case LOGOUT_USER:
            return { ...state, auth_user: {}, login: {} };
        case RESETAUTHUSER:
            return { ...state, auth_user: {} };


        default:
            return { ...state };
    }
}