// https://codesandbox.io/s/xmt6r
import React, { useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  min
} from "date-fns";
import { Button } from "react-bootstrap"
import '../assets/calendar.css';
import { filterProjects } from "../constant/filterTypeahead";

function Calendar({ minDate, updatePermission, load, selectedLabourWage, multiselect, projects, selectedProject, setSelectedProject, show, currentMonth, setCurrentMonth, mandays, header_bg, costcenter, markAsAbsent, deleteEntry }) {


  const minWeek = getWeek(new Date(minDate));
  const thisWeek = getWeek(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  // eslint-disable-next-line no-unused-vars
  const [selectedDate, setSelectedDate] = useState(new Date());



  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));

      load(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));

      load(addWeeks(currentMonth, 1));
    }


  };



  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    // console.log("selected day", selectedDate);
    console.log(currentMonth > new Date(minDate));
    return (
      <div className={`header row ${header_bg} p-1`}>
        <div className="col">
          <div >
            {
              Number(currentWeek) > Number(minWeek) || currentMonth > new Date(minDate) ? <Button disabled={!show} variant="outline-light" size="sm" onClick={() => changeWeekHandle("prev")}><i className="fa fa-angle-double-left fa-2x" ></i></Button> : null
            }

          </div>
        </div>
        <div className="col text-center pt-2">
          <h6>{format(currentMonth, dateFormat)} W-{currentWeek} </h6>
        </div>
        <div className="col text-end">
          <div>
            {
              <Button disabled={!show} variant="outline-light" size="sm" onClick={() => changeWeekHandle("next")}><i className="fa fa-angle-double-right fa-2x"  ></i></Button>
            }
          </div>
        </div>
      </div>
    );
  };


  const dbFormat = "yyyy-MM-dd";
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {

      for (let i = 0; i < 7; i++) {

        if (new Date() >= day) {
          formattedDate = format(day, dateFormat);
          const cloneDay = day;
          const manday = mandays[format(day, "yyyy-MM-dd")];
          // const selectCal = selectedProject[format(cloneDay, "yyyy-MM-dd")] ?? {};
          // console.log(format(day, "yyyy-MM-dd") + " " + manday + " " + Object.keys(mandays))
          days.push(
            <div
              className={`col cell ${isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                  ? "selected"
                  : ""
                } ${new Date() < day ? "invisible" : ""}`}
              key={day}

            // onClick={() => {
            //   setSelectedProject({ ...selectedProject, [format(cloneDay, "yyyy-MM-dd")]: { ...selectCal, "selected": selectCal.selected && selectCal.selected === 'Y' ? 'N' : 'Y' } })
            // }}
            >
              <span className="number">{formattedDate}</span>

              {(manday && manday.projectid && manday.absent === 'N') ? null :
                <span><i className={manday && manday.absent === 'Y' ? 'fa fa-user-times text-danger' : 'fa fa-user-o text-primary'} role="button"
                  onClick={() => {
                    if (manday && manday.absent === 'Y') {
                      deleteEntry(manday.id);
                    }
                    else {
                      markAsAbsent(format(cloneDay, dbFormat))
                    }
                  }}
                  title={manday && manday.absent === 'Y' ? "Mark as present" : "Mark as absent"}></i> </span>}
              <span className="day text-secondary">{format(day, "EEE")}</span>
              <span className="bg">{formattedDate}</span>


              {
                manday && manday.absent==='Y' ? <span class="d-flex"><i class="fa fa-ban text-muted fa-3x m-auto"></i></span>:
                  <React.Fragment>
                    {
                      manday && manday.projectid && !updatePermission ? (
                        <p className="m-0 fw-bold"> manday.projectname ?? ""</p>

                      ) : (

                        multiselect || (manday && manday.paidstatus === 'A') ?
                          <p className="m-0 fw-bold">
                            {manday && manday.projectname ? (manday.projectname ?? "") :
                              (selectedProject[format(cloneDay, "yyyy-MM-dd")] ? selectedProject[format(cloneDay, "yyyy-MM-dd")].name : "--")}
                          </p> :
                          <select name="projectSelect" disabled={!show} className="form-select position-relative mb-2"
                            placeholder="Project Name" style={{ zIndex: 9999, width: "75%" }}
                            value={selectedProject[format(cloneDay, dbFormat)] && selectedProject[format(cloneDay, dbFormat)].id ? (selectedProject[format(cloneDay, dbFormat)].id)
                              : (manday && manday.projectid ? manday.projectid : "0")
                            }
                            onChange={e => {
                              var index = e.target.selectedIndex;

                              setSelectedProject({ ...selectedProject, [format(cloneDay, dbFormat)]: { id: e.target.value, name: e.target.options[index].text, base: ((manday?.base) ? manday.base : selectedLabourWage ?? 0), factor: 1 } })
                            }
                            }
                          >
                            <option value="0">Select Project</option>
                            {
                              projects ? filterProjects(projects, costcenter).map((e, i) => {
                                return <option key={`pro_${i}`} value={e.id}>{e.name}</option>
                              }) : null
                            }
                          </select>)

                    }



                    <div className="d-flex">
                      {
                        show ?
                          (manday && manday.paid > 0 ?
                            <span className={`pay fw-bold ${manday && manday.paidstatus === 'A' ? "text-success" : "text-danger"}`} >
                              {manday.paidstatus === 'P' ?
                                `${manday.wage ?? "0.00"} / ${manday.paid ?? "0.00"}` :
                                ` ${manday.paid ?? "0.00"}  `
                              }
                            </span> :
                            (<React.Fragment>
                              <span className='pay'>{(selectedLabourWage ?? 0)} </span>
                              <span className="text-muted fa ps-1">{" * "} </span>
                              <input type="number" name="manday"
                                disabled={(!selectedProject[format(cloneDay, dbFormat)] || !selectedProject[format(cloneDay, dbFormat)].id) && (!manday || !manday.projectid)}
                                value={selectedProject[format(cloneDay, dbFormat)] && (selectedProject[format(cloneDay, dbFormat)].factor || selectedProject[format(cloneDay, dbFormat)].factor === 0) ? selectedProject[format(cloneDay, dbFormat)].factor
                                  : manday?.factor ?? ""
                                }
                                min={0} max={5} step={.5}
                                className="form-control p-0 ms-2 ps-1" style={{ height: "30px", width: "15%" }}
                                onChange={e => {
                                  setSelectedProject({
                                    ...selectedProject, [format(cloneDay, dbFormat)]: {
                                      ...selectedProject[format(cloneDay, dbFormat)], factor: e.target.value
                                    }
                                  })
                                }}
                              />

                              <span className="text-muted fa ps-1">{" = "} </span>
                              <span className='pay'>{(selectedLabourWage ?? 0) * (selectedProject[format(cloneDay, dbFormat)] && selectedProject[format(cloneDay, dbFormat)].factor ? selectedProject[format(cloneDay, dbFormat)].factor
                                : manday?.factor ?? 0)}</span>
                            </React.Fragment>
                            )
                          ) : null
                      }
                      {(manday && manday.absent!=='Y' && manday.paidstatus==='P') ? <span class="ms-auto"><i className="fa fa-trash text-danger" role="button" onClick={()=>{deleteEntry(manday.id)}}></i></span> : null}
                    </div>
                  </React.Fragment>
              }




              {/* <i className={`fa check text-secondary ${selectCal.selected && selectCal.selected ==='Y' ? "fa-check-square-o":"fa-square-o"}`}></i> */}

            </div>
          );

        }

        day = addDays(day, 1);

      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];

    }
    return <div className="body">{rows}</div>;
  };


  return (
    <div className="calendar-header calendar">
      {mandays ? renderHeader() : null}
      {/* {renderDays()} */}
      {mandays ? renderCells() : null}
      {/* {renderFooter()} */}
    </div>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
