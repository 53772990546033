import { LOADALLPROJECT,
    LOADPROJECT,
    RESETPROJECT,
    CHANGEPROJECTFIELD,SET_NOTIFICATION, LOADPROJECTLOOKUPS, RESETPROJECTS} from "../constant/constant";
import {pAxios} from "..";



function loadAllProjectService(status,unallocated){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/project?status=${status}${unallocated?"&unallocated="+unallocated:""}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLPROJECT, project: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load project data",variant:"danger",err}  })
            });
    });

}

function loadProjectService(id){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/project/${id}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADPROJECT, project: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load project data",variant:"danger",err}  })
          });
  });

}


export function saveProjectService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/project`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
            // type: SET_NOTIFICATION, notification:
                resolve({ message: `Project ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETPROJECT});
         }
      })
          .catch(err => {
              
              reject({ message:"Failed to save project data",variant:"danger",err}  )
          });
  });

}


export function loadProjectLookUpService(){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/project?attributes=id,name,clientid,status&skippage=true`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADPROJECTLOOKUPS, project: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load project lookup",variant:"danger",err}  })
            });
    });
  
  }



 export const loadAllProject=(status,unallocated)=>{

    return dispatch => {

        loadAllProjectService(status,unallocated)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadProject=(id)=>{

  return dispatch => {

      loadProjectService(id)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

  
  

export const changeProject=({name,value,model})=>{

  return dispatch => {
    dispatch({type:CHANGEPROJECTFIELD,name,value,model});
}
  

}

export const resetProject=()=>{

  return dispatch => {
    dispatch({type:RESETPROJECT,});
}
  
}

export const resetProjects=()=>{

    return dispatch => {
      dispatch({type:RESETPROJECTS,});
  }
    
  }

// export const saveProject=(project)=>{

//   return dispatch => {
//     saveProjectService(project,"POST")
//     .then(response => dispatch(response))
//     .catch(err => dispatch(err));
// }
  
// }


// export const updateProject=(project)=>{

//     return dispatch => {
//       saveProjectService(project,"PATCH")
//       .then(response => dispatch(response))
//       .catch(err => dispatch(err));
//   }
    
//   }

  
export const loadProjectLookup=()=>{

    return dispatch => {
  
        loadProjectLookUpService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }


