import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Form, Card, Col, FloatingLabel, } from "react-bootstrap";


import RTable from "./Table"

import DatePicker from 'react-date-picker'
import { changePayment, deletepaymentService, loadAllPayment, loadPayment, resetPayment, savePaymentService } from "../action/paymentAction";
import { format, startOfMonth, subDays } from "date-fns";
import { Confirm } from "./Confirm";
import { notification } from "../action/notificationAction";
import { Typeahead } from "react-bootstrap-typeahead";
import { savePendingService } from "../action/reportAction";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";
import { filterClient, filterProjects } from "../constant/filterTypeahead";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';



export default function Received({minDate, permitted }) {

  const dispatch = useDispatch();
  const cltEntryTypeRef = React.useRef(null);
  const proEntryTypeRef = React.useRef(null);

  const [startDate, setStartDate] = useState(minDate ? new Date(minDate) : subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedPayment, setSelectedPayment] = useState({});
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('active');
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null });


  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`RECEIVABLE_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`RECEIVABLE_UPDATE`));
  // eslint-disable-next-line no-unused-vars
  const [deletePermission, setDeletePermission] = React.useState(permitted(`RECEIVABLE_DELETE`));



  const onChange = (dates) => {

    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }

  };


  const payments = useSelector(
    (state) => state.payment.payments
  );

  const payment = useSelector(
    (state) => state.payment.payment
  );

  const client = useSelector(
    (state) => state.lookup.client
  );


  const project = useSelector(
    (state) => state.lookup.project
  );


  const lookup = useSelector(
    (state) => state.lookup.category
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );


  const handleClose = (skipLoadPayments) => {
    setShow(false);
    setSelectedPayment(null);
    setErrors({});
    if(!skipLoadPayments){
      load();
    }
    dispatch(resetPayment());
  }



  const handleShow = (selected) => {
    setShow(true);
    if (selected && selected.original.id) {
      setSelectedPayment(selected.original.id);
      dispatch(loadPayment(selected.original.id));
    }
    else {
      setSelectedPayment({});
      dispatch(resetPayment());
      setField("date", format(new Date(), "yyyy-MM-dd"));
    }
  }



  const deletePayRequestOrder = (confirm, selected, remarks) => {

    if (confirm) {
      if (deletePermission) {

        deletepaymentService(selected.id, 'Received').then(res => {
          dispatch(notification(res));
          load();
        }).catch(err => {
          dispatch(notification(err))
        })
      }
      else {

        savePendingService({
          table: 'Payment',
          pk_id: selected.id,
          type: 'Payment Delete',
          amount: selected.amount,
          sub: 'name',
          name: selected.name,
          project: selected.projectid,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          remarks

        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })
      }


    }

    setShowConfirm({ show: false, callBackData: null });
  }


  const 
  columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        
      },
      {
        Header: 'Client',
        accessor: 'dname',
        id: 'client',
        textFilter:true
      },
      {
        Header: 'Project',
        accessor: 'projectname',
      },
      {
        Header: 'Date',
        accessor: (cell) => format(new Date(cell['date']), "dd-MM-yyyy"),
        className:'w-100p'
      },
      {
        Header: 'Amount',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Status',
        accessor: (cell) => {
          return cell.approved_status === 'Y' ? 'Approved' : cell.auth_status === 'Y' ? 'Processed' : 'Pending'
        }
      }, {
        Header: "Action",
        id: 'action',
        show: status === 'active',
        hideFilter: true,
        accessor: (cell) => {
          return (
            <div className="text-center">
              {<Button size="sm" variant="outline-danger" className="pt-0 pb-0" title="Delete Receivable Request" onClick={() => { setShowConfirm({ show: true, callBackData: cell }); }}> <i className="fa fa-trash" role="button"></i></Button>}
            </div>)
        }
      }],
    [status]
  )


  const load = () => {

    if (startDate && endDate) {
      dispatch(loadAllPayment({ status, startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd"), type: 'RECEIVED', costcenter }))
    }

  }

  const setField = (field, value, idx) => {
    dispatch(changePayment({ name: field, value, idx }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let dataToPost = {};
      let method = "POST";

      if (!payment.id) {
        dataToPost = {
          ...payment,
          type: 'RECEIVED',
          paymenton: 'CLIENT',
          basic: payment.gross,
          screen_name: 'Received'
        };
      }
      else {
        dataToPost = {
          ...payment, basic: payment.gross, screen_name: 'received'
        };
        method = "PATCH"
      }

      savePaymentService(dataToPost, method).then(res => {

        dispatch(resetPayment());
        dispatch(notification(res));
        handleClose();
      })
        .catch(err => {
          dispatch(notification(err))
        })

    }

  }

  const findFormErrors = () => {

    const { clientid, projectid, date, gross, mode } = payment
    const newErrors = {}

    if (!date || date === '') newErrors.date = 'date cannot be blank!';

    if (!clientid || clientid === "0") newErrors.client = 'Select a client!'

    if (!projectid || projectid === "0") newErrors.project = 'Select a project!'

    if (!mode || mode === "0") newErrors.project = 'Select a project!'

    if (!gross || gross === "") newErrors.amount = 'Amount cannot be blank!'

    return newErrors
  }


  React.useEffect(() => {
    load();
    handleClose(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, costcenter]);

  React.useEffect(()=>{
    dispatch(setEnableMobileMenu(false));
  },[dispatch]);

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      {
        !show ? (

          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>  

                <h6 className="flex-fill text-start">Received</h6>
                {createPermission ?
                  <div >
                    {createPermission ? <Button variant="success" size="sm" onClick={() => handleShow()}>
                      <i className="fa fa-plus d-md-none d-sm-inline"></i>
                      <p className="d-none d-md-inline">Add New</p>
                    </Button> : null}


                  </div> : null}

              </Card.Header>
              <Card.Body>
                <Row className="mb-3 g-2">
                  <Col sm={12} md={3} >
                    <div style={{ position: "relative" }} className="dp">
                      <DateRangePicker maxDate={new Date()}
                        
                        format="dd-MM-yyyy"
                        
                        onChange={onChange}  
                        value = {[startDate, endDate]}
                        calendarIcon={<i className="fa fa-calendar"></i>}
                        // clearIcon = {null}
                        className="form-control"
                        
                        id="paymentDaterangeFilter"
                      ></DateRangePicker>
                      <label className="black-text" htmlFor="paymentDaterangeFilter">Received Date</label>
                    </div>
                  </Col>


                  <Col>
                    {
                      !show ? (<div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">


                        <Button variant="primary" size="sm" onClick={load}>Search</Button>
                      </div>) : ""}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <RTable columns={columns} data={payments} title="Received Payment" status={status}
              changeStatus={setStatus} hideColumn={status === 'active' ? [] : ['action']}
              edit={handleShow} >



            </RTable>
          </Container>) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="flex-fill text-start">Payment Received </h6>
                <div >

                  {((payment.id && updatePermission && payment.auth_status === 'N') || (!payment.id && createPermission)) ?
                    <Button variant="primary" size="sm" onClick={handleSubmit} className="me-2"
                      title={payment.id ? "Update" : "Save"}>

                      <i className="fa fa-save d-md-none d-sm-inline"></i>
                      <p className="d-none d-md-inline"> {payment.id ? "Update" : "Save"}</p>
                    </Button>
                    : null}

                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p>
                  </Button>


                </div>
              </Card.Header>
              <Card.Body>

                <Row >

                  <Card className="col-sm-12 col-md-6 border-0 mb-0">
                    <Card.Body className="pt-0 pb-0">
                      <Col className="mb-3">

                        <Typeahead
                          id="floating-label-clt"
                          onChange={(e) => {
                            setField('clientid', e[0]?.id);
                            setField('name', e[0]?.name);
                            setField('projectid', null);
                          }}
                          clearButton
                          onBlur={() => {
                            if (!payment.clientid) {
                              cltEntryTypeRef.current?.clear();
                              proEntryTypeRef.current?.clear();
                              setField('projectid', null);
                            }
                          }}
                          labelKey="name"
                          options={filterClient(client, costcenter)}
                          ref={cltEntryTypeRef}
                          placeholder="Choose a client"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingClient" label="Select a client">
                                <Form.Control
                                  isInvalid={!!errors.client}
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.client} </Form.Control.Feedback>
                              </FloatingLabel>

                            );
                          }}
                          selected={payment.clientid ? client.filter(e => e.id === Number(payment.clientid)) : []}
                        />


                      </Col>
                      <Col className="mb-3">

                        <Typeahead
                          id="floating-label-pro"
                          onChange={(e) => {
                            setField('projectid', e[0]?.id)
                          }}
                          clearButton
                          labelKey="name"
                          onBlur={() => {
                            if (!payment.projectid) {
                              proEntryTypeRef.current?.clear();
                            }
                          }}
                          options={project && payment.clientid ? filterProjects(project, costcenter).filter(e => Number(e.clientid) === Number(payment.clientid)) : filterProjects(project, costcenter)}

                          ref={proEntryTypeRef}

                          placeholder="Select a project"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingProject" label="Select a project">
                                <Form.Control isInvalid={!!errors.project}
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                              </FloatingLabel>

                            );
                          }}
                          selected={payment.projectid ? project.filter(e => e.id === Number(payment.projectid)) : []}
                        />


                      </Col>
                      <Col className="mb-3">

                        <div style={{ position: "relative" }} className="dp">
                          <DatePicker maxDate={new Date()} 
                            minDate={minDate? new Date(minDate): null}
                            id="paymentdate"
                            value={payment.date ? new Date(payment.date) : ""}
                            onChange={(date) => setField("date", format(date, "yyyy-MM-dd"))}


                            className={`form-control ${!!errors.date ? "is-invalid" : ""}`}

                            format="dd-MM-yyyy"
                            calendarIcon={<i className="fa fa-calendar"></i>}
                            clearIcon = {null}
                          ></DatePicker>
                          <label className="black-text" htmlFor="paymentdate" > Date</label>
                        </div>

                      </Col>


                    </Card.Body>

                  </Card>
                  <Card className="col-sm-12 col-md-6 border-0">
                    <Card.Body className="pt-0 pb-0" >

                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingAmount" label="Amount">
                          <Form.Control type="text" placeholder="Amount" isInvalid={!!errors.amount} value={payment.gross ?? ""} onChange={e => setField("gross", e.target.value)} />
                          <Form.Control.Feedback type='invalid'>{errors.amount} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingPayMode" label="Payment Mode">
                          <Form.Select id="floatingPayMode" aria-label="Payment Mode" isInvalid={!!errors.mode} value={payment.mode ?? "0"} onChange={e => setField("mode", e.target.value)}>
                            <option value="0">Select Mode</option>
                            {
                              lookup && lookup.PAYMENT_MODE ? lookup.PAYMENT_MODE.map((e, i) => {
                                return <option value={e.id} key={`mode_${i}`}>{e.name}</option>
                              }) : null
                            }
                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.modw} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingReference" label="Remarks">
                          <Form.Control as="textarea" placeholder="Remarks" value={payment.remarks ?? ""} onChange={e => setField("remarks", e.target.value)} />

                        </FloatingLabel>
                      </Col>


                    </Card.Body>
                  </Card>
                </Row>
              </Card.Body>
            </Card>
          </Container>)
      }
      <Confirm show={showConfirm} confirmation={deletePermission ? "Are you sure?" : "You are not allowed to delete, however this will be created as a requested to elevated users!"} confirmCallBack={deletePayRequestOrder} showRemarks={!deletePermission}></Confirm>
    </React.Fragment >);
}

// }
