import React, { Component } from "react";
import '../assets/table.css';
import {Button} from "react-bootstrap";
 

export default class TablePagination extends Component {
  render() {
    return (
        <div
          className={this.props.className ? this.props.className : " bg-secondary"}
        >
          <div className="-pagination mb-3">
            <div className="-previous">
              <Button
                size="sm"
                className={`float-start h-100 w-100 ${this.props.btn}`}
                onClick={() => this.props.previousPage()}
                disabled={!this.props.canPreviousPage}
              >
                Previous
              </Button>
            </div>
            <div className="-center">
              <span className="-pageInfo">
                Page {" "}
                <div className="-pageJump">
                  <input
                    type="number"
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      this.props.gotoPage(page);
                    }}
                    value={this.props.pageIndex + 1}
                  />
                </div>{""}
                of{" "}
                <span className="-totalPages">
                  {this.props.pageOptions.length}
                </span>
              </span>
              
            </div>
            <div className="-next">
              <Button
              className={`float-end h-100 w-100 ${this.props.btn}`}
                onClick={() => this.props.nextPage()}
                disabled={!this.props.canNextPage}
                size="sm"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
    );
  }
}
