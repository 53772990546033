/* eslint-disable jsx-a11y/heading-has-content */

import React, { Fragment } from 'react';
import { Suspense, lazy } from 'react';
import AppHeader from './AppHeader'

import cx from 'classnames';


import { useDispatch, useSelector } from "react-redux";
import { history } from "../../app/history";
import { Container } from "react-bootstrap"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import User from "../User";
import Client from "../Client";
import { loadUserWallet, resetAuth } from "../../action/loginAction";
import { menus } from "../../constant/menu";
import { loadUserLookup } from "../../action/userAction";
//import {isAuthenticated} from "../constant/resetLocal".
import { loginUser } from "../../action/loginAction";


import brand from "../../assets/brand.jpg"

import { loadAllLookup } from "../../action/lookupAction";
import { loadClientLookup } from "../../action/clientAction";
import { loadProjectLookup } from "../../action/projectAction";
import { loadLabourLookup } from "../../action/labourAction";
import { loadVendorLookup } from "../../action/vendorAction";
import { isObjectEmpty } from "../../util/util";
import Tds from "../Tds";
import Summary from "../Summary";
import Statement from "../Statement";
import Allocation from "../Allocation";
import Bill from "../BillReport";
import Chat from "../Chat";
// import Dashboard from "../Dashboard";
import Role from "../Role";
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from './AppLogo';
import MetisMenu from 'react-metismenu';
import LinkContainer from './AppNav/LinkContainer';

import {
    setEnableMobileMenu
} from '../../constant/constant';

import { setHeaderBackgroundColor, setBackgroundColor } from '../../constant/constant';
import { loadAllConfig } from "../../action/categoryAction";
import LabourReport from '../LabourReport';
import VendorContact from '../VendorContact';
import CreditNote from '../CreditNote';
import DetailedSummary from '../DetailedSummary';

const Salary = React.lazy(() => import('../Salary'));
const Vendor = React.lazy(() => import('../Vendor'));
const Labour = React.lazy(() => import("../Labour"));
const Category = React.lazy(() => import("../Category"));
const Project = React.lazy(() => import("../Project"));
const PurchaseOrder = React.lazy(() => import("../PurchaseOrder"));
const Invoice = React.lazy(() => import("../Invoice"));
const Payment = React.lazy(() => import("../Payment"));
const LabourManday = React.lazy(() => import("../LabourManday"));
const MandayPayment = React.lazy(() => import("../MandayPayment"));
const Received = React.lazy(() => import("../Received"));
const Expense = React.lazy(() => import("../Expense"));
const Materail = React.lazy(() => import("../Material"));
const Activity = React.lazy(() => import("../Activity"));
const Config = React.lazy(() => import('../Config'));
const Accesslog = React.lazy(() => import('../Accesslog'));
const Dash = React.lazy(() => {
    return new Promise(resolve => setTimeout(resolve, 300)).then(
        () =>
            import("../Dashboard")
    );
});

const Drive = React.lazy(() => import('../FileExplorer'));

export function AppMain() {


    const dispatch = useDispatch();


    const auth_user = useSelector(
        (state) => state.auth_user.auth_user
    );

    const theme = useSelector((state) => state.ThemeOptions);


    const wallet = useSelector(
        (state) => state.wallet.wallet
    );


    const config = useSelector(
        (state) => state.config.config
    );

    const [newMenu, setNewMenu] = React.useState({});

    const [showMenu, setShowMenu] = React.useState(false);
    const [render, setRender] = React.useState(false);
    const [minDate, setMinDate] = React.useState(null);
    const [permissions, setPermissions] = React.useState(null);


    const isPermitted = (name, perms) => {

        // return true;
        if (auth_user) {

            return perms.some(user => user === name.toUpperCase());
        }

        return false;
    }



    React.useEffect(() => {
        history.listen(location => { console.log(location.pathname) });

        return history.listen(location => {
            console.log(location.pathname)
            if (history.action === 'POP') {

                history.push("/")
            }
        });

    }, [])

    const loadLookups = () => {

        dispatch(loadAllLookup());
        dispatch(loadUserLookup());
        dispatch(loadClientLookup());
        dispatch(loadProjectLookup());
        dispatch(loadLabourLookup("id,name,wage,total"));
        dispatch(loadVendorLookup("id,name,category"));
        dispatch(loadUserWallet());

    }


    React.useEffect(() => {

        let timer = null;

        if (!auth_user) {

            dispatch(loginUser());
        }

        if (auth_user?.permissions) {

            dispatch(loadAllConfig());
            setPermissions(auth_user.permissions);
            let menu = {};

            Object.keys(menus).forEach((k) => {

                let sub_menu = menus[k].filter((m) => !m.name || isPermitted(m.name, auth_user.permissions));

                if (sub_menu.length > 0) {

                    menu = { ...menu, [k]: sub_menu };
                }
            });
            setNewMenu({ ...menu });

            timer = setTimeout(() => {
                loadLookups();
            }, 300);

            console.log("loaded");


        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth_user, dispatch]);

    React.useEffect(() => {
        return () => {
            setRender(false)
            dispatch(resetAuth())
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (config) {
            let min = config.find(e => e.config_name === "Freeze_to_date");
            if (min) {
                setMinDate(min.config_value);
            }

            let backgroundColor = config.find(e => e.config_name === "backgroundColor");
            if (backgroundColor) {
                dispatch(setBackgroundColor(backgroundColor.config_value));
                setRender(true);
            }

            let headerBackgroundColor = config.find(e => e.config_name === "headerBackgroundColor");
            if (headerBackgroundColor) {
                dispatch(setHeaderBackgroundColor(headerBackgroundColor.config_value));
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    const permitted = (name) => {

        if (auth_user) {
            
            return permissions.some(user => user === name.toUpperCase());
        }

        return false;
    }

    // eslint-disable-next-line no-unused-vars
    async function auth() {
        // let auth = await isAuthenticated();

        // setIsAuth(auth);
    }

    // eslint-disable-next-line no-unused-vars
    const hideMenu = () => {
        setShowMenu(true);
    }

    const toggleMobileSidebar = () => {

        dispatch(setEnableMobileMenu(!theme.enableMobileMenu));
    }
    const icon = {
        "Create": "fa fa-user-plus",
        "PO": "fa fa-inbox",
        "Invoice": "fa fa-get-pocket",
        "Requisitions": "fa fa-pencil-square-o",
        "Payment": "fa fa-money",
        "Report": "fa fa-file-excel-o",
        "Chat": "fa fa-whatsapp"


    }


    return (render ?
        <Fragment>
            <div className={cx(
                "app-container app-theme-" + theme.colorScheme,
                { 'fixed-header': theme.enableFixedHeader },
                { 'fixed-sidebar': theme.enableFixedSidebar },

                { 'closed-sidebar': theme.enableClosedSidebar },
                { 'closed-sidebar-mobile': theme.closedSmallerSidebar },
                { 'sidebar-mobile-open': theme.enableMobileMenu },
            )}>
                <Router  >
                    <AppHeader username={auth_user?.name} wallet={wallet} newMenu={newMenu} />
                    <div className="app-main">


                        <div className="sidebar-mobile-overlay" />
                        <div className={cx("app-sidebar", theme.backgroundColor, { 'sidebar-shadow': theme.enableSidebarShadow })}>
                            <HeaderLogo />
                            <PerfectScrollbar>
                                <div className="app-sidebar__inner">
                                    <h5 className="app-sidebar__heading"></h5>
                                    <MetisMenu label="Home"
                                        content={
                                            [{
                                                icon: "fa fa-home",
                                                label: "Home",
                                                to: "/home"
                                            }]

                                        }
                                        LinkComponent={LinkContainer}
                                        activeLinkFromLocation
                                        className="vertical-nav-menu"
                                        iconNamePrefix="" classNameStateIcon="fa fa-angle-down"
                                        onSelected={() => {

                                            toggleMobileSidebar()
                                        }}
                                    />
                                    {
                                        newMenu ? Object.keys(newMenu).filter((k) => newMenu[k].length > 0).map((k, i) => {

                                            return (
                                                <Fragment key={`${i}-menu`}>
                                                    <h5 className="app-sidebar__heading"></h5>
                                                    <MetisMenu content={
                                                        [{
                                                            icon: icon[k],
                                                            label: k,
                                                            content: [...newMenu[k].map((m, j) => {
                                                                return {
                                                                    label: m.label,
                                                                    to: m.link,
                                                                }
                                                            })]
                                                        }]
                                                    }
                                                        LinkComponent={LinkContainer}
                                                        activeLinkFromLocation
                                                        className="vertical-nav-menu"
                                                        iconNamePrefix="" classNameStateIcon="fa fa-angle-down"
                                                        onSelected={() => {
                                                            console.log("clicked");
                                                            toggleMobileSidebar()
                                                        }}
                                                    />
                                                </Fragment>

                                            );

                                        }) : null
                                    }

                                </div>
                            </PerfectScrollbar>
                            <div
                                className={cx("app-sidebar-bg", theme.backgroundImageOpacity)}
                                style={{
                                    backgroundImage: theme.enableBackgroundImage ? 'url(' + theme.backgroundImage + ')' : null
                                }}>
                            </div>
                        </div>
                        <div className="app-main__outer">
                            <div className="app-main__inner p-0" >
                                {auth_user && !isObjectEmpty(auth_user) && permissions ? (

                                    <Container fluid className="p-0" style={{ zIndex: 999, minHeight: "89vh", overflow: 'auto' }}>

                                        <Suspense fallback={<div>Loading...</div>}>
                                            {/* <PerfectScrollbar> */}
                                            <Switch>
                                                <Route exact path="/master/user" ><User permitted={permitted}></User></Route>
                                                <Route exact path="/master/client"><Client permitted={permitted}></Client> </Route>
                                                <Route exact path="/master/vendor" > <Vendor permitted={permitted}></Vendor> </Route>
                                                <Route exact path="/master/labour"><Labour permitted={permitted}></Labour> </Route>
                                                <Route exact path="/master/category" ><Category permitted={permitted}></Category> </Route>
                                                <Route exact path="/master/project"> <Project permitted={permitted}></Project> </Route>
                                                <Route exact path="/master/material"> <Materail permitted={permitted}></Materail> </Route>
                                                <Route exact path="/master/activity" > <Activity permitted={permitted}></Activity> </Route>

                                                <Route exact path="/po/client"  ><PurchaseOrder entity="Client" permitted={permitted} minDate={minDate}></PurchaseOrder></Route>
                                                <Route exact path="/po/vendor"  ><PurchaseOrder entity="Vendor" permitted={permitted} minDate={minDate}></PurchaseOrder></Route>
                                                <Route exact path="/invoice/client"  ><Invoice entity="Client" permitted={permitted} minDate={minDate}></Invoice></Route>
                                                <Route exact path="/invoice/vendor"  ><Invoice entity="Vendor" permitted={permitted} minDate={minDate}></Invoice></Route>

                                                <Route exact path="/req/payment"  ><Payment mode="Requested" permitted={permitted} minDate={minDate}></Payment></Route>
                                                <Route exact path="/req/manday"> <LabourManday permitted={permitted} minDate={minDate} /> </Route>

                                                <Route exact path="/payment/make"  ><Payment mode="Process" permitted={permitted} minDate={minDate}></Payment></Route>
                                                <Route exact path="/payment/mandays" ><MandayPayment permitted={permitted} minDate={minDate} /> </Route>
                                                <Route exact path="/payment/received"><Received permitted={permitted} minDate={minDate} /> </Route>
                                                <Route exact path="/payment/expense"><Expense permitted={permitted} minDate={minDate} /> </Route>

                                                <Route exact path="/tds"><Tds permitted={permitted} minDate={minDate} /> </Route>
                                                <Route exact path="/report/summary"><Summary permitted={permitted} /> </Route>
                                                <Route exact path="/report"><Statement permitted={permitted} minDate={minDate}/> </Route>
                                                <Route exact path="/payment/allocation"><Allocation permitted={permitted} /> </Route>
                                                <Route exact path="/report/bill"><Bill permitted={permitted} /> </Route>
                                                <Route exact path="/chat"><Chat permitted={permitted} /> </Route>
                                                <Route exact path="/master/role"><Role permitted={permitted} /> </Route>
                                                <Route exact path="/master/config"><Config permitted={permitted} /> </Route>
                                                <Route exact path="/report/access"><Accesslog permitted={permitted} /> </Route>
                                                <Route exact path="/salary"><Salary permitted={permitted} /> </Route>
                                                <Route exact path="/report/labour"><LabourReport permitted={permitted} /> </Route>
                                                <Route exact path="/file"><Drive></Drive></Route>
                                                <Route exact path="/report/detailsummary" permitted={permitted}> <DetailedSummary></DetailedSummary></Route>
                                                <Route exact path="/master/contact"><VendorContact permitted={permitted}></VendorContact></Route>
                                                <Route exact path="/payment/creditnote"><CreditNote permitted={permitted}></CreditNote></Route>
                                                <Route><Dash permitted={permitted} /></Route>
                                                {/* <Route path="/" component={LandingPage} /> */}
                                            </Switch>
                                            {/* </PerfectScrollbar> */}
                                        </Suspense>
                                    </Container>
                                ) : null
                                }
                            </div>

                        </div>
                    </div>
                </Router>
            </div>
        </Fragment>
        : null
    )
};