import React, { useState } from "react";
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from "react-redux";

import { Container, Form, Card, Row, Col, Button, FloatingLabel, Image } from "react-bootstrap";
import Table from "./Table";
import { format, set, subDays } from "date-fns";
import { approveExpenseService, changeExpense, deleteExpenseService, loadAllExpense, loadExpense, resetExpense, saveExpenseService } from "../action/expenseAction";

import { Confirm } from "./Confirm";
import { notification } from "../action/notificationAction";
import { numberCheck } from "../constant/numberCheck";
// import { loadAllUser,  getUserPassword } from "../action/userAction";
import { Typeahead } from "react-bootstrap-typeahead";
import { loadUserWallet } from "../action/loginAction";
import { savePendingService } from "../action/reportAction";
import {
  LOADING,
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";

import imageCompression from "browser-image-compression";
import { filterLabour, filterProjects, filterProjectsSearch } from "../constant/filterTypeahead";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { pAxios } from "..";
import fileDownload from 'js-file-download'

export default function Expense({ permitted, minDate, dShow, dShowId, dback }) {

  const dispatch = useDispatch();

  const proTypeRef = React.useRef(null);
  const proEntryTypeRef = React.useRef(null);
  const labourEntryTypeRef = React.useRef(null);
  const venEntryTypeRef = React.useRef(null);

  const matEntryTypeRef = React.useRef(null);
  const actEntryTypeRef = React.useRef(null);

  const userTypeRef = React.useRef(null);

  const projects = useSelector(
    (state) => state.lookup.project
  );

  const vendors = useSelector(
    (state) => state.lookup.vendor
  );

  const labours = useSelector(
    (state) => state.lookup.labour
  );


  const lookup = useSelector(
    (state) => state.lookup.category
  );

  const costcenters = useSelector(
    (state) => state.costcenter.costcenters
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );


  // const material = useSelector(
  //   (state) => state.lookup.material
  // );


  // const activity = useSelector(
  //   (state) => state.lookup.activity
  // );

  const categories = useSelector(
    (state) => state.lookup.category
  );


  const users = useSelector(
    (state) => state.lookup.user
  );



  const payment_mode = ["CASH", "G-PAY", "NEFT", "IMPS", "OTHERS"];

  const expenses = useSelector(
    (state) => state.expense.expenses
  );

  const expense = useSelector(
    (state) => state.expense.expense
  );


  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [generalExp, setGeneralExp] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [seletedExpense, setSelectedExpense] = useState(null);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null });
  const [pageCount, setPageCount] = React.useState(0);
  const [status, setStatus] = useState("active");
  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`EXPENSE_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`EXPENSE_UPDATE`));
  // eslint-disable-next-line no-unused-vars
  const [reversePermission, setReversePermission] = React.useState(permitted(`EXPENSE_REVERSE`));
  // eslint-disable-next-line no-unused-vars
  const [ownPermission, setOwnPermission] = React.useState(permitted("EXPENSE_OWN"));
  const [approvePermission, setApprovePermission] = React.useState(permitted(`EXPENSE_APPROVE`));
  
  // const [selectedFiles, setSelectedFiles] = useState([]);

  const onChange = (dates) => {

    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }

  };

  const handleShow = (selected) => {

    if (selected && selected.original.id) {

      setShow(true);
      setSelectedExpense(selected.original);
      dispatch(loadExpense(selected.original.id));
    }
    else {
      setSelectedExpense(null);
      setShow(true);
      setField("date", format(new Date(), "yyyy-MM-dd"));
    }

  }

  const handleClose = () => {

    dispatch(resetExpense());

    if (dback) {
      dback();
    }
    else {

      // load();
      setSelectedExpense(null);
      setShow(false);
      setErrors({});
    }
  }


  React.useEffect(() => {
    if (dShow && dShowId) {
      handleShow({ original: { id: dShowId } });
    }
    else {
      load();
    }
    setSelectedProjectId(null);
    setSelectedUser(null);
    proTypeRef?.current?.clear();
    proEntryTypeRef?.current?.clear();
    labourEntryTypeRef?.current?.clear();
    if (expense) {
      setField("projectid", null);
      setField("labourid", null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, costcenter, generalExp, status ]);


  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const column = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        
      },
      {
        Header: 'Unique Id',
        accessor:'id',
        id:'unique'
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        textFilter: true
      },
      {
        Header: 'Project',
        accessor: 'projectname',
        id: 'projectname',
      },
      {
        Header: 'Date',
        accessor: (cell) => cell['date'] ? format(new Date(cell['date']), "dd-MM-yyyy") : ''
      }, {
        Header: 'Category',
        accessor: 'categoryname',
      },
      {
        Header: 'Spended On',
        accessor: (cell) => {
          if (cell['type'] === 'GENERAL')
            return "GENERAL"
          return cell['expenseon']
        },
        id: 'expenseon'
      },
      {
        Header: 'Amount',
        accessor: (cell) => formatCurrency(cell['amount']),
        className: 'text-end',
        hideFilter: true
      },{
        Header: 'Status',
        accessor: (cell) => cell['approved_status']==='Y' ? 'Approved' : 'Pending',
        className: 'text-start',
        hideFilter: true
      }, {
        Header: 'CreatedBy',
        accessor: 'createdByName'
      }, {
        Header: "Action",
        id: 'action',
        hideFilter:true,
        accessor: (cell) => {
          if (cell['reversed'] === 'N') {

            return (
              <div className="text-center">
                <Button size="sm" variant="outline-danger" className="pt-0 pb-0" title="Reverse Expense" onClick={() => { setShowConfirm({ show: true, callBackData: cell }); }}> <i className="fa fa-reply" role="button"></i></Button>
              </div>)
          }
          else {
            return "Reversed";
          }
        }
      }

    ],
    []
  );


  const load = () => {

    if (startDate && endDate) {
      dispatch(loadAllExpense({
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        user: selectedUser,
        projectid: selectedProjectId,
        type: generalExp ? "GENERAL" : "PROJECT",
        costcenter: costcenter === "0" ? null : costcenter,
        status
      }));
    }

  }


  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let dataToPost = {};
      let method = "POST";


      if (!expense.id) {
        let cc = null;

        if (expense.type === 'GENERAL' && costcenters.length === 1) {
          cc = costcenters[0].id;
        }

        dataToPost = {
          ...expense,
          type: expense.type ?? 'PROJECT',
          screen_name: 'Expense',
          images: expense.images ? expense.images.join("|") : null,
          costcenter: expense.costcenter ? expense.costcenter : cc
        };

        if (expense.expenseon === 'LABOUR') {
          dataToPost['costcenter'] = labours.find(e => e.name === expense.name)?.costcenter ?? null;
        }
      }
      else {
        dataToPost = {
          ...expense,
          screen_name: 'Expense',
          images: expense.images ? expense.images.join("|") : null
        };
        method = "PATCH";


        if (expense.expenseon === 'LABOUR') {
          dataToPost['costcenter'] = labours.find(e => e.name === expense.name)?.costcenter ?? null;
        }
      }

      saveExpenseService(dataToPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetExpense());
        dispatch(loadUserWallet());

        if (!expense.id) {
          load();
        }
      })
        .catch(err => {
          dispatch(notification(err))
        })
    }

  }

  const findFormErrors = () => {

    const { name, expenseon, categoryid, mode, projectid, amount, date, type, costcenter } = expense
    const newErrors = {}


    if (type !== "GENERAL") {
      if (!name || name === 'O') newErrors.name = "Selection cannot be empty!"

      if (!expenseon || expenseon === "0") newErrors.expenseon = "Select a expense on!"

      if (!projectid || projectid === "0") newErrors.project = "Select a project!"

    }
    else {

      if (costcenters.length > 1 && (!costcenter || costcenter === "0")) newErrors.costcenter = "Select a cost center!"

      if (costcenters.length === 0) newErrors.costcenter = "Cost center not mapped to user"

      if (!categoryid || categoryid === "0") newErrors.categoryid = "Select a catergory!"
    }

    if (!mode || mode === "0" || mode === 0) newErrors.mode = "Select a mode!"

    if (!date || date === "") newErrors.date = "Date cannot be blank!"

    if (!amount || Number(amount) === 0) newErrors.amount = "Amount cannot be blank!"



    return newErrors
  }

  const setField = (field, value, model) => {
    dispatch(changeExpense({ name: field, value, model }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }


  const deleteExp = (confirm, selected, remarks) => {

    if (confirm) {
      if (reversePermission) {
        deleteExpenseService(selected.id, 'Expense').then(res => {
          dispatch(notification(res));
          dispatch(loadUserWallet());
          load();
        }).catch(err => {
          dispatch(notification(err));
        })
      }
      else {

        savePendingService({
          table: 'Expense',
          pk_id: selected.id,
          type: 'Expense Reversal',
          amount: selected.amount,
          project: selected.projectid,
          name: selected.name,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          remarks,
          screen_name: 'Expense'

        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })
      }


    }

    setShowConfirm({ show: false, callBackData: null });
  }

  const subtype = (expenseon) => {

    if (expenseon === "VENDOR") {
      return (<Typeahead
        id="floating-label-ven"
        onChange={(e) => {
          setField('name', e[0]?.name)
        }}
        onBlur={() => {
          if (!expense.name) {
            venEntryTypeRef.current?.clear();
          }
        }}
        clearButton
        labelKey="name"
        options={vendors ? vendors.filter(v => v.id) : []}
        ref={venEntryTypeRef}
        placeholder="Seelct a vendor"
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          return (

            <FloatingLabel controlId="floatingVendorName" label="Select a vendor">
              <Form.Control isInvalid={!!errors.name}
                {...inputProps}
                ref={(node) => {
                  inputRef(node);
                  referenceElementRef(node);
                }}
              />
              <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
            </FloatingLabel>

          );
        }}
        selected={expense.name ? vendors.filter(e => e.name === expense.name) : []}
      />
      )
    }
    else if (expenseon === "LABOUR") {
      return (

        <Typeahead
          id="floating-labour-lbl"
          onChange={(e) => {
            setField('name', e[0]?.name)
          }}
          onBlur={() => {
            if (!expense.name) {
              labourEntryTypeRef.current?.clear();

            }
          }}
          clearButton
          labelKey="name"
          options={filterLabour(labours, costcenter)}
          ref={labourEntryTypeRef}
          placeholder="Select a labour"
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
            return (

              <FloatingLabel controlId="floatingLabour" label="Select a labour">
                <Form.Control
                  isInvalid={!!errors.name}
                  {...inputProps}
                  ref={(node) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
              </FloatingLabel>

            );
          }}
          selected={expense.name ? labours.filter(e => e.name === (expense.name)) : []}
        />)

    }
    else if (expenseon === "MATERIAL") {
      return (
        <Typeahead
          id="floating-material-lbl"
          onChange={(e) => {
            setField('name', e[0]?.name)
          }}
          onBlur={() => {
            if (!expense.name) {
              matEntryTypeRef.current?.clear();

            }
          }}
          clearButton
          labelKey="name"
          options={lookup.MATERIAL ?? []}
          ref={matEntryTypeRef}
          placeholder="Select a material"
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
            return (

              <FloatingLabel controlId="floatingMaterial" label="Select a material">
                <Form.Control
                  isInvalid={!!errors.name}
                  {...inputProps}
                  ref={(node) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
              </FloatingLabel>

            );
          }}
          selected={expense.name && lookup.MATERIAL ? lookup.MATERIAL.filter(e => e.name === (expense.name)) : []}
        />
      )

    }
    else if (expenseon === "ACTIVITY") {
      return (<Typeahead
        id="floating-activity-lbl"
        onChange={(e) => {
          setField('name', e[0]?.name)
        }}
        onBlur={() => {
          if (!expense.name) {
            actEntryTypeRef.current?.clear();

          }
        }}
        clearButton
        labelKey="name"
        options={lookup.ACTIVITY ?? []}
        ref={actEntryTypeRef}
        placeholder="Select a activity"
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          return (

            <FloatingLabel controlId="floatingActivity" label="Select a activity">
              <Form.Control
                isInvalid={!!errors.name}
                {...inputProps}
                ref={(node) => {
                  inputRef(node);
                  referenceElementRef(node);
                }}
              />
              <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
            </FloatingLabel>

          );
        }}
        selected={expense.name && lookup.ACTIVITY ? lookup.ACTIVITY.filter(e => e.name === (expense.name)) : []}
      />)

    }
    else {
      <FloatingLabel controlId="floatingDetail" label="Sub list" >
        <Form.Select id="floatingDetail" placeholder="Sublist" disabled>
          <option>Select sub list</option>
        </Form.Select>
      </FloatingLabel>
    }
  }

  const selectFiles = (event) => {


    for (let i = 0; i < event.target.files.length; i++) {


      const options = {
        maxSizeMB: 1.5,
        maxWidthOrHeight: 1000,
        useWebWorker: true
      };
      console.log((event.target.files[i].size / 1000).toFixed(2));

      dispatch({ type: LOADING, isLoading: true })

      imageCompression(event.target.files[i], options).then((img) => {
        // setCompressedImage(img);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setField('images', [...expense.images ? expense.images : [], reader.result])
          }
          dispatch({ type: LOADING, isLoading: false })
        }
        console.log((img.size / 1000).toFixed(2))

        reader.readAsDataURL(img);
      });




    }



    // setImagePreviews([...setSelectedFiles, ...images]);

    event.target.value = '';
  };

  const download = () =>{
    if (startDate && endDate) {
      const json = {
        fromDate: format(startDate, "yyyy-MM-dd"),
        toDate: format(endDate, "yyyy-MM-dd"),
        user: selectedUser,
        projectid: selectedProjectId,
        type: generalExp ? "GENERAL" : "PROJECT",
        costcenter: costcenter == "0" ? null : costcenter,
        skippage: true
      };

      let url = Object.keys(json).filter((k) => {
        return json[k] !== null
      }).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(json[k])
      }).join('&')

      pAxios({
        "method": "GET",
        "url": "/api/auth",
  
      }).then(r => {
  
        pAxios.get(`/api/expense/attachment?${url}`, {
          responseType: 'blob',
        })
          .then((res) => {
            fileDownload(res.data, `Expense.zip`)
          })
      })
    }

  }

  const approve = e => {

    approveExpenseService(expense.id).then(res => {

      // dispatch(resetPayment());
      dispatch(notification(res));
      if (!dback) {
        dispatch(loadExpense(expense.id));
      }
      else {
        dback()
      }
      // handleClose();
    })
      .catch(err => {
        dispatch(notification(err));
        if (dback) {
          dback();
        }
      })

    //setErrors({})

  }

  const theme = useSelector((state) => state.ThemeOptions);



  return (
    <React.Fragment>
      <Container fluid className={`${show ? 'd-none' : ''}`}>

        <Card>
          <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
            <h6 className="flex-fill text-start">Expense</h6>
            {createPermission ?
              <div >

                <Button variant="success" size="sm" onClick={() => { handleShow() }} title="New Expense">
                  <i className="fa fa-plus d-md-none d-sm-inline"></i>
                  <p className="d-none d-md-inline">Add New</p></Button>
              </div> : null}

          </Card.Header>
          <Card.Body>
            <Row className="mb-3 g-2">
              <Col sm={12} md={3}>
                <div style={{ position: "relative" }} className="dp">
                  <DateRangePicker id="expDateRangeFilter" maxDate={new Date()}
                    
                    format="dd-MM-yyyy"
                    selected={new Date()}
                    onChange={onChange}
                    value = {[startDate, endDate]}
                    calendarIcon={<i className="fa fa-calendar"></i>}
                    // clearIcon = {null}
                    className="form-control"
                    isClearable={true}
                  ></DateRangePicker>
                  <label className="black-text" htmlFor="expDateRangeFilter" >Expense Date</label>
                </div>
              </Col>
              {
                ownPermission ? null :
                  <Col sm={12} md={3}>

                    <Typeahead
                      id="floating-user-lbl"
                      onChange={(e) => {
                        setSelectedUser(e[0]?.id)
                      }}
                      onBlur={() => {
                        if (!selectedUser) {
                          userTypeRef.current?.clear();

                        }
                      }}
                      clearButton
                      labelKey="name"
                      options={users ?? []}
                      ref={userTypeRef}
                      placeholder="Select a user"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingUser" label="Select a user">
                            <Form.Control
                              isInvalid={!!errors.name}
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                            <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedUser ? users.filter(e => e.id === Number(selectedUser)) : []}
                    />

                  </Col>
              }
              <Col sm={12} md={3}>
                <Typeahead disabled={generalExp}
                  id="floating-label-pro-filter"
                  onChange={(e) => {
                    setSelectedProjectId(e[0]?.id)
                  }}
                  clearButton
                  labelKey="name"
                  onBlur={() => {
                    if (!selectedProjectId) {
                      proTypeRef.current?.clear();
                    }
                  }}
                  options={filterProjectsSearch(projects, costcenter)}

                  ref={proTypeRef}
                  placeholder="Choose A Project"
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                        <Form.Control
                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />
                      </FloatingLabel>

                    );
                  }}
                  selected={selectedProjectId ? projects.filter(e => e.id === selectedProjectId) : []}
                />
              </Col>
              <Col sm={12} md={2}>
                <div className="form-check form-switch pt-3">
                  <input className="form-check-input" type="checkbox" id="flexSwitchGeneralType" checked={generalExp}
                    onChange={(e) => {
                      setSelectedProjectId(null);
                      setGeneralExp(e.target.checked);

                    }
                    } />
                  <label className="form-check-label" htmlFor="flexSwitchGeneralType"><b>General Expense</b></label>
                </div>
              </Col>
              <Col sm={12} md={1}>
                <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end align-items-end h-100  ">


                  <Button variant="primary" size="sm" onClick={() => { load() }}>Search</Button>
 
                  <Button variant="success" size="sm" onClick={() => { download() }}>Download</Button> 

                </div>
              </Col>
            </Row>
          </Card.Body>

        </Card>
        <Table columns={column} data={expenses} title="Expense Entries" edit={handleShow} pageCount={expenses?.length} status={status} changeStatus={setStatus}>

        </Table>
      </Container>
      {
        !show ? (

          null) : (

          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="flex-fill text-start">Expense Entry </h6>
                <div className="d-flex">

                  <div className="form-check form-switch pe-3">
                    <input className="form-check-input" type="checkbox" id="flexSwitchGeneralExpense"
                      disabled={expense.id}
                      checked={expense && expense.type === "GENERAL"}
                      onChange={(e) => {
                        setField('type', e.target.checked ? 'GENERAL' : 'PROJECT');
                        setField('name', null);
                        setField('categoryid',null);
                        setField("categoryname", null);
                        setField('expenseon', null);
                        setField('projectid', null);
                      }} />
                    <label className="form-check-label" htmlFor="flexSwitchGeneralExpense"><b>General </b></label>
                  </div>

                  {approvePermission && expense.id ? 
                        <Button className="me-1" disabled={expense.approved_status && expense.approved_status === "Y"} variant="success" 
                          size="sm" title="Approve" onClick={approve}> {expense.approved_status && expense.approved_status === "Y" ? 'Approved' : 'Approve'} </Button> 
                        : null
                  }

                  {
                    !dback && ((expense.id && updatePermission) || (!expense.id && createPermission)) ?
                      <Button variant="primary" size="sm" onClick={handleSubmit} className="me-2" title={expense.id ? "Update" : "Save"}>

                        <i className="fa fa-save d-md-none d-sm-inline"></i>
                        <p className="d-none d-md-inline">{expense.id ? "Update" : "Save"}</p>
                      </Button>
                      : null
                  }
                  

                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p>
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <Row className="m-auto">


                  {
                    (expense && expense.type !== "GENERAL") ?
                      <Card className="col-sm-12 col-md-6 border-0 mb-0">
                        <Card.Body className="pt-0 pb-0">

                          <Col className="mb-3 mt-3">

                            <Typeahead
                              id="floating-label-pro"
                              onChange={(e) => {
                                setField('projectid', e[0]?.id)
                              }}
                              labelKey="name"
                              onBlur={() => {
                                if (!expense.projectid) {
                                  proEntryTypeRef.current?.clear();
                                }
                              }}
                              clearButton
                              options={filterProjects(projects, costcenter)}
                              ref={proEntryTypeRef}

                              placeholder="Select a project"
                              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                return (

                                  <FloatingLabel controlId="floatingProject" label="Select a project">
                                    <Form.Control isInvalid={!!errors.project}
                                      {...inputProps}
                                      ref={(node) => {
                                        inputRef(node);
                                        referenceElementRef(node);
                                      }}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                                  </FloatingLabel>

                                );
                              }}
                              selected={expense.projectid ? projects.filter(e => e.id === Number(expense.projectid)) : []}
                            />



                          </Col>

                          <Col className="mb-3">
                            <FloatingLabel controlId="floatingMisc" label="Expense On" >
                              <Form.Select id="floatingMisc" placeholder="Expense On" value={expense.expenseon ?? "0"}
                                onChange={e => {
                                  setField("expenseon", e.target.value);
                                  setField("name", null);
                                }}
                                isInvalid={!!errors.expenseon}
                              >
                                <option value="0"> Select expense on</option>
                                <option value="MATERIAL"> Material</option>
                                {/* <option value="LABOUR"> Labour</option> */}
                                <option value="ACTIVITY"> Activity</option>
                                <option value="VENDOR"> Vendor</option>
                              </Form.Select>
                              <Form.Control.Feedback type='invalid'>{errors.expenseon} </Form.Control.Feedback>
                            </FloatingLabel>

                          </Col>
                          <Col className="mb-3">
                            {subtype(expense.expenseon)}
                          </Col>

                          <Col sm={12} className="pt-2 mb-3">
                            <Form.Check id="gstCheck"
                              disabled={expense && expense.id}
                              label="GST Bill Available?" checked={expense.gstavailable && expense.gstavailable === "Y"}
                              onChange={(e) => { setField("gstavailable", e.target.checked ? "Y" : "N") }} />
                          </Col>

                        </Card.Body>
                      </Card> : null
                  }



                  <Card className="col-sm-12 col-md-6 border-0 mb-0">
                    <Card.Body className="pt-0 pb-0">
                      {
                        expense && expense.type === "GENERAL" ?
                          <React.Fragment>

                            <Col className="mt-3">

                              {
                                costcenters && (costcenters.length > 1 || costcenters.length === 0 || expense.id) ? (

                                  <FloatingLabel controlId="costcenterSelect" label="Cost Center" >

                                    <Form.Select id="categorySelect" aria-label="Category"
                                      isInvalid={!!errors.costcenter}
                                      value={expense.costcenter ?? "0"}
                                      onChange={e => setField("costcenter", e.target.value)}
                                    >

                                      <option value={0}>Select Cost Center</option>
                                      {
                                        costcenters ? costcenters.map((e, i) => {
                                          return <option key={`cate${i}`} value={e.id}>{e.name}</option>
                                        }) : null
                                      }

                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>{errors.costcenter} </Form.Control.Feedback>
                                  </FloatingLabel>


                                ) : null
                              }

                            </Col>
                            <Col className="mt-3">
                              <FloatingLabel controlId="categorySelect" label="Category" >

                                <Form.Select id="categorySelect" aria-label="Category" isInvalid={!!errors.categoryid} value={expense.categoryid ?? "0"}
                                  onChange={e => {
                                    var index = e.target.selectedIndex;
                                    setField("categoryid", e.target.value);
                                    setField("categoryname",e.target.options[index].text);
                                    }
                                  }
                                >
                                  <option value="0">Select Category</option>
                                  {
                                    categories && categories.EXPENSE ? categories.EXPENSE.map((e, i) => {
                                      return <option key={`exp_${i}`} value={e.id}>{e.name}</option>
                                    }) : null
                                  }

                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>{errors.categoryid} </Form.Control.Feedback>
                              </FloatingLabel>

                            </Col>
                          </React.Fragment>
                          : null
                      }

                      <Col className="mt-3 mb-3">
                        <FloatingLabel controlId="modeOfPayment" label="Mode of Payment" >

                          <Form.Select id="modeOfPayment" aria-label="Mode of payment" isInvalid={!!errors.mode} value={expense.mode ?? "0"}
                            onChange={e => setField("mode", e.target.value)}
                          >
                            <option value="0">Select Mode</option>
                            {
                              payment_mode.map((e, i) => {
                                return <option key={`mode_${i}`} value={e}>{e}</option>
                              })
                            }
                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.mode} </Form.Control.Feedback>
                        </FloatingLabel>

                      </Col>

                      <Col className="mb-3">


                        <div style={{ position: "relative" }} className="dp">
                          <DatePicker id="expDate"
                            maxDate={new Date()}
                            minDate={minDate ? new Date(minDate) : null}
                            format="dd-MM-yyyy"
                            calendarIcon={<i className="fa fa-calendar"></i>}
                            clearIcon = {null}
                            className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                            //isInvalid={true}
                            value={expense.date ? new Date(expense.date) : null}
                            onChange={(date) => setField("date", format(date, "yyyy-MM-dd"))}  
                          ></DatePicker>
                          <label className="black-text" htmlFor="expDate" >Expense Date</label>
                        </div>


                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingAmount" label="Amount" >

                          <Form.Control onKeyPress={numberCheck} placeholder="Amount" isInvalid={!!errors.amount} value={expense.amount ?? ""} onChange={e => setField("amount", e.target.value)} />
                          <Form.Control.Feedback type='invalid'>{errors.amount} </Form.Control.Feedback>
                        </FloatingLabel>

                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingremarks" label="Remarks">
                          <Form.Control as="textarea" placeholder="Remarks" style={{ height: '100px' }} value={expense.remarks ?? ""} onChange={e => setField("remarks", e.target.value)}></Form.Control>

                        </FloatingLabel>
                      </Col>
                      <Col className="mb-3">
                        <FloatingLabel controlId="floatingRequest" label="Requested By">
                          <Form.Control type="text" placeholder="Requested By" readOnly value={expense.createdByName ?? ""} />

                        </FloatingLabel>
                      </Col>
                      <Col>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                          <Form.Label>Bill Reference</Form.Label>
                          <Form.Control type="file" accept="image/*" onChange={selectFiles} />
                        </Form.Group>
                      </Col>
                      <Col className="mb-3">

                        {expense.images && (
                          <Row>
                            {expense.images.map((img, i) => {
                              return (
                                <Col sm={12} md={4} className="mb-2" key={i}>
                                  <Image className="preview img-fluid" src={img} alt={"image-" + i}
                                    width="100%" height="100"
                                    //  onClick={ () => openImageViewer(i) }
                                    onClick={(e) => {


                                      var w = window.open('about:blank');;


                                      setTimeout(() => {
                                        w.document.body.appendChild(w.document.createElement('iframe')).src = e.target.src;
                                        w.document.body.style.margin = 0;
                                        w.document.getElementsByTagName("iframe")[0].style.width = '100%';
                                        w.document.getElementsByTagName("iframe")[0].style.height = '100%';
                                        w.document.getElementsByTagName("iframe")[0].style.border = 0;
                                      }, 0);

                                      // window.open(e.target.src, '_blank')

                                    }}
                                    thumbnail
                                  />
                                  <div className="d-grid ">
                                    <Button type="button" variant="outline-danger" onClick={() => {

                                      setField("images", expense.images ? expense.images.filter((_, index) => index !== i) : [])

                                      // setSelectedFiles(selectedFiles.filter((_, index) => index !== i));

                                    }}><i className="fa fa-close"></i></Button>
                                  </div>
                                </Col>
                              );
                            })}


                          </Row>
                        )}
                      </Col>
                      <Col>
                      
                      </Col>

                    </Card.Body>
                  </Card>
                </Row>


              </Card.Body>
            </Card>


          </Container>)
      }
      {/* {isViewerOpen && (
                              <ImageViewer className="mt-5 w-75 h-75 " 
                                src={expense.images??[]}
                                currentIndex={currentImage}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                              />
                            )} */}
      <Confirm show={showConfirm} confirmation={`${reversePermission ? 'Are you sure, want to delete expense?' : 'You are not allowed to delete, however this will be created as a requested to elevated users!'}`} showRemarks={!reversePermission} confirmCallBack={deleteExp}></Confirm>
    </React.Fragment>);








}