import React, {Fragment} from 'react';

import {
    DropdownToggle, DropdownMenu,
    Nav, NavItem, NavLink,
 UncontrolledButtonDropdown
} from 'reactstrap';

import {Modal, Form, Button} from 'react-bootstrap' 

import { resetPassword, updatePassword } from "../../action/userAction";
// import avatar1 from '../../assets/utils/images/avatars/12.jpg';
import { logout } from "../../action/loginAction";
import Avatar, { } from "react-avatar";

import { useDispatch, useSelector } from "react-redux";
import { formatAccounting, formatCurrency } from '../../constant/format';

export default function UserBox({username,wallet}) {
   
    
    const [show, setShow] = React.useState(false);
    const [password, setPassword] = React.useState(null);

    const handleClose = () => {
        setShow(false);
        setPassword(null);

    }

    const handleShow = () => {

        setShow(true);

    }

    const dispatch = useDispatch();


        return (
            <Fragment>
                <div className="header-btn-lg pe-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        {/* <img width={42} className="rounded-circle" src={avatar1} alt=""/> */}
                                        <Avatar className="fw-bold" value={username?.substring(0, 2).toUpperCase()} round={true} size="30" />
                                        <i className="ms-2 opacity-8 fa fa-angle-down" ></i>
                                    </DropdownToggle>
                                    <DropdownMenu end className="rm-pointers dropdown-menu-lg user-drop">
                                        <Nav vertical>
                                            
                                            <NavItem>
                                                <NavLink onClick={handleShow}>Reset Password</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { dispatch(logout()) }}>
                                                    Logout
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ms-3 header-user-info">
                                <div className="widget-heading">
                                   {username||""}
                                </div>
                                <div className="widget-subheading">
                                    <i className='fa fa-inr'></i> { formatAccounting(wallet || 0)}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton as='h6'>
                    Reset Password
                </Modal.Header>
                <Modal.Body>
                    <div className="row gap-2">
                        <Form>
                            <Form.Group >
                                <Form.Label>New Password</Form.Label>
                                <Form.Control size="sm" placeholder="Password" type="password" onChange={e => setPassword(e.target.value)} value={password ? password : ""}></Form.Control>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mt-2">

                        <Button variant="danger" disabled={!password} size="sm" onClick={() => {
                            dispatch(updatePassword(password)); handleClose();
                        }}>Update
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>
            </Fragment>
        )
    
}

