import {
    LOADALLCONFIG,
    CHANGECONFIGFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.config }, action) {

    switch (action.type) {
        case LOADALLCONFIG:
            return {...state,config:[...action.config.rows]}
       
        case CHANGECONFIGFIELD:
            return {...state, config:[
                ...state.config.map(e=>{
  
                    if(e.config_name===action.name){
                      return {...e, config_value : action.value }
                    }
                    else{
                      return e;
                    }
                  })
            ]};
       
        default:
            return { ...state };
    }
}