import {
    LOADALLTDS,
    LOADTDS,
    RESETTDS,
    CHANGETDSFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.tds }, action) {

    switch (action.type) {
        case LOADALLTDS:
            return {...state,tdsentries:[...action.tds.rows]}
       
        case LOADTDS:
            return { ...state, tds:{...action.tds} };
        case CHANGETDSFIELD:
            if(action.model)
                return { ...state,tds:{...state.tds,[action.model]:{...(state.tds[action.model] ?? {}),[action.name]:action.value}}};
            else
                return { ...state,tds:{...state.tds,[action.name]:action.value}};
        case RESETTDS:
            return {...state,tds:{...initialState.tds.tds}}
        default:
            return { ...state };
    }
}