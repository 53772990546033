import {
    LOADALLCATEGORY,
    LOADCATEGORY,
    RESETCATEGORY,
    CHANGECATEGORYFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.category }, action) {

    switch (action.type) {
        case LOADALLCATEGORY:
            return {...state,categories:[...action.category]}
       
        case LOADCATEGORY:
            return { ...state, category:{...action.category} };
        case CHANGECATEGORYFIELD:
            if(action.model)
                return { ...state,category:{...state.category,[action.model]:{...(state.category[action.model] ?? {}),[action.name]:action.value}}};
            else
                return { ...state,category:{...state.category,[action.name]:action.value}};
        case RESETCATEGORY:
            return {...state,category:{...initialState.category.category}}
        default:
            return { ...state };
    }
}