import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Container, FloatingLabel, Form } from "react-bootstrap";



export function Confirm({
    confirmation = "",
    show = { show: false, callBackData: null },
    confirmCallBack = null,
    showRemarks }) {

    const [remarks, setRemarks] = React.useState("");
    

    React.useEffect(()=>{
        setRemarks("");
    },[])

    return (<Modal
        animation={false}
        show={show.show}
        onHide={() => confirmCallBack(false)}
        backdrop={true}
        keyboard={true}
    >

        <Modal.Body>

            <Modal.Header className="border-bottom-0">{confirmation}</Modal.Header>

            {showRemarks ? 
            <Container fluid className="mb-3">

            <FloatingLabel controlId="remarks" label="Remarks" >
                          <Form.Control as="textarea" placeholder="Remarks" value={remarks ?? ""} onChange={e => setRemarks(e.target.value)} />
                        </FloatingLabel>

            </Container>:null }

            <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">
                <Button size="sm" variant="danger" onClick={() => { if (confirmCallBack) confirmCallBack(false) }}>Cancel</Button>
                <Button size="sm" variant="primary"
                    onClick={() => { if (confirmCallBack) confirmCallBack(true, show.callBackData, remarks) }}
                >
                    Sure
                </Button>

            </div>

        </Modal.Body>
    </Modal>)
}
