import {
     initialState, UPDATE_COSTCENTER, LOAD_COSTCENTERS
} from "../constant/constant";




export default function reducer(state = { ...initialState.costcenter }, action) {

    switch (action.type) {
        case LOAD_COSTCENTERS:
            return { ...state, costcenters:action.value} ;
        case UPDATE_COSTCENTER:
            return { ...state, costcenter:action.value} ;
        default:
            return { ...state };
    }
}