import React, { Fragment } from 'react';
import cx from 'classnames';

import AppLogo from './AppLogo'

import SearchBox from './SearchBox';
import UserBox from './UserBox';
import { useSelector } from "react-redux";
import brand from "../../assets/brand.jpg"

export default function AppHeader({ username, wallet, newMenu }) {

    const theme = useSelector((state) => state.ThemeOptions);

    return (
        <Fragment>

            <div className={cx("app-header", theme.headerBackgroundColor, { 'header-shadow': theme.enableHeaderShadow })}>

                <AppLogo />
                <a href="/" className='logo-src ms-2' >
                    <img width="30" height="30" id="profile-img" src={brand} alt="logo" />
                </a>
                <b className='logo-src ps-2'>
                    Athira Projects
                </b>
                <div className={cx(
                    "app-header__content",
                    { 'header-mobile-open': theme.enableMobileMenuSmall },
                )}>
                    <div className="app-header-left">
                        <SearchBox newMenu={newMenu}/>
                    </div>
                    <div className="app-header-right">
                        <UserBox username={username} wallet={wallet} />
                    </div>
                </div>
            </div>
        </Fragment>
    );

}
