import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Form, Card, Col, FloatingLabel } from "react-bootstrap";

import MasterToolBar from "./MasterToolBar"
import Table from "./Table"
import { states } from '../constant/in_state'
import { changeVendor, loadAllVendor, loadVendor, loadVendorLookup, resetVendor, saveVendorService } from "../action/vendorAction";
import { isObjectEmpty } from "../util/util";
import { notification } from "../action/notificationAction";
import {Typeahead} from "react-bootstrap-typeahead";
import {
  setEnableMobileMenu
} from '../constant/constant';



export default function Vendor({ permitted }) {

  const [status, setStatus] = useState("active");
  const dispatch = useDispatch();
  const typeRef = React.useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);


  const vendors = useSelector(
    (state) => state.vendor.vendors
  );

  const vendor = useSelector(
    (state) => state.vendor.vendor
  );



  const handleClose = () => {
    setShow(false);
    setErrors({});
    setSelectedVendorId(null);
    dispatch(resetVendor());
    dispatch(loadAllVendor(status));
  }

  const handleShow = (selected) => {
    setShow(true);
    setErrors({});

    if (selected && selected.original.id) {

      setSelectedVendorId(selected.original.id);
      dispatch(loadVendor(selected.original.id));
    }
    else {
      setSelectedVendorId(null);
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        className:'text-wrap w-250',
        textFilter: true
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Gstin',
        accessor: 'gstin',
        hideFilter: true
      },
      {
        Header: 'Phone',
        accessor: (cell)=> {
          return (cell['phone']??"").replace(/,/g, ", ")
        },
        className:'text-wrap w-150',
        textFilter: true
      },
      {
        Header: 'ContactPerson',
        accessor: (cell)=> {
          return (cell['contact_person']??"").replace(/,/g, ", ")
        },
        className:'text-wrap w-150',
        textFilter: true
      },
      {
        Header: 'Address',
        accessor: 'address.address1',
        className: 'text-truncate w-500',
        textFilter: true

      },

      // {
      //   Header: 'City',
      //   accessor: 'address.city',
      // }, {
      //   Header: 'Balance',
      //   accessor: 'balance',
      // },
      {
        Header: 'Closed',
        accessor: 'closedDate',

        show: status === 'active'
      },
    ],
    [status]
  )

  const handleSubmit = e => {

    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let dataToPost = {};
      let method = "POST";

      if (!vendor.id) {
        dataToPost = {
          ...vendor,
          status: 'Y',
          address: isObjectEmpty(vendor.address) ? null : vendor.address,
          bank: isObjectEmpty(vendor.bank) ? null : vendor.bank
        }
      }
      else {
        dataToPost = {
          ...vendor,
          address: isObjectEmpty(vendor.address) ? null : vendor.address,
          bank: isObjectEmpty(vendor.bank) ? null : vendor.bank
        };
        method = "PATCH"
      }

      saveVendorService(dataToPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetVendor());
        dispatch(loadVendorLookup());
      })
        .catch(err => {
          dispatch(notification(err));
        })

    }

  }

  const findFormErrors = () => {

    const { name, category, email } = vendor
    const newErrors = {}

    if (!name || name === '') newErrors.name = 'Name cannot be blank!'

    if (!category || category === '0') newErrors.category = 'Select a category!'

    if (email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
      newErrors.mail = 'Invalid email address'
    }


    return newErrors
  }

  const setField = (field, value, model) => {
    dispatch(changeVendor({ name: field, value, model }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  React.useEffect(() => {
    dispatch(loadAllVendor(status));
  }, [dispatch, status]);

  React.useEffect(()=>{
    dispatch(setEnableMobileMenu(false));
  },[dispatch])

  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`VENDOR_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`VENDOR_UPDATE`));

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      <MasterToolBar createPermission updatePermission id={vendor.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose}></MasterToolBar>
      {
        !show ? (

          <Container fluid >
            <Table columns={columns} data={vendors} title="Vendor" edit={handleShow} status={status} changeStatus={setStatus} hideColumn={status === 'active' ? ['closedDate'] : []}></Table>
          </Container>) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>Vendor</Card.Header>
              <Card.Body className="p-0">
                <Form>
                  <Row className="m-auto">

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title><i className="fa fa-user-o "></i> Vendor Info</Card.Title>
                        <hr></hr>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingName" label="Vendor Name" >
                            <Form.Control type="text" placeholder="Vendor Name" isInvalid={!!errors.name} value={vendor.name ?? ""} onChange={e => setField('name', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingCategory" label="Vendor Category">
                            <Form.Select id="floatingCategory" aria-label="State" isInvalid={!!errors.category} value={vendor.category ?? "0"} onChange={e => setField('category', e.target.value)} >
                              <option value="0">Select Category</option>
                              <option value="SUPPLIER">Supplier</option>
                              <option value="CONTRACTOR">Contractor</option>

                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors.category} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>


                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingGstin" label="GSTIN">
                            <Form.Control type="text" placeholder="GSTIN" isInvalid={!!errors.gstin} value={vendor.gstin ?? ""} onChange={e => setField('gstin', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.gstin} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingEmail" label="Email" >
                            <Form.Control type="email" placeholder="Email" isInvalid={!!errors.mail} value={vendor.email ?? ""} onChange={e => setField('email', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.mail} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingPhone" label="Phone Number">
                            <Form.Control type="text" placeholder="Phone" isInvalid={!!errors.phone} value={vendor.phone ?? ""} onChange={e => setField('phone', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col> <FloatingLabel controlId="floatingContact" label="Contact Person">
                            <Form.Control type="text" placeholder="Contact Person" isInvalid={!!errors.contact} value={vendor.contact_person ?? ""} onChange={e => setField('contact_person', e.target.value)} />
                            <Form.Control.Feedback type='invalid'>{errors.contact} </Form.Control.Feedback>
                          </FloatingLabel>
                            <Form.Text className="text-muted">
                              #Use comma to seperate multiple contact person and phone number  
                            </Form.Text>

                          </Col>
                        </Row>




                      </Card.Body>
                    </Card>

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title> <i className="fa fa-address-book-o "></i>Address</Card.Title>
                        <hr></hr>

                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingAddr" label="Address">
                            <Form.Control type="text" placeholder="Billing Address" isInvalid={!!errors.address1} value={vendor.address.address1 ?? ""} onChange={e => setField('address1', e.target.value, 'address')} />
                            <Form.Control.Feedback type='invalid'>{errors.address1} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        {/* <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingAddr2" label="Address Line 2">
                            <Form.Control type="text" placeholder="Billing Address 2 " value={vendor.address.address2 ?? ""} onChange={e => setField('address2', e.target.value, 'address')} />
                          </FloatingLabel>
                          </Col>
                        </Row> */}
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingCity" label="City">
                            <Form.Control type="text" placeholder="City" isInvalid={!!errors.city} value={vendor.address.city ?? ""} onChange={e => setField('city', e.target.value, 'address')} />
                            <Form.Control.Feedback type='invalid'>{errors.city} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col>

                          <Typeahead
                              id="floating-label-state"
                              onChange={(e) => {
                                setField('state', e[0], 'address')
                              }}
                              clearButton
                              onBlur={() => {
                                if (!vendor.address.state) {
                                  typeRef.current?.clear();
                                }
                              }}
                              options={states}
                              ref={typeRef}
                              placeholder="Choose a state..."
                              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                return (

                                  <FloatingLabel controlId="floatingLabel" label="Select a state...">
                                    <Form.Control
                                      {...inputProps}
                                      ref={(node) => {
                                        inputRef(node);
                                        referenceElementRef(node);
                                      }}
                                    />
                                  </FloatingLabel>

                                );
                              }}
                              selected={vendor.address.state ? [vendor.address.state] : []}
                            />
                          
                           {/* <FloatingLabel controlId="floatingState" label="State" >
                            <Form.Select id="floatingState" aria-label="State" isInvalid={!!errors.state} value={vendor.address.state ?? "0"} onChange={e => setField('state', e.target.value, 'address')} >
                              <option value="0">Select State</option>
                              {
                                states.map((e, i) => {
                                  return <option key={`state_op_${i}`} value={e}>{e}</option>
                                })
                              }

                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors.state} </Form.Control.Feedback>
                          </FloatingLabel>
                           */}
                          </Col>
                          <Col>
                            <FloatingLabel controlId="floatingZip" label="Pincode" value={vendor.address.pincode ?? ""} onChange={e => setField('pincode', e.target.value, 'address')} >
                              <Form.Control type="text" placeholder="Pincode" isInvalid={!!errors.pincode} />
                              <Form.Control.Feedback type='invalid'>{errors.pincode} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                      </Card.Body>
                    </Card>

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title className={vendor?.bank?.verified==='Y'?'text-success':'text-danger'}> <i className="fa fa-university "></i> Bank Details - {vendor?.bank?.verified==='Y' ? 'Verified' : vendor?.bank?.verified==='N'?'Unverified':''}</Card.Title>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingbank" label="Bank Name">
                            <Form.Control type="text" placeholder="Bank" isInvalid={!!errors.bankname} value={vendor.bank.name ?? ""} onChange={e => setField('name', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.bankname} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingBranch" label="Branch">
                            <Form.Control type="text" placeholder="Branch" isInvalid={!!errors.branch} value={vendor.bank.branch ?? ""} onChange={e => setField('branch', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.branch} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingIfsc" label="IFSC Code">
                            <Form.Control type="text" placeholder="IFSC Code" isInvalid={!!errors.ifsc} value={vendor.bank.ifsc ?? ""} onChange={e => setField('ifsc', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.ifsc} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingAcc" label="Account Number">
                            <Form.Control type="text" placeholder="Account Number" isInvalid={!!errors.accountnumber} value={vendor.bank.number ?? ""} onChange={e => setField('number', e.target.value, 'bank')} />
                            <Form.Control.Feedback type='invalid'>{errors.accountnumber} </Form.Control.Feedback>
                          </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingPan" label="PAN Number">
                              <Form.Control type="text" placeholder="PAN Number" isInvalid={!!errors.pan} value={vendor.pan ?? ""} onChange={e => setField('pan', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.pan} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingGpay" label="Gpay Number">
                              <Form.Control type="text" placeholder="Gpay Number" isInvalid={!!errors.gpay} value={vendor.bank.gpay_number ?? ""} onChange={e => setField('gpay_number', e.target.value, 'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.gpay} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingGpayName" label="Gpay Name">
                              <Form.Control type="text" placeholder="Gpay Name" isInvalid={!!errors.gpayName} value={vendor.bank.gpay_name ?? ""} onChange={e => setField('gpay_name', e.target.value,'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.gpayName} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col> <FloatingLabel controlId="floatingBalance" label="Balance">
                            <Form.Control type="text" placeholder="Balance" value={vendor.balance ?? ""} readOnly />
                            <Form.Text className="text-muted">
                              #Consider negative balance as Advance
                            </Form.Text>
                          </FloatingLabel>
                          </Col>
                          <Col> <FloatingLabel controlId="floatingPaid" label="Received">
                            <Form.Control type="text" placeholder="Received" value={vendor.paid ?? ""} readOnly />
                          </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <div className="form-check form-switch">
                              <input className="form-check-input" disabled={!vendor.id} type="checkbox" id="flexSwitchInvoiceType"
                                checked={vendor.status === "Y"} onChange={(e) => { setField('status', e.target.checked ? "Y" : "N") }} />
                              <label className="form-check-label" htmlFor="flexSwitchInvoiceType"><b>Is Active? </b>
                                {
                                  vendor.closedDate && vendor.status === 'N' ? <sub className="d-flex text-muted">{vendor.closedDate}</sub> : null
                                }
                              </label>
                            </div>
                          </Col>

                        </Row>

                      </Card.Body>
                    </Card>

                  </Row>

                </Form>
              </Card.Body>
            </Card>


          </Container>)
      } </React.Fragment>);
}

// }
