

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, } from "react-router-dom";
import { history } from "../../../app/history";

class LinkContainer extends React.Component {
  componentWillMount() {
    this.to = this.props.to;
    if (this.to[0] !== '/') this.to = `/${this.to}`;

    history.listen(this.onLocationChange.bind(this));
    this.onLocationChange(history.location.pathname);
  }
  onLocationChange(e) {
    // console.log('onLocationChange', e);
    // if ((e || '/') === this.to) {
    //   this.props.activateMe({
    //     newLocation: e,
    //   });
    // }
  }
  render() {
    const {
      className,
      classNameActive,
      classNameHasActiveChild,
      active,
      hasActiveChild,
      to,
      externalLink,
      hasSubMenu,
      toggleSubMenu,
      children,
    } = this.props;

    return (
      hasSubMenu || externalLink
      ? (
        <a
          className={classnames(
            className,
            hasActiveChild && classNameHasActiveChild
          )}
          target={externalLink ? '_blank' : undefined}
          href={to}
          onClick={toggleSubMenu} rel="noreferrer"
        >
          {children}
        </a>
      )
      : (
        <Link
          className={classnames(
            className,
            active && classNameActive
          )}
          to={to}
        >
          {children}
        </Link>
      )
    );
  }
}
// (
//   <Link to={to}  
//   className={classnames(
//     className,
//     active && classNameActive,
//     hasActiveChild && classNameHasActiveChild,
//   )}
//   onClick={hasSubMenu ? toggleSubMenu : activateMe}
// >
//   {children}

// </Link>

// );

LinkContainer.defaultProps = {
  externalLink: false,
  toggleSubMenu: null,
};

LinkContainer.propTypes = {
  className: PropTypes.string.isRequired,
  classNameActive: PropTypes.string.isRequired,
  classNameHasActiveChild: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  hasActiveChild: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  hasSubMenu: PropTypes.bool.isRequired,
  toggleSubMenu: PropTypes.func,
  activateMe: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default LinkContainer;