import {
    LOADALLEXPENSE,
    LOADEXPENSE,
    RESETEXPENSE,
    CHANGEEXPENSEFIELD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.expense }, action) {

    switch (action.type) {
        case LOADALLEXPENSE:
            return {...state,expenses:[...action.expense.rows]}
       
        case LOADEXPENSE:
            return { ...state, expense:{...action.expense} };
        case CHANGEEXPENSEFIELD:
            if(action.model)
                return { ...state,expense:{...state.expense,[action.model]:{...(state.expense[action.model] ?? {}),[action.name]:action.value}}};
            else
                return { ...state,expense:{...state.expense,[action.name]:action.value}};
        case RESETEXPENSE:
            return {...state,expense:{...initialState.expense.expense}}
        default:
            return { ...state };
    }
}