import {
    LOADALLPAYMENT,
    LOADPAYMENT,
    RESETPAYMENT,
    CHANGEPAYMENTFIELD,
    initialState,
    RESETPAYMENTS
} from "../constant/constant";

export default function reducer(state = { ...initialState.payment }, action) {

    switch (action.type) {
        case LOADALLPAYMENT:
            return { ...state, payments: [...action.payment] }

        case LOADPAYMENT:
            return { ...state, payment: { ...action.payment } };
        case CHANGEPAYMENTFIELD:
            if (action.model)
                return { ...state, payment: { ...state.payment, [action.model]: { ...(state.payment[action.model] ?? {}), [action.name]: action.value } } };
            else
                return { ...state, payment: { ...state.payment, [action.name]: action.value } };
        case RESETPAYMENT:
            return { ...state, payment: { ...initialState.payment.payment } }
        case RESETPAYMENTS:
            return { ...state, payments: [] }
        default:
            return { ...state };
    }
}