import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Form, Card, Col, Tabs, Tab, Modal } from "react-bootstrap";

import { changeCategory, loadAllCategory, loadCategory, resetCategory, saveCategory, updateCategory } from "../action/categoryAction";
import {
    setEnableMobileMenu
  } from '../constant/constant';

  
export default function Category({ permitted }) {

    
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [key, setKey] = useState('Client');

    // eslint-disable-next-line no-unused-vars
    const [selectedCategoryId, setSelectedCategoryId] = useState({});

    const [errors, setErrors] = useState({});


    const category = useSelector(
        (state) => state.category.category
    );

    const categories = useSelector(
        (state) => state.category.categories
    );


    const handleClose = () => {
        setShow(false);
        setSelectedCategoryId(null);

        dispatch(resetCategory());
        dispatch(loadAllCategory());
    }

    const handleShow = (id) => {
        setShow(true);
        if (id) {

            dispatch(loadCategory(id))
            setSelectedCategoryId(id);
        }
        else {
            setSelectedCategoryId(null);
        }
    }

    const handleSubmit = e => {

        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (!category.id) {
                dispatch(saveCategory({
                    ...category, type: key.toUpperCase()
                }
                ));
            }
            else {
                dispatch(updateCategory({
                    ...category
                }));
            }
            handleClose();
        }

    }
    const findFormErrors = () => {

        const { name, } = category
        const newErrors = {}

        if (!name || name === '') newErrors.name = 'Name cannot be blank!'

        return newErrors
    }



    const setField = (field, value, model) => {
        dispatch(changeCategory({ name: field, value, model }));
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }



    React.useEffect(() => {
        dispatch(loadAllCategory());
        dispatch(setEnableMobileMenu(false));
    }, [dispatch]);


    const generateTabs = (type) => {
        return (
            <Container fluid className="border border-top-0 pt-3" style={{ "height": "65vh", "overflowX": "auto" }}>
                <Row>
                    {
                        categories.filter(e => e.type === type.toUpperCase()).map((e, i) => {
                            return (<Col className="col-md-4 col-12 col-lg-3 mb-2" key={`${i}_${type}`}>
                                <Card >
                                    <Card.Body className="p-2" onDoubleClick={() => { handleShow(e.id) }}>
                                        {e.name}
                                        {/* <i className="fa fa-times-circle-o pt-1 float-end text-danger" role="button"></i> */}
                                    </Card.Body>
                                </Card>
                            </Col>)
                        })
                    }
                </Row>

            </Container>)
    }


    // eslint-disable-next-line no-unused-vars
    const [createPermission, setCreatePermission] = React.useState(permitted(`CATEGORIES_CREATE`));
    // eslint-disable-next-line no-unused-vars
    const [updatePermission, setUpdatePermission] = React.useState(permitted(`CATEGORIES_UPDATE`));

    const theme = useSelector((state) => state.ThemeOptions);


    return (
        <React.Fragment>
            <Container fluid >
                <Card>
                    <Card.Header className={`${theme.headerBackgroundColor}`}>
                        <h6 className="text-start p-1 flex-fill">

                        Category
                    </h6>
                        {
                            createPermission ?
                                <div className="">
                                    <Button variant="success" size="sm" onClick={() => handleShow()}><i className="fa fa-plus "> </i> <p className="d-none d-md-inline"> Add</p></Button>
                                </div> : null
                        }
                    </Card.Header>
                    <Card.Body>
                        {
                            updatePermission ?
                                <div className="col">
                                    <sub className="text-muted"><span className="text-danger">*</span>Double click the categories to edit</sub>
                                </div> : null
                        }
                        <Tabs activeKey={key}
                            onSelect={(k) => setKey(k)}   className="mb-0"                >
                            <Tab eventKey="Client" title="Cost Center">
                                {generateTabs("Client")}  
                            </Tab>
                            <Tab eventKey="Material" title="Material">
                                {generateTabs("Material")}
                            </Tab>
                            <Tab eventKey="Labour" title="Labour">
                                {generateTabs("Labour")}
                            </Tab>
                            <Tab eventKey="Activity" title="Activity">
                                {generateTabs("Activity")}
                            </Tab>
                            <Tab eventKey="Expense" title="Expense" >
                                {generateTabs("Expense")}
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>

                {/**/}

            </Container>
            {
                updatePermission ? <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add / Edit - {key==='Client'?'Cost Center': key}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                            <Form.Group className="mb-3" controlId="categoryValue">
                                <Form.Label>Category</Form.Label>
                                <Form.Control type="text"  placeholder="Category" isInvalid={!!errors.name} value={category.name ?? ""} onChange={e => setField("name", e.target.value)} />
                                <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal> : null
            }

        </React.Fragment>);
}

// }
